import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  allVishlawGlobalOrganisationUser,
  changePassword,
  createUser,
  createUserForm,
  deleteUsers,
  getUsers,
  isActiveUser,
  resetPassword,
  updateUser,
} from "../../../api/user_api";
import { updateUserCaseFilter } from "../../../api/case_api";

export const getUsersAction = createAsyncThunk(
  "users/getUsersAction",
  async (_, { rejectWithValue, getState }) => {
    try {
      const users = await getUsers();
      return users.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const createUserAction = createAsyncThunk(
  "users/createUserAction",
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const users = await createUser(values);

      dispatch(getUsersAction());

      return users;
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

export const updateUserAction = createAsyncThunk(
  "users/updateUserAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await updateUser(values);

      getUsersAction();
      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const allVishlawGlobalOrganisationUserAction = createAsyncThunk(
  "users/allVishlawGlobalOrganisationUserAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await allVishlawGlobalOrganisationUser(values);

      getUsersAction();
      return users.data.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const changePasswordAction = createAsyncThunk(
  "users/changePasswordAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await changePassword(values);

      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const resetPasswordAction = createAsyncThunk(
  "users/resetPasswordAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await resetPassword(values);

      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateUserIsActiveStatusAction = createAsyncThunk(
  "users/updateUserIsActiveStatus",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await isActiveUser(values);

      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const deleteUserAction = createAsyncThunk(
  "users/deleteUser",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await deleteUsers(values);

      return users;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const formDataAction = createAsyncThunk(
  "users/formDataAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const users = await createUserForm();

      return users;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userUpdateCaseFilterAction = createAsyncThunk(
  "users/userUpdateCaseFilterAction",
  async (value, { rejectWithValue, getState }) => {
    try {
      const users = await updateUserCaseFilter(value);
      return users.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const UpdateCaseFilterAction = createAsyncThunk(
  "users/UpdateCaseFilterAction",
  async (value, { rejectWithValue, getState }) => {
    try {
      const users = await updateUserCaseFilter(value);
      return users.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
