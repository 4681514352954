import {
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import ThemeCustomization from "./themes";
import ScrollTop from "./components/ScrollTop";
import { ToastContainer } from "react-toastify";
import { bounce } from "./pages/TosterAlert";
import axios from "axios";
import { getCookie } from "./helpers/cookie";
import { handleAuthentication, resetDataAndLogout } from "./helpers/auth";
import LoginRoutes from "./routes/LoginRoutes";
import MainRoutes from "./routes/MainRoutes";
import { useSelector } from "react-redux";
import "./App.css";
import { useEffect } from "react";

const skipAuthRoutes = ["login", "refreshToken"];

axios.interceptors.request.use(
  function (config) {
    const skipCheck = skipAuthRoutes.some((route) =>
      config.url.includes(route)
    );

    if (!skipCheck) {
      const headerToken = `Bearer ${getCookie("accessToken")}`;

      config.headers.Authorization = headerToken;
    } else {
      resetDataAndLogout();
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const navigate = useNavigate();

    if (error?.response?.status == 401) {
      if (error?.config?.url.includes("refreshToken")) {
        navigate("/");
      }
      return handleAuthentication(error);
    }
    return Promise.reject(error);
  }
);

// ==============================|| APP - THEME, ROUTER, LOCAL ||============================== //

export default function App() {
  const login = useSelector((state) => state?.login || []);

  const isLogggedIn = getCookie("accessToken");
  const routes = [LoginRoutes];

  if (isLogggedIn || login) {
    routes.push(MainRoutes);
  }
  const router = createBrowserRouter(routes, {
    basename: "/",
  });

  return (
    <ThemeCustomization>
      <ScrollTop>
        <ToastContainer transition={bounce} />
        {/* <SessionTimeoutHandler /> */}
        <RouterProvider router={router} />
      </ScrollTop>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          input[type="password"]::-ms-reveal,
          input[type="password"]::-ms-clear {
            display: none !important;
          }
        `,
        }}
      />
    </ThemeCustomization>
  );
}
