import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  allAgreementDocumentDelete,
  allAgreementDocumentList,
  allCaseDetails,
  allCaseType,
  caseBulkFilter,
  caseDetailHistory,
  caseTrackingUpdate,
  dashboardCaseDetailsCounts,
  filterColumn,
  organizationCaseDetails,
  pdfIDMatching,
  preTrailCaseDetailsDocuments,
  preTrailCaseDetailsCreate,
  singleCaseDetails,
  updateAppointmentOfArbitrator,
  updateAppointmentOfArbitratorSec11and12,
  updateArbitrationClausAndAgreement,
  updateArbitrationDurationAndTimelineManagement,
  updateArbitrationEnforcementAndAppeals,
  updateArbitrationFeePaymentStatus,
  updateArbitrationHoldWithdrawalAndSettlement,
  updateArbitrationInterimOrdersReliefsAwards,
  updateArbitrationNoticesKeyDates,
  updateArbitrationWitnessEvidenceAndExpertReport,
  updateCaseDetails,
  updateCasePrimaryInformation,
  updateFinalAward,
  updateInitiationOfArbitration,
  updateInterimOrders,
  updateSec17InterimOrders,
  updateSec9InterimOrders,
  preTrailCaseDetailsUpdate,
  preTrailCaseDetailsDelete,
  claimantsListOfEvidenceCreate,
  claimantsListOfEvidenceDelete,
  claimantsListOfEvidenceUpdate,
  respondentsListOfEvidenceUpdate,
  respondentsListOfEvidenceDelete,
  respondentsListOfEvidenceCreate,
  claimantSideWitnessCreate,
  claimantSideWitnessUpdate,
  claimantSideWitnessDelete,
  respondentWitnessCreate,
  respondentWitnessUpdate,
  respondentWitnessDelete,
  claimantSideArgumentsCreate,
  claimantSideArgumentsUpdate,
  claimantSideArgumentsDelete,
  respondentSideArgumentsCreate,
  respondentSideArgumentsUpdate,
  respondentSideArgumentsDelete,
} from "../../../api/case_api";
import { json } from "react-router-dom";

const filter = {
  "#": true,
  caseID: true,
  allocationDate: true,
  stage: false,
  "Updated At": false,
  "Organization Name": true,
  creator: true,
  updator: true,
  "Case Type": true,
  "Case Status": true,
  appointingAuthorityName: false,
  "Arbitrator Consent Letter Date": false,
  "Award Date": false,
  "Claim Filling Date": false,
  Contested: false,
  CreatedAt: false,
  "Final Hearing": false,
  "First Hearing": false,
  "Second Hearing": false,
  "Third Hearing": false,
  "IA Application Date": false,
  "IA Order Date": false,
  iaOrderIssued: false,
  "LRN Notice Date": false,
  "REF Notice Date": false,
  remarks: false,
  "Notice For Arbitrator Consent Date": false,
  "Notice Of Appointment Of Arbitrator Date": false,
  "Notice Of Arbitrator Disclosure Date": false,
  "Case Close Date": false,
  numberOfArbitrators: false,
  "TRN Date": false,
  "Case Closing Reason": false,
  arbitralTribunalID: false,
  modeOfProceedingsID: false,
  natureOfProceedingsID: false,
  "Practice Area": false,
  "Jurisdiction Type": false,
  "Mode Of Communications": false,
  "arbitration Initiated By": false,
  "Dispute Referred": false,
  "Arbitration Proceedings Type": false,
  "Arbitration Governing Rules": false,
  "Results Of Conflicts Of Interest Check": false,
  "Appointment Of Arbitrator Challenge Outcome": false,
  "Status Of App For Interim Relief Order Before High Court Under Section 9": false,
  "Status Of App For Interim Award Field": false,
  "Enforcement Status": false,
  "Appeal Status": false,
  "Arbitration Fee Payment Status": false,
  "Written Arbitration Agreement": false,
  "Executed By All Parties To Agreement": false,
  "Mode Of Execution Of Arbitration Agreement": false,
  "Mode Of Hearing Per Agreement": false,
  "Status Of App Of Extension Of Arbitration Timeline Filed On": false,
  "Agreed Arbitration Duration": false,
  "Date of Prepration": false,
  "CONTRACT BRANCH": false,
  "BRANCH NAME": false,
  "TERRITORY NAME": false,
  "REGION NAME": false,
  ACTIVITY: false,
  "BORROWER CODE": false,
  "CONSTITUTION TYPE": false,
  "PROPRIETOR TITLE": false,
  "PROPRIETOR NAME": false,
  "BORROWER CARE OF": false,
  "BORROWER DOB": false,
  "BORROWER AGE": false,
  "BORROWER CARE OF NAME": false,
  "ADD BORR 1 CARE INFO": false,
  "ADD BORR 1 CARE INFO 1": false,
  "ADD BORR 1 DOB": false,
  "ADD BORR 1 AGE": false,
  "ADD BORR 2 CARE INFO": false,
  "ADD BORR 2 CARE INFO 1": false,
  "ADD BORR 2 DOB": false,
  "ADD BORR 2 AGE": false,
  "ADD BORR 3 CARE INFO": false,
  "ADD BORR 3 CARE INFO 1": false,
  "ADD BORR 3 DOB": false,
  "ADD BORR 3 AGE": false,
  "ADD BORR 4 CARE INFO": false,
  "ADD BORR 4 CARE INFO 1": false,
  "ADD BORR 4 DOB": false,
  "ADD BORR 4 AGE": false,
  "ADD BORR 5 CARE INFO": false,
  "ADD BORR 5 CARE INFO 1": false,
  "ADD BORR 5 DOB": false,
  "ADD BORR 5 AGE": false,
  "GUAR1 NAME": false,
  "GUAR1 ADDRESS": false,
  "GUAR1 CARE INFO": false,
  "GUAR1 CARE OF NAME": false,
  "GUAR1 DOB": false,
  "GUAR1 AGE": false,
  "GUAR2 NAME": false,
  "GUAR2 ADDRESS": false,
  "GUAR2 CARE INFO": false,
  "GUAR2 CARE INFO 1": false,
  "GUAR2 DOB": false,
  "GUAR2 AGE": false,
  "GUAR3 NAME": false,
  "GUAR3 ADDRESS": false,
  "GUAR3 CARE INFO": false,
  "GUAR3 CARE INFO 1": false,
  "GUAR3 DOB": false,
  "GUAR3 AGE": false,
  "GUAR4 NAME": false,
  "GUAR4 ADDRESS": false,
  "GUAR4 CARE INFO": false,
  "GUAR4 CARE INFO 1": false,
  "GUAR4 DOB": false,
  "GUAR4 AGE": false,
  "GUAR5 NAME": false,
  "GUAR5 ADDRESS": false,
  "GUAR5 CARE INFO": false,
  "GUAR5 CARE INFO 1": false,
  "GUAR5 DOB": false,
  "GUAR5 AGE": false,
  "GUAR6 NAME": false,
  "GUAR6 ADDRESS": false,
  "GUAR6 CARE INFO": false,
  "GUAR6 CARE INFO 1": false,
  "GUAR6 DOB": false,
  "GUAR6 AGE": false,
  "NUMBER OF ASSETS": false,
  "DEFAULT FROM DATE NOTICE": false,
  "DEFAULT INSTALLMENT NOTICE": false,
  "DEFAULT FROM DATE REF": false,
  "DEFAULT INST REF": false,
  "INSTALMENT RECEIVABLE": false,
  "AFC DEBIT": false,
  "INS RCVBLE DEBIT": false,
  "INCI BAL DEBIT": false,
  "TRAVEL EXP BAL DEBIT": false,
  "BANK CHGS DEBIT": false,
  "CHQ RTN CHGS DEBIT": false,
  "REPOSS EXP DEBIT": false,
  "TOTAL MEMO DEBIT": false,
  "FLEET BAL": false,
  "TOT DR": false,
  "REBATE AMOUNT": false,
  "PPR AMOUNT CR": false,
  "INSUR RECEIVABLE CR": false,
  "INCIDENTAL BAL CR": false,
  "AFC BAL CR": false,
  "AFC WAIVER": false,
  "DEALER BAL CR": false,
  "SCP PA PAYABLE CREDIT": false,
  "TOT MEMO BAL CR": false,
  "TOT CR": false,
  "AMOUNT FINANCED": false,
  "ORIGINAL AMT REPAY": false,
  "REPAY FREQ": false,
  INSTALLMENTS: false,
  "FIRST INSTL DUE DATE": false,
  "ORIGINAL LAST INSTL DUE DATE": false,
  "AFC RATE": false,
  "LAST LEGAL NOTICE DATE": false,
  "SECOND INSTL DAY": false,
  "SECOND INSTL AMOUNT": false,
  "MORATORIUM PERIOD": false,
  "MORATORIUM MONTHS": false,
  "MORATORIUM REPAY AMT": false,
  "MORATORIUM LAST INST DT": false,
  "RESTRUCTURE PERIOD": false,
  "RESTRUCTURE AMOUNT": false,
  "RESTRUCTURE LAST INST DT": false,
  "CURRENT AMT REPAY": false,
  "CURRENT LAST INSTL DATE": false,
  "SETTLEMENT AMOUNT": false,
  "CLOSURE TYPE": false,
  "SETTLEMENT DATE": false,
  "COLLATERAL INDICATOR": false,
  "GUAR1 MOBILE NUMBER": false,
  "CONTRACT POSITION": false,
  "CONTRACT STATUS": false,
  "Conciliation Date": false,
  "Conciliator Name": false,
  "Conciliator Address": false,
  "Conciliation Venue": false,
  "Conciliator Letter Date": false,
  "No Legal /Award Passed": false,
  "MAKE DESC": false,
  MODEL: false,
  "ASSET TYPE DESC": false,
  "REGN IDENTITY NUMBER": false,
  "ENGINE NUMBER": false,
  "CHASIS NUMBER": false,
  "ASSET STATUS": false,
  "S.no": false,
  "CONTRACT NO": false,
  "AGREE DATE": false,
  "CONTRACT TYPE": false,
  "Contact person": false,
  "Contract Name": false,
  "Award Amount/Settlement Amount": false,
  "BORROWER NAME": false,
  "BORROWER MOBILE": false,
  "BORROWER ADDRESS": false,
  "ADD BORR 1 NAME": false,
  "ADD BORR1 ADDRESS": false,
  "ADD BORR 2 NAME": false,
  "ADD BORR2 ADDRESS": false,
  "ADD BORR 3 NAME": false,
  "ADD BORR3 ADDRESS": false,
  "ADD BORR 4 NAME": false,
  "ADD BORR4 ADDRESS": false,
  "ADD BORR 5 NAME": false,
  "ADD BORR5 ADDRESS": false,
  "ADD BORR1 MOBILE NUMBER": false,
  "S.No": false,
  "Allocation Date": false,
  Year: false,
  Claimant: false,
  Respondent: false,
  "Case Name": true,
  "Name of Arbitrator": false,
  "Arbitrator Appointed By": false,
  "Ref Notice Date": false,
  "CLAIM FILING DATE": false,
  "FIRST HEARING": false,
  "SECOND HEARING": false,
  "SEC 17 Status": false,
  "SEC 17 ORDER DATE": false,
  "THIRD HEARING": false,
  "FINAL HEARING": false,
  "AWARD DATE": false,
  STAGE: false,
  REMARKS: false,
  "BAR CODE": false,
  "AGREEMENT NO": false,
  PRODUCT: false,
  TYPE: false,
  "DATE ON RECEIVED": false,
  "Adv Name": false,
  "Allocation code": false,
  "Legal Cost": false,
  EMI: false,
  STATUS: false,
  DPD: false,
  Bkt: false,
  "Claim Amount": false,
  "OD+Pos": false,
  FC: false,
  FORECLOSE_STATUS: false,
  FUTURE_RECEIVABLE: false,
  "POS+EMI Overdue": false,
  "FC Amount": false,
  FORECLOSE_DATE: false,
  EMI_OVERDUE: false,
  CBC_OVERDUE: false,
  AFC_OVERDUE: false,
  FUTURE_PRINC: false,
  CASH_BOUNCE_OVERDUE: false,
  LOSS_ON_SALE: false,
  PROPOSALNO: false,
  BRANCHNAME: false,
  BRANCHNAME_CODE: false,
  NOC_STATUS: false,
  NOC_GENERATED_DATE: false,
  BARROWER_NAME: false,
  BARROWER_FATHERNAME: false,
  BARROWER_CUSTOMER_DOB: false,
  BARROWER_CUSTOMER_ADDRESS1: false,
  BARROWER_CUSTOMER_ADDRESS2: false,
  BARROWER_CUSTOMER_ADDRESS3: false,
  BARROWER_CITY: false,
  BARROWER_CUSTOMER_LANDMARK: false,
  BARROWER_CUSTOMER_PINCODE: false,
  BARROWER_CUSTOMER_CONTACT1: false,
  BARROWER_CUSTOMER_CONTACT2: false,
  BARROWER_CUSTOMER_CONTACT3: false,
  BARROWER_CUSTOMER_CONTACT4: false,
  BARROWER_CUSTOMER_CONTACT5: false,
  BARROWER_STATE: false,
  BARROWER_TOWN: false,
  BARROWER_TALUK: false,
  COBORROWER_NAME: false,
  COBORROWER_FATHERNAME: false,
  COBORROWER_CUSTOMER_DOB: false,
  COBORROWER_CUSTOMER_ADDRESS1: false,
  COBORROWER_CUSTOMER_ADDRESS2: false,
  COBORROWER_CUSTOMER_ADDRESS3: false,
  COBORROWER_CITY: false,
  COBORROWER_CUSTOMER_LANDMARK: false,
  COBORROWER_CUSTOMER_PINCODE: false,
  COBORROWER_CUSTOMER_CONTACT1: false,
  COBORROWER_CUSTOMER_CONTACT2: false,
  COBORROWER_CUSTOMER_CONTACT3: false,
  COBORROWER_CUSTOMER_CONTACT4: false,
  COBORROWER_CUSTOMER_CONTACT5: false,
  COBORROWER_STATE: false,
  COBORROWER_TOWN: false,
  COBORROWER_TALUK: false,
  GUAR_NAME: false,
  GUAR_FATHERNAME: false,
  GUAR_CUSTOMER_DOB: false,
  GUAR_CUSTOMER_ADDRESS1: false,
  GUAR_CUSTOMER_ADDRESS2: false,
  GUAR_CUSTOMER_ADDRESS3: false,
  GUAR_CITY: false,
  GUAR_CUSTOMER_LANDMARK: false,
  GUAR_CUSTOMER_PINCODE: false,
  GUAR_CUSTOMER_CONTACT1: false,
  GUAR_CUSTOMER_CONTACT2: false,
  GUAR_CUSTOMER_CONTACT3: false,
  GUAR_CUSTOMER_CONTACT4: false,
  GUAR_CUSTOMER_CONTACT5: false,
  GUAR_STATE: false,
  GUAR_TOWN: false,
  GUAR_TALUK: false,
  PAYMENTTYPE: false,
  REGISTRATION: false,
  CHASSIS: false,
  ENGINNUMBER: false,
  ITENOR: false,
  PAYMENT_FREQUENCY: false,
  FROI: false,
  CACCOUNTSTATUS: false,
  FTOTLOANAMT: false,
  DTINSTALLMENTSTARTDATE: false,
  DTINSTALLMENTENDDATE: false,
  FINANCE_CHARGES: false,
  OUTSTANDING_PRINCIPLE: false,
  FAMOUNT: false,
  FCAMOUNT: false,
  LOSSONSALE: false,
  DTDISBURSEMENTDATE: false,
  Arbitration: false,
  "App LRN": false,
  "CoApp LRN": false,
  "Gur LRN": false,
  "App Los": false,
  "CoApp Los": false,
  "Gur Los": false,
  "Repo IA Date": false,
  "Repo Order Date": false,
  "PAN/Bank Attachment IA Date": false,
  "Pan/Bank Attachment Order Date": false,
  "Tahsildar IA Date": false,
  "Tahsildar Order Date": false,
  "RTO IA Date": false,
  "RTO Order Date": false,
  "Property Attachment IA Date": false,
  "Property Attachment Order Date": false,
  "Salary Attachment IA Date": false,
  "Salary Attachment Order Date": false,
};

export const allCaseTypeAction = createAsyncThunk(
  "case/allCaseTypeAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await allCaseType();
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const singleCaseDetailsAction = createAsyncThunk(
  "case/singleCaseTypeAction",
  async (values, { rejectWithValue }) => {
    try {
      const result = await singleCaseDetails(values);

      const data = result[0];

      return data ?? [];
      // return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const allCaseDetailsAction = createAsyncThunk(
  "case/allCaseDetails",
  async (values, { rejectWithValue }) => {
    try {
      const result = await allCaseDetails(values);

      const serializedHeaders = {
        contentType: result.headers["content-type"],
      };

      let trueCount = 0;

      let parsedFilter;
      try {
        parsedFilter = JSON.parse(result?.data?.filter) ?? filter;
      } catch (error) {
        console.error("Failed to parse filter:", error);
      }

      const filterColumn =
        parsedFilter && typeof parsedFilter === "object"
          ? Object.fromEntries(
              Object.entries(parsedFilter).map(([key, value]) => {
                if (value === true && trueCount < 20) {
                  trueCount++;
                  return [key, true];
                }
                return [key, false];
              })
            )
          : {};

      return {
        data: result?.data?.result,
        filter: filterColumn,
        pageCount: result?.data.totalRecords,
        headers: serializedHeaders,
      };
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

export const OrganizationCaseDetailsAction = createAsyncThunk(
  "case/OrganizationCaseDetailsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await organizationCaseDetails(values);

      const serializedHeaders = {
        contentType: result.headers["content-type"],
      };

      const newData = result?.data?.data?.map((item) => {
        try {
          let allCaseDetails = null;
          if (item && item?.["Alter Case Details"]) {
            try {
              const parsedDetails = JSON.parse(item?.["Alter Case Details"]);

              // allCaseDetails = Object.entries(parsedDetails).reduce(
              //   (acc, [key, value]) => {
              //     acc[value.alterHeader] = value.value;
              //     return acc;
              //   },
              //   {}
              // );

              allCaseDetails = Object.entries(parsedDetails).reduce(
                (acc, [key, value]) => {
                  let formattedValue = value.value;
                  // const referenceDate = new Date("1970-01-01");

                  // Check if value.value is a valid date
                  // if (
                  //   value.value instanceof Date &&
                  //   !isNaN(value.value.getTime()) &&
                  //   value.value > referenceDate
                  // ) {
                  //   const day = String(value.value.getDate()).padStart(2, "0");
                  //   const month = String(value.value.getMonth() + 1).padStart(
                  //     2,
                  //     "0"
                  //   ); // Months are zero-indexed
                  //   const year = value.value.getFullYear();
                  //   formattedValue = `${day}-${month}-${year}`;
                  // }

                  acc[value.alterHeader] = formattedValue;
                  return acc;
                },
                {}
              );

              // return allCaseDetails;
            } catch (error) {
              console.error("Failed to parse alterCaseDetails:", error);
            }
          }

          return {
            ...item,
            ...allCaseDetails,
          };
        } catch (err) {}
      });

      // const transformedName = newData?.reduce((acc, item) => {

      //   return {
      //     ...acc, // Accumulate existing properties
      //     ...item, // Spread the current item properties
      //   };
      // }, {});

      return {
        // data: allData,
        data: newData,
        filter: result?.data?.filter,
        pageCount: result?.data.totalRecords,
        headers: serializedHeaders,
      };
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateCaseDetailsAction = createAsyncThunk(
  "case/updateCaseDetailsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateCaseDetails(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const caseDetailsHistoryAction = createAsyncThunk(
  "case/caseDetailsHistoryAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await caseDetailHistory(values);

      const { data, headers } = result;

      const serializedHeaders = {
        contentType: headers["content-type"],
      };

      return { data, headers: serializedHeaders };
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const caseTrackingUpdateAction = createAsyncThunk(
  "case/caseTrackingUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await caseTrackingUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateCasePrimaryInformationAction = createAsyncThunk(
  "case/updateCasePrimaryInformationAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateCasePrimaryInformation(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateInitiationOfArbitrationAction = createAsyncThunk(
  "case/updateInitiationOfArbitrationAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateInitiationOfArbitration(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateAppointmentOfArbitratorSec11and12Action = createAsyncThunk(
  "case/updateAppointmentOfArbitratorSec11and12Action",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateAppointmentOfArbitratorSec11and12(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateArbitrationInterimOrdersReliefsAwardsAction =
  createAsyncThunk(
    "case/updateArbitrationInterimOrdersReliefsAwardsAction",
    async (values, { rejectWithValue, getState }) => {
      try {
        const result =
          await updateArbitrationInterimOrdersReliefsAwards(values);

        return result.data;
      } catch (error) {
        return rejectWithValue(error.message || "something went wrong");
      }
    }
  );

export const updateArbitrationWitnessEvidenceAndExpertReportAction =
  createAsyncThunk(
    "case/updateArbitrationWitnessEvidenceAndExpertReportAction",
    async (values, { rejectWithValue, getState }) => {
      try {
        const result =
          await updateArbitrationWitnessEvidenceAndExpertReport(values);

        return result.data;
      } catch (error) {
        return rejectWithValue(error.message || "something went wrong");
      }
    }
  );

export const updateArbitrationHoldWithdrawalAndSettlementAction =
  createAsyncThunk(
    "case/updateArbitrationHoldWithdrawalAndSettlementAction",
    async (values, { rejectWithValue, getState }) => {
      try {
        const result =
          await updateArbitrationHoldWithdrawalAndSettlement(values);

        return result.data;
      } catch (error) {
        return rejectWithValue(error.message || "something went wrong");
      }
    }
  );

export const updateArbitrationEnforcementAndAppealsAction = createAsyncThunk(
  "case/updateArbitrationEnforcementAndAppealsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateArbitrationEnforcementAndAppeals(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateArbitrationFeePaymentStatusAction = createAsyncThunk(
  "case/updateArbitrationFeePaymentStatusAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateArbitrationFeePaymentStatus(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateArbitrationNoticesKeyDatesAction = createAsyncThunk(
  "case/updateArbitrationNoticesKeyDatesAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateArbitrationNoticesKeyDates(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateArbitrationDurationAndTimelineManagementAction =
  createAsyncThunk(
    "case/updateArbitrationDurationAndTimelineManagementAction",
    async (values, { rejectWithValue, getState }) => {
      try {
        const result =
          await updateArbitrationDurationAndTimelineManagement(values);

        return result.data;
      } catch (error) {
        return rejectWithValue(error.message || "something went wrong");
      }
    }
  );

export const updateArbitrationClausAndAgreementAction = createAsyncThunk(
  "case/updateArbitrationClausAndAgreementAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateArbitrationClausAndAgreement(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateSec17InterimOrdersAction = createAsyncThunk(
  "case/updateSec17InterimOrdersAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateSec17InterimOrders(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
export const updateSec9InterimOrdersAction = createAsyncThunk(
  "case/updateSec9InterimOrdersAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateSec9InterimOrders(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateInterimOrdersAction = createAsyncThunk(
  "case/updateInterimOrdersAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateInterimOrders(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const updateFinalAwardAction = createAsyncThunk(
  "case/updateFinalAwardAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await updateFinalAward(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const allAgreementDocumentListAction = createAsyncThunk(
  "case/allAgreementDocumentList",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await allAgreementDocumentList(values);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const allAgreementDocumentDeleteAction = createAsyncThunk(
  "case/allAgreementDocumentDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await allAgreementDocumentDelete(values);
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

//Column Arrange

export const filterColumnAction = createAsyncThunk(
  "case/filterColumnAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await filterColumn(values);

      return JSON.parse(result.data.data);
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

// Case Bulk Filter

export const caseBulkFilterAction = createAsyncThunk(
  "case/caseBulkFilterAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await caseBulkFilter(values);
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const pdfIDMatchingAction = createAsyncThunk(
  "case/pdfIDMatchingAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await pdfIDMatching(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const dashboardCaseDetailsCountsAction = createAsyncThunk(
  "case/dashboardCaseDetailsCountsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await dashboardCaseDetailsCounts(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const preTrailCaseDetailsCreateAction = createAsyncThunk(
  "case/preTrailCaseDetailsCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await preTrailCaseDetailsCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const preTrailCaseDetailsUpdateAction = createAsyncThunk(
  "case/preTrailCaseDetailsUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await preTrailCaseDetailsUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const preTrailCaseDetailsDocumentsAction = createAsyncThunk(
  "case/preTrailCaseDetailsDocumentsAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await preTrailCaseDetailsDocuments(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const preTrailCaseDetailsDeleteAction = createAsyncThunk(
  "case/preTrailCaseDetailsDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await preTrailCaseDetailsDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantsListOfEvidenceCreateAction = createAsyncThunk(
  "case/claimantsListOfEvidenceCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantsListOfEvidenceCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantsListOfEvidenceDeleteAction = createAsyncThunk(
  "case/claimantsListOfEvidenceDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantsListOfEvidenceDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantsListOfEvidenceUpdateAction = createAsyncThunk(
  "case/claimantsListOfEvidenceUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantsListOfEvidenceUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentsListOfEvidenceCreateAction = createAsyncThunk(
  "case/respondentsListOfEvidenceCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentsListOfEvidenceCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentsListOfEvidenceDeleteAction = createAsyncThunk(
  "case/respondentsListOfEvidenceDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentsListOfEvidenceDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentsListOfEvidenceUpdateAction = createAsyncThunk(
  "case/respondentsListOfEvidenceUpdate",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentsListOfEvidenceUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantSideWitnessCreateAction = createAsyncThunk(
  "case/claimantSideWitnessCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantSideWitnessCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantSideWitnessUpdateAction = createAsyncThunk(
  "case/claimantSideWitnessUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantSideWitnessUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantSideWitnessDeleteAction = createAsyncThunk(
  "case/claimantSideWitnessDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantSideWitnessDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentWitnessCreateAction = createAsyncThunk(
  "case/respondentWitnessCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentWitnessCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentWitnessUpdateAction = createAsyncThunk(
  "case/respondentWitnessUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentWitnessUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentWitnessDeleteAction = createAsyncThunk(
  "case/respondentWitnessDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentWitnessDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantSideArgumentsCreateAction = createAsyncThunk(
  "case/claimantSideArgumentsCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantSideArgumentsCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantSideArgumentsUpdateAction = createAsyncThunk(
  "case/claimantSideArgumentsUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantSideArgumentsUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const claimantSideArgumentsDeleteAction = createAsyncThunk(
  "case/claimantSideArgumentsDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await claimantSideArgumentsDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentSideArgumentsCreateAction = createAsyncThunk(
  "case/respondentSideArgumentsCreateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentSideArgumentsCreate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentSideArgumentsUpdateAction = createAsyncThunk(
  "case/respondentSideArgumentsUpdateAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentSideArgumentsUpdate(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const respondentSideArgumentsDeleteAction = createAsyncThunk(
  "case/respondentSideArgumentsDeleteAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await respondentSideArgumentsDelete(values);

      return result.data;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
