import { createSlice } from "@reduxjs/toolkit";
import {
  OrganizationCaseDetailsAction,
  allAgreementDocumentDeleteAction,
  allAgreementDocumentListAction,
  allCaseDetailsAction,
  allCaseTypeAction,
  caseBulkFilterAction,
  caseDetailsHistoryAction,
  caseTrackingUpdateAction,
  dashboardCaseDetailsCountsAction,
  filterColumnAction,
  pdfIDMatchingAction,
  preTrailCaseDetailsDocumentsAction,
  preTrailCaseDetailsCreateAction,
  singleCaseDetailsAction,
  updateAppointmentOfArbitratorSec11and12Action,
  updateArbitrationClausAndAgreementAction,
  updateArbitrationDurationAndTimelineManagementAction,
  updateArbitrationEnforcementAndAppealsAction,
  updateArbitrationFeePaymentStatusAction,
  updateArbitrationHoldWithdrawalAndSettlementAction,
  updateArbitrationInterimOrdersReliefsAwardsAction,
  updateArbitrationNoticesKeyDatesAction,
  updateArbitrationWitnessEvidenceAndExpertReportAction,
  updateCaseDetailsAction,
  updateCasePrimaryInformationAction,
  updateFinalAwardAction,
  updateInitiationOfArbitrationAction,
  updateInterimOrdersAction,
  updateSec17InterimOrdersAction,
  updateSec9InterimOrdersAction,
  preTrailCaseDetailsUpdateAction,
  preTrailCaseDetailsDeleteAction,
  claimantsListOfEvidenceCreateAction,
  claimantsListOfEvidenceDeleteAction,
  claimantsListOfEvidenceUpdateAction,
  respondentsListOfEvidenceCreateAction,
  respondentsListOfEvidenceDeleteAction,
  respondentsListOfEvidenceUpdateAction,
  claimantSideWitnessCreateAction,
  claimantSideWitnessUpdateAction,
  claimantSideWitnessDeleteAction,
  respondentWitnessCreateAction,
  respondentWitnessUpdateAction,
  respondentWitnessDeleteAction,
  claimantSideArgumentsCreateAction,
  claimantSideArgumentsUpdateAction,
  claimantSideArgumentsDeleteAction,
  respondentSideArgumentsCreateAction,
  respondentSideArgumentsUpdateAction,
  respondentSideArgumentsDeleteAction,
} from "../../actions/Case/caseAction";
import {
  allCaseMappingDetailAction,
  caseMappingDetailAction,
  caseMappingDetailIsActiveAction,
  caseMappingDetailUpdateAction,
  caseMappingUploadAction,
  organizationActiveCaseTemplateAction,
  selectedCaseMappingDetailAction,
} from "../../actions/Case/caseMappingAction";
import { animateCss } from "../../../pages/TosterAlert";
import { caseDetailsFindCaseIDAction } from "../../actions/Checklist/checklistAction";
import { createSplitChecklistDocumentAction } from "../../actions/checklistDocuments/checklistDocumentsAction";
import {
  UpdateCaseFilterAction,
  userUpdateCaseFilterAction,
} from "../../actions/UserAction/usersAction";
import { exportPDFDocumentAction } from "../../actions/hooks/exportDocumentAction";

const caseInitialState = {
  settings: [],
  history: [],
  editCaseDetails: null,
  caseMappingDetails: {
    allCaseMappingDetails: [],
    mappingDetail: [],
    mappingTemplate: [],
  },
  caseDetails: {
    allData: [],
    pageCount: null,
    caseTemplate: [],
  },
  preTrailCaseDetailsDocuments: [],
  caseFilter: null,
  columnArrange: null,
  allotment: {
    allotmentHeader: [],
    allotmentOrganization: [],
    allotmentOrganizationTemplate: [],
  },
  loading: false,
  data: null,
  createCase: {
    loading: false,
    error: "",
  },
  agreementDocumentList: null,
  notMatchingID: [],
};

const filter = {
  "#": true,
  caseID: true,
};

const caseSlice = createSlice({
  name: "case",
  initialState: caseInitialState,
  reducers: {
    resetMappingDetailsReducer: (state, action) => {
      state.caseMappingDetails.mappingDetail = [];
      state.caseMappingDetails.mappingTemplate = [];
      return state;
    },
    resetMappingAllDetailsReducer: (state, action) => {
      state.caseMappingDetails = {
        allCaseMappingDetails: [],
        mappingDetail: [],
        mappingTemplate: [],
      };

      return state;
    },
    allotmentHeaderReducer: (state, action) => {
      state.allotment.allotmentHeader = action.payload;
    },
    mappingTemplateReducer: (state, action) => {
      state.caseMappingDetails.mappingTemplate =
        action?.payload?.mappingData ?? action.payload;
      state.caseMappingDetails.mappingDetail = action?.payload?.mappingData
        ? action.payload
        : [];
      return state;
    },
    resetSingleCaseDetailsReducer: (state, action) => {
      state.editCaseDetails = null;
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(allCaseTypeAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(allCaseTypeAction.fulfilled, (state, action) => {
        state.settings = action.payload?.data?.[0];
        state.loading = false;
      })
      .addCase(allCaseTypeAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(allCaseMappingDetailAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(allCaseMappingDetailAction.fulfilled, (state, action) => {
        state.loading = false;
        state.caseMappingDetails.allCaseMappingDetails = action.payload;
      })
      .addCase(allCaseMappingDetailAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(selectedCaseMappingDetailAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(selectedCaseMappingDetailAction.fulfilled, (state, action) => {
        state.loading = false;
        state.caseMappingDetails.allCaseMappingDetails = action.payload;
        return state;
      })
      .addCase(selectedCaseMappingDetailAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(caseMappingUploadAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(caseMappingUploadAction.fulfilled, (state, action) => {
        state.loading = false;
        animateCss("success", "Case Template Created Successfully");
      })
      .addCase(caseMappingUploadAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(
        organizationActiveCaseTemplateAction.pending,
        (state, action) => {
          state.loading = true;
          return state;
        }
      )
      .addCase(
        organizationActiveCaseTemplateAction.fulfilled,
        (state, action) => {
          state.caseMappingDetails.allotmentOrganizationTemplate = [];
          if (action.payload) {
            state.allotment.allotmentOrganizationTemplate =
              action.payload?.template;
          }
          if (!action.payload) {
            state.allotment.allotmentOrganizationTemplate = [];
          }

          state.loading = false;
          return state;
        }
      )
      .addCase(
        organizationActiveCaseTemplateAction.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      .addCase(caseMappingDetailUpdateAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(caseMappingDetailUpdateAction.fulfilled, (state, action) => {
        if (action.payload.data) {
          animateCss("success", "Case Template Update Successfully");
          state.caseMappingDetails.mappingDetail = [];
          state.caseMappingDetails.mappingTemplate = [];
        }
        state.loading = false;
      })
      .addCase(caseMappingDetailUpdateAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(caseMappingDetailIsActiveAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(caseMappingDetailIsActiveAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(caseMappingDetailIsActiveAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(OrganizationCaseDetailsAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(
        OrganizationCaseDetailsAction.fulfilled,
        (state, { payload }) => {
          state.caseDetails.allData = payload?.data;
          state.caseDetails.pageCount = payload?.pageCount;
          state.caseFilter = JSON.parse(payload?.filter) ?? filter;
          state.loading = false;
          return state;
        }
      )
      .addCase(OrganizationCaseDetailsAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(allCaseDetailsAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(allCaseDetailsAction.fulfilled, (state, { payload }) => {
        let trueCount = 0;

        state.caseDetails.allData = payload?.data;
        state.caseDetails.pageCount = payload?.pageCount;
        // state.caseFilter = Object.fromEntries(
        //   Object.entries(payload?.filter).map(([key, value]) => {
        //     if (value === true && trueCount < 20) {
        //       trueCount++;
        //       return [key, true];
        //     }
        //     return [key, false];
        //   })
        // );

        // payload?.filter;
        state.loading = false;
        return state;
      })
      .addCase(allCaseDetailsAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(singleCaseDetailsAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(singleCaseDetailsAction.fulfilled, (state, action) => {
        let value = action.payload;
        value.alterCaseDetails = value?.alterCaseDetails
          ? JSON.parse(value?.alterCaseDetails)
          : null;
        state.editCaseDetails = value;
        state.loading = false;
        return state;
      })
      .addCase(singleCaseDetailsAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateCaseDetailsAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(updateCaseDetailsAction.fulfilled, (state, action) => {
        animateCss("success", "Case Detail Update Successfully");
        setTimeout(() => {
          allCaseDetailsAction();
        }, 1000);
        state.loading = false;
      })
      .addCase(updateCaseDetailsAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(caseDetailsHistoryAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(caseDetailsHistoryAction.fulfilled, (state, action) => {
        state.history = action.payload?.data;

        state.loading = false;
      })
      .addCase(caseDetailsHistoryAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(caseTrackingUpdateAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(caseTrackingUpdateAction.fulfilled, (state, action) => {
        if (action.payload?.data) {
          animateCss("success", "Case Tracking Update Successfully");
        }
        state.loading = false;
      })
      .addCase(caseTrackingUpdateAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(caseDetailsFindCaseIDAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(caseDetailsFindCaseIDAction.fulfilled, (state, action) => {
        state.loading = false;
        createSplitChecklistDocumentAction(action.meta.arg);
        return state;
      })
      .addCase(caseDetailsFindCaseIDAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateCasePrimaryInformationAction.pending, (state) => {
        state.loading = true;
        return state;
      })
      .addCase(updateCasePrimaryInformationAction.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateCasePrimaryInformationAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateInitiationOfArbitrationAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(updateInitiationOfArbitrationAction.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateInitiationOfArbitrationAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(
        updateAppointmentOfArbitratorSec11and12Action.pending,
        (state) => {
          state.loading = true;
          return state;
        }
      )

      .addCase(
        updateAppointmentOfArbitratorSec11and12Action.fulfilled,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(
        updateAppointmentOfArbitratorSec11and12Action.rejected,
        (state) => {
          state.loading = false;
        }
      )
      .addCase(
        updateArbitrationInterimOrdersReliefsAwardsAction.pending,
        (state) => {
          state.loading = true;
          return state;
        }
      )

      .addCase(
        updateArbitrationInterimOrdersReliefsAwardsAction.fulfilled,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(
        updateArbitrationInterimOrdersReliefsAwardsAction.rejected,
        (state) => {
          state.loading = false;
        }
      )
      .addCase(
        updateArbitrationWitnessEvidenceAndExpertReportAction.pending,
        (state) => {
          state.loading = true;
          return state;
        }
      )

      .addCase(
        updateArbitrationWitnessEvidenceAndExpertReportAction.fulfilled,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(
        updateArbitrationWitnessEvidenceAndExpertReportAction.rejected,
        (state) => {
          state.loading = false;
        }
      )
      .addCase(
        updateArbitrationHoldWithdrawalAndSettlementAction.pending,
        (state) => {
          state.loading = true;
          return state;
        }
      )

      .addCase(
        updateArbitrationHoldWithdrawalAndSettlementAction.fulfilled,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(
        updateArbitrationHoldWithdrawalAndSettlementAction.rejected,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(
        updateArbitrationEnforcementAndAppealsAction.pending,
        (state) => {
          state.loading = true;
          return state;
        }
      )

      .addCase(
        updateArbitrationEnforcementAndAppealsAction.fulfilled,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(
        updateArbitrationEnforcementAndAppealsAction.rejected,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(updateArbitrationFeePaymentStatusAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(updateArbitrationFeePaymentStatusAction.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateArbitrationFeePaymentStatusAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(updateArbitrationNoticesKeyDatesAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(updateArbitrationNoticesKeyDatesAction.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateArbitrationNoticesKeyDatesAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(
        updateArbitrationDurationAndTimelineManagementAction.pending,
        (state) => {
          state.loading = true;
          return state;
        }
      )

      .addCase(
        updateArbitrationDurationAndTimelineManagementAction.fulfilled,
        (state) => {
          state.loading = false;
        }
      )

      .addCase(
        updateArbitrationDurationAndTimelineManagementAction.rejected,
        (state) => {
          state.loading = false;
        }
      )
      .addCase(updateArbitrationClausAndAgreementAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(updateArbitrationClausAndAgreementAction.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateArbitrationClausAndAgreementAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(updateSec17InterimOrdersAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(updateSec17InterimOrdersAction.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateSec17InterimOrdersAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(updateSec9InterimOrdersAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(updateSec9InterimOrdersAction.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateSec9InterimOrdersAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(updateInterimOrdersAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(updateInterimOrdersAction.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateInterimOrdersAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(updateFinalAwardAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(updateFinalAwardAction.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(updateFinalAwardAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(allAgreementDocumentListAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(allAgreementDocumentListAction.fulfilled, (state, action) => {
        state.loading = false;
        state.agreementDocumentList = action.payload?.data;
        return state;
      })

      .addCase(allAgreementDocumentListAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(allAgreementDocumentDeleteAction.pending, (state) => {
        state.loading = true;
        return state;
      })

      .addCase(allAgreementDocumentDeleteAction.fulfilled, (state) => {
        state.loading = false;
        return state;
      })

      .addCase(allAgreementDocumentDeleteAction.rejected, (state) => {
        state.loading = false;
      })

      .addCase(caseBulkFilterAction.pending, (state, action) => {
        state.loading = true;

        return state;
      })

      .addCase(caseBulkFilterAction.fulfilled, (state, action) => {
        state.loading = false;

        return state;
      })

      .addCase(caseBulkFilterAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(userUpdateCaseFilterAction.pending, (state, action) => {
        return state;
      })
      .addCase(userUpdateCaseFilterAction.fulfilled, (state, action) => {
        // state.caseFilter = action.meta.arg?.columnVisibility;
        let trueCount = 0;
        const casefilter = Object.fromEntries(
          Object.entries(action.meta.arg?.columnVisibility).map(
            ([key, value]) => {
              if (value === true && trueCount < 20) {
                trueCount++;
                return [key, true];
              }
              return [key, false];
            }
          )
        );
        const filterColumn =
          casefilter && typeof casefilter === "object"
            ? Object.fromEntries(
                Object.entries(casefilter).map(([key, value]) => {
                  if (value === true && trueCount < 20) {
                    trueCount++;
                    return [key, true];
                  }
                  return [key, false];
                })
              )
            : {};

        const extractTrueKeys = (casefilter) => {
          if (casefilter && typeof casefilter === "object") {
            return [
              "#",
              ...Object.keys(casefilter).filter(
                (key) => casefilter[key] === true
              ),
            ];
          }
          return [];
        };

        state.caseFilter = casefilter;

        state.columnArrange = Object.keys(casefilter);
        // extractTrueKeys(casefilter);
        return state;
      })
      .addCase(userUpdateCaseFilterAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(UpdateCaseFilterAction.pending, (state, action) => {
        return state;
      })
      .addCase(UpdateCaseFilterAction.fulfilled, (state, action) => {
        // state.columnArrange = Object.keys(action.meta.arg?.columnVisibility);
        // return state;
      })
      .addCase(UpdateCaseFilterAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(pdfIDMatchingAction.pending, (state, action) => {
        return state;
      })
      .addCase(pdfIDMatchingAction.fulfilled, (state, action) => {
        state.notMatchingID = action.payload?.data;
        return state;
      })
      .addCase(pdfIDMatchingAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(filterColumnAction.pending, (state, action) => {
        return state;
      })
      .addCase(filterColumnAction.fulfilled, (state, { payload }) => {
        let trueCount = 0;
        const filter = Object.fromEntries(
          Object.entries(payload).map(([key, value]) => {
            if (value === true && trueCount < 20) {
              trueCount++;
              return [key, true];
            }
            return [key, false];
          })
        );
        const extractTrueKeys = (casefilter) => {
          if (casefilter && typeof casefilter === "object") {
            return [
              "#",
              ...Object.keys(casefilter).filter(
                (key) => casefilter[key] === true
              ),
            ];
          }
          return [];
        };
        state.caseFilter = filter;
        state.columnArrange = extractTrueKeys(filter);

        return state;
      })
      .addCase(filterColumnAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(dashboardCaseDetailsCountsAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        dashboardCaseDetailsCountsAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(dashboardCaseDetailsCountsAction.rejected, (state, action) => {
        state.error = action.payload;
      })

      .addCase(preTrailCaseDetailsCreateAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        preTrailCaseDetailsCreateAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(preTrailCaseDetailsCreateAction.rejected, (state, action) => {
        state.error = action.payload;
      })

      .addCase(preTrailCaseDetailsUpdateAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        preTrailCaseDetailsUpdateAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(preTrailCaseDetailsUpdateAction.rejected, (state, action) => {
        state.error = action.payload;
      })

      .addCase(preTrailCaseDetailsDocumentsAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        preTrailCaseDetailsDocumentsAction.fulfilled,
        (state, { payload }) => {
          state.preTrailCaseDetailsDocuments = payload?.documents;
          return state;
        }
      )
      .addCase(preTrailCaseDetailsDocumentsAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(preTrailCaseDetailsDeleteAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        preTrailCaseDetailsDeleteAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(preTrailCaseDetailsDeleteAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(claimantsListOfEvidenceCreateAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        claimantsListOfEvidenceCreateAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(
        claimantsListOfEvidenceCreateAction.rejected,
        (state, action) => {
          state.error = action.payload;
        }
      )
      .addCase(claimantsListOfEvidenceDeleteAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        claimantsListOfEvidenceDeleteAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(
        claimantsListOfEvidenceDeleteAction.rejected,
        (state, action) => {
          state.error = action.payload;
        }
      )
      .addCase(claimantsListOfEvidenceUpdateAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        claimantsListOfEvidenceUpdateAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(
        claimantsListOfEvidenceUpdateAction.rejected,
        (state, action) => {
          state.error = action.payload;
        }
      )
      .addCase(
        respondentsListOfEvidenceCreateAction.pending,
        (state, action) => {
          return state;
        }
      )
      .addCase(
        respondentsListOfEvidenceCreateAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(
        respondentsListOfEvidenceCreateAction.rejected,
        (state, action) => {
          state.error = action.payload;
        }
      )
      .addCase(
        respondentsListOfEvidenceDeleteAction.pending,
        (state, action) => {
          return state;
        }
      )
      .addCase(
        respondentsListOfEvidenceDeleteAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(
        respondentsListOfEvidenceDeleteAction.rejected,
        (state, action) => {
          state.error = action.payload;
        }
      )
      .addCase(
        respondentsListOfEvidenceUpdateAction.pending,
        (state, action) => {
          return state;
        }
      )
      .addCase(
        respondentsListOfEvidenceUpdateAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(
        respondentsListOfEvidenceUpdateAction.rejected,
        (state, action) => {
          state.error = action.payload;
        }
      )
      .addCase(claimantSideWitnessCreateAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        claimantSideWitnessCreateAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(claimantSideWitnessCreateAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(claimantSideWitnessUpdateAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        claimantSideWitnessUpdateAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(claimantSideWitnessUpdateAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(claimantSideWitnessDeleteAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        claimantSideWitnessDeleteAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(claimantSideWitnessDeleteAction.rejected, (state, action) => {
        state.error = action.payload;
      })

      .addCase(respondentWitnessCreateAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        respondentWitnessCreateAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(respondentWitnessCreateAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(respondentWitnessUpdateAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        respondentWitnessUpdateAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(respondentWitnessUpdateAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(respondentWitnessDeleteAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        respondentWitnessDeleteAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(respondentWitnessDeleteAction.rejected, (state, action) => {
        state.error = action.payload;
      })

      .addCase(claimantSideArgumentsCreateAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        claimantSideArgumentsCreateAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(claimantSideArgumentsCreateAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(claimantSideArgumentsUpdateAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        claimantSideArgumentsUpdateAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(claimantSideArgumentsUpdateAction.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(claimantSideArgumentsDeleteAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        claimantSideArgumentsDeleteAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(claimantSideArgumentsDeleteAction.rejected, (state, action) => {
        state.error = action.payload;
      })

      //

      .addCase(respondentSideArgumentsCreateAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        respondentSideArgumentsCreateAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(
        respondentSideArgumentsCreateAction.rejected,
        (state, action) => {
          state.error = action.payload;
        }
      )
      .addCase(respondentSideArgumentsUpdateAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        respondentSideArgumentsUpdateAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(
        respondentSideArgumentsUpdateAction.rejected,
        (state, action) => {
          state.error = action.payload;
        }
      )
      .addCase(respondentSideArgumentsDeleteAction.pending, (state, action) => {
        return state;
      })
      .addCase(
        respondentSideArgumentsDeleteAction.fulfilled,
        (state, { payload }) => {
          return state;
        }
      )
      .addCase(
        respondentSideArgumentsDeleteAction.rejected,
        (state, action) => {
          state.error = action.payload;
        }
      ),
});

export const {
  resetMappingDetailsReducer,
  resetMappingAllDetailsReducer,
  allotmentHeaderReducer,
  mappingTemplateReducer,
  resetSingleCaseDetailsReducer,
} = caseSlice.actions;
export default caseSlice.reducer;
