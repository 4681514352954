import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Stack,
  styled,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DatePicker from "react-datepicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  preTrailCaseDetailsDocumentsAction,
  singleCaseDetailsAction,
  respondentsListOfEvidenceCreateAction,
  respondentsListOfEvidenceUpdateAction,
  respondentsListOfEvidenceDeleteAction,
} from "../../../../redux/actions/Case/caseAction";
import React, { useEffect, useState } from "react";
import { animateCss } from "../../../TosterAlert";
import { MdDelete } from "react-icons/md";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { IoMdAttach } from "react-icons/io";
import { closeSnackbar, enqueueSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const RespondentsListOfEvidence = ({
  expanded,
  openModel,
  setOpenModel,
  setViewDocument,
  setEditIndex,
  editIndex,
  handleAccordionChange,
  formValue,
  setFormValue,
}) => {
  const [editForm, setEditForm] = useState(false);
  const dispatch = useDispatch();

  const { id } = useParams();
  const params = { caseID: id };
  const rowData = useSelector((state) => state?.case?.editCaseDetails);
  const settings = useSelector((state) => state?.ChecklistDocument?.settings);

  const data = rowData.RespondentsListOfEvidence
    ? (JSON.parse(JSON.parse(rowData.RespondentsListOfEvidence)) ??
      JSON.parse(rowData.RespondentsListOfEvidence))
    : [];

  useEffect(() => {
    setTimeout(() => {
      dispatch(singleCaseDetailsAction(params));
    }, 3000);
  }, [openModel]);

  return (
    <>
      <div
        className="d-flex row p-0 m-0"
        spacing={2}
        style={{ backgroundColor: "#fbfbfc" }}
      >
        {editForm && expanded === 2 && editForm ? (
          <div className="d-flex row col-12 mt-2  ">
            <form
              className="pe-0"
              // onSubmit={handleSubmit}
              style={{ boxShadow: "none" }}
              xs={12}
              sm={12}
              md={12}
            >
              <Accordion expanded={expanded === 2}>
                <AccordionSummary
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box
                    className="p-0 m-0"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      width: "100%",
                      backgroundColor: "white",
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="m-0"
                      sx={{
                        "&:hover": {
                          color: "primary.main",
                        },
                      }}
                      onClick={() => handleAccordionChange(2)}
                    >
                      Respondent's List of Evidence
                    </Typography>
                    <div>
                      <Button
                        className="mx-2"
                        type="button"
                        variant="contained"
                        color="secondary"
                        onClick={(event) => {
                          event.stopPropagation();
                          setEditForm(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "end",
                          backgroundColor: "white",
                          flexWrap: "wrap",
                          alignItems: "stretch",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "end",
                            px: 2,
                            gridTemplateColumns: {
                              xs: "1fr",
                              sm: "1fr",
                              md: "1fr",
                            },
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "100%",
                            },
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setEditIndex(null);
                              setOpenModel(true);
                            }}
                            disabled={data?.length == 10}
                            className="justify-content-center"
                          >
                            Respondent's Submission Form
                          </Button>
                        </Box>
                        <ModelForm
                          values={data}
                          settings={settings}
                          formValue={formValue}
                          setFormValue={setFormValue}
                          setOpenModel={setOpenModel}
                          openModel={openModel}
                          editIndex={editIndex}
                          setEditIndex={setEditIndex}
                        />

                        <Box
                          sx={{
                            display: "grid",
                            // px: 2,
                            gridTemplateColumns: {
                              xs: "1fr",
                              sm: "1fr",
                              md: "1fr",
                            },
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "100%",
                            },
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          {data?.length > 0 && (
                            <Box
                              className="d-flex col-12"
                              // style={{
                              //   overflowX: "auto",
                              //   width: "100%",
                              // }}
                            >
                              <table
                                striped
                                bordered
                                hover
                                className="my-2 table"
                              >
                                <thead
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "lighter",
                                  }}
                                >
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Evidence Number
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Evidence Name
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Status
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Attachment
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {data?.map((order, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <Typography
                                            gutterBottom
                                            variant="p"
                                            component="div"
                                            className="m-0"
                                            sx={{
                                              "&:hover": {
                                                color: "primary.main",
                                                cursor: "pointer",
                                              },
                                            }}
                                            onClick={() => {
                                              if (window.edit) {
                                                setOpenModel(true);
                                                setEditIndex(index);

                                                const uniqueDocumentId =
                                                  order?.[
                                                    "Evidence Attachment"
                                                  ];
                                                const document = {
                                                  uniqueDocumentId,
                                                };
                                                dispatch(
                                                  preTrailCaseDetailsDocumentsAction(
                                                    document
                                                  )
                                                );
                                              } else {
                                                animateCss(
                                                  "info",
                                                  "You are not authorized to update"
                                                );
                                              }
                                            }}
                                          >
                                            Evidence {index + 1}
                                          </Typography>{" "}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "12px",
                                          }}
                                        >
                                          {order?.evidenceName}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "12px",
                                          }}
                                        >
                                          {order?.evidenceStatus}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "12px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {order?.["Evidence Attachment"]
                                            ?.length > 0 && (
                                            <IconButton aria-label="attach">
                                              <IoMdAttach className="fs-3 text-primary" />
                                            </IconButton>
                                          )}
                                        </td>
                                        <td
                                          className={
                                            data?.length - 1 == index &&
                                            "d-flex"
                                          }
                                        >
                                          {data?.length - 1 == index && (
                                            <IconButton
                                              aria-label="delete"
                                              color="error"
                                              style={{
                                                marginTop: "4%",
                                              }}
                                              onClick={() => {
                                                if (window.delete) {
                                                  enqueueSnackbar(
                                                    <>
                                                      <div>
                                                        Are you sure you want to
                                                        delete the evidence and
                                                        its associated document
                                                        for Claimant's List of
                                                        Evidence {index + 1} ?
                                                      </div>
                                                    </>,
                                                    {
                                                      variant: "warning",
                                                      onClose: () => {},
                                                      anchorOrigin: {
                                                        vertical: "top",
                                                        horizontal: "right",
                                                      },
                                                      action: (key) => (
                                                        <>
                                                          <StyledButton
                                                            onClick={async () => {
                                                              const output =
                                                                formValue.filter(
                                                                  (_, i) =>
                                                                    i !== index
                                                                );

                                                              setFormValue(
                                                                output
                                                              );
                                                              const value = {
                                                                caseID: params,
                                                                index,
                                                              };
                                                              dispatch(
                                                                respondentsListOfEvidenceDeleteAction(
                                                                  value
                                                                )
                                                              );

                                                              closeSnackbar(
                                                                key
                                                              );

                                                              setTimeout(() => {
                                                                dispatch(
                                                                  singleCaseDetailsAction(
                                                                    params
                                                                  )
                                                                );
                                                              }, 1000);
                                                            }}
                                                          >
                                                            Confirm
                                                          </StyledButton>
                                                          <StyledButton
                                                            onClick={() => {
                                                              closeSnackbar(
                                                                key
                                                              );
                                                            }}
                                                          >
                                                            Cancel
                                                          </StyledButton>
                                                        </>
                                                      ),
                                                      autoHideDuration: null,
                                                      style: {
                                                        backgroundColor:
                                                          "#f44336",
                                                        width: "600px",
                                                        color: "#fff",
                                                      },
                                                    }
                                                  );
                                                } else {
                                                  animateCss(
                                                    "info",
                                                    "You are not authorized to delete"
                                                  );
                                                }
                                              }}
                                            >
                                              <MdDelete
                                                style={{
                                                  fontSize: "24px",
                                                }}
                                              />
                                            </IconButton>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </Box>
                          )}
                        </Box>
                      </Box>

                      {/* Dynamice Data */}
                    </LocalizationProvider>
                  </>
                </AccordionDetails>
              </Accordion>
            </form>
          </div>
        ) : (
          <>
            <Accordion className="mt-2 " expanded={expanded === 2}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="m-0"
                    sx={{
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                    onClick={() => handleAccordionChange(2)}
                  >
                    Respondent's List of Evidence
                  </Typography>

                  {window?.edit && (
                    <div>
                      <Button
                        type="button"
                        color="primary"
                        onClick={(event) => {
                          event.stopPropagation();

                          if (window.edit) {
                            event.stopPropagation();
                            // setPreTrialApplications(true);
                            setEditForm(true);
                          } else {
                            animateCss(
                              "info",
                              "You are not authorized to update"
                            );
                          }
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={0}>
                  <Box
                    sx={{
                      display: "grid",
                      // px: 2,
                      gridTemplateColumns: {
                        xs: "1fr",
                        sm: "1fr",
                        md: "1fr",
                      },
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                      },
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Box
                      className="d-flex col-12"
                      style={{
                        overflowX: "auto",
                        width: "100%",
                      }}
                    >
                      <table striped bordered hover className="table my-2">
                        <thead
                          style={{
                            fontSize: "12px",
                            fontWeight: "lighter",
                          }}
                        >
                          <tr>
                            <th
                              style={{
                                fontWeight: "lighter",
                              }}
                              className="border"
                            >
                              Evidence Number
                            </th>

                            <th
                              style={{
                                fontWeight: "lighter",
                              }}
                              className="border"
                            >
                              Evidence Name
                            </th>

                            <th
                              style={{
                                fontWeight: "lighter",
                              }}
                              className="border"
                            >
                              Status
                            </th>

                            <th
                              style={{
                                fontWeight: "lighter",
                              }}
                              className="border"
                            >
                              Attachment
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {data?.map((order, index) => {
                            return (
                              <tr key={index}>
                                <td>Evidence {index + 1}</td>
                                <td
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {order?.evidenceName}
                                </td>
                                <td
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {order?.evidenceStatus}
                                </td>
                                <td
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {order?.["Evidence Attachment"]?.length >
                                    0 && (
                                    <IconButton
                                      onClick={() => {
                                        setViewDocument(true);
                                        const uniqueDocumentId =
                                          order?.["Evidence Attachment"];

                                        const document = { uniqueDocumentId };
                                        dispatch(
                                          preTrailCaseDetailsDocumentsAction(
                                            document
                                          )
                                        );
                                      }}
                                      aria-label="attach"
                                    >
                                      <IoMdAttach className="fs-3 text-primary" />
                                    </IconButton>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Box>
                  </Box>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </div>
    </>
  );
};

export default RespondentsListOfEvidence;

const ModelForm = ({
  setPreTrialApplications,
  values,
  formValue,
  setFormValue,
  settings,
  setOpenModel,
  openModel,
  editIndex,
  setEditIndex,
}) => {
  const [formData, setFormData] = useState(null);
  const data = values;

  const claimantPreTrailApplicationAttachmentsOld =
    data[editIndex]?.["Evidence Attachment"] ?? [];

  const uniqueevidenceAttachment = Array.from(
    new Set(claimantPreTrailApplicationAttachmentsOld?.map(Number))
  );

  const uniqueDocumentId = [...(uniqueevidenceAttachment || [])];

  const dispatch = useDispatch();

  const { id } = useParams();

  const params = { caseID: id };

  const document = {
    uniqueDocumentId,
    id,
  };

  // Updating formData based on editIndex
  useEffect(() => {
    // dispatch(preTrailCaseDetailsDocumentsAction(document));
    if (editIndex >= 0) {
      setFormData(data?.[editIndex]);
    } else {
      setFormData(null);
    }

    if (claimantPreTrailApplicationAttachmentsOld?.length >= 0) {
      const documentId = [...(uniqueDocumentId || [])];
      // Ensure all IDs are unique
      const uniqueDocumentsId = [...new Set(documentId)];

      const document = {
        uniqueDocumentsId,
        id,
      };
    }
  }, [editIndex, openModel]);

  const reduxAttachments = useSelector(
    (state) => state.case?.preTrailCaseDetailsDocuments
  );

  const evidenceAttachmentDoc = useSelector(
    (state) => state.case?.preTrailCaseDetailsDocuments
  )?.filter((item) => uniqueDocumentId?.includes(item.checkListDocID));

  let initialValues = {
    evidenceFiledOn: formData?.evidenceFiledOn ?? "",
    evidenceName: formData?.evidenceName ?? "",
    evidenceAttachment: evidenceAttachmentDoc ?? [],
    evidenceStatus: formData?.evidenceStatus ?? "",
    evidenceServedToClaimant: formData?.evidenceServedToClaimant ?? "",
    evidenceServedToClaimantBy: formData?.evidenceServedToClaimantBy ?? "",
    evidenceServedToClaimantOn: formData?.evidenceServedToClaimantOn ?? "",
    claimantsAcknowledgementReceived:
      formData?.claimantsAcknowledgementReceived ?? "",
    acknowledgementReceivedOn: formData?.acknowledgementReceivedOn ?? "",
    editIndex,
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    Formik.setFieldValue("claimantsPreTrailApplicationAttachments", files);
  };

  return (
    <Dialog
      open={openModel}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          width: "50%",
          maxWidth: "100%",
          padding: 16,
        },
      }}
    >
      <Box sx={{ display: "grid", p: 2, width: "100%", height: "100%" }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            try {
              const form = new FormData();
              form.append("evidenceFiledOn", values.evidenceFiledOn);
              form.append("evidenceName", values.evidenceName);
              form.append("evidenceStatus", values.evidenceStatus);
              form.append(
                "evidenceServedToClaimant",
                values.evidenceServedToClaimant
              );
              form.append("caseID", id);
              form.append(
                "evidenceServedToClaimantBy",
                values.evidenceServedToClaimantBy
              );
              form.append(
                "evidenceServedToClaimantOn",
                values.evidenceServedToClaimantOn
              );
              form.append(
                "claimantsAcknowledgementReceived",
                values.claimantsAcknowledgementReceived
              );
              form.append(
                "acknowledgementReceivedOn",
                values.acknowledgementReceivedOn
              );

              if (values.evidenceAttachment) {
                values.evidenceAttachment.forEach((file) => {
                  if (file?.caseID) {
                    form.append(
                      `claimantPreTrailApplicationAttachmentsOld`,
                      file?.checkListDocID
                    );
                  } else {
                    form.append(`Evidence Attachment`, file?.file);
                  }
                });
              }

              if (editIndex == null) {
                dispatch(respondentsListOfEvidenceCreateAction(form))
                  .unwrap()
                  .then((result) => {
                    setOpenModel(false);
                    if (result.documents) {
                      setTimeout(() => {
                        dispatch(singleCaseDetailsAction(params));
                        setOpenModel(false);
                      }, 2000);
                    }
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              } else if (editIndex >= 0) {
                const updatedData = [...formValue];
                updatedData[editIndex] = values;
                setFormValue(updatedData);
                form.append("editIndex", editIndex);
                dispatch(respondentsListOfEvidenceUpdateAction(form))
                  .unwrap()
                  .then((result) => {
                    setOpenModel(false);
                    if (result.documents) {
                      setTimeout(() => {
                        dispatch(singleCaseDetailsAction(params));
                      }, 3000);
                    }
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              }
              // dispatch(
              //   allAgreementDocumentListAction({
              //     value: "Arbitration Agreement",
              //     caseID: id,
              //   })
              // );

              actions.resetForm();
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Evidence Filed On
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="evidenceFiledOn"
                  selected={values?.evidenceFiledOn}
                  onChange={(date) => setFieldValue("evidenceFiledOn", date)}
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.evidenceFiledOn && errors.evidenceFiledOn && (
                  <Typography color="error">
                    {errors.evidenceFiledOn}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="evidenceName">Evidence Name</InputLabel>
                <OutlinedInput
                  id="evidenceName"
                  name="evidenceName"
                  value={values?.evidenceName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.evidenceName && errors.evidenceName && (
                  <Typography color="error">{errors.evidenceName}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Evidence Attachment
                </Typography>
                <Button
                  className="col-12 col-sm-12 col-md-6 col-xl-3"
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload files
                  <VisuallyHiddenInput
                    type="file"
                    multiple
                    accept="application/pdf"
                    onChange={(event) => {
                      const existingFiles = values?.evidenceAttachment || [];
                      const newFiles = Array.from(event.target.files);

                      const updatedFiles = [
                        ...new Map(
                          [...existingFiles, ...newFiles].map((file) => [
                            file.name || file?.actualDocName,
                            {
                              file: file?.file ?? file,
                              checkListDocID: file?.checkListDocID || null,
                              caseRowID: file?.caseRowID || null,
                              caseID: file?.caseID || null,
                              checklistID: file?.checklistID || null,
                              genSetID: file?.genSetID || null,
                              checklistDate: null,
                              actualDocName: file.name || file?.actualDocName,
                              uploadDocName: file.name || file?.uploadDocName,
                              documentType: file.type || file.documentType,
                              documentSize:
                                file.documentSize || file.size?.toString(),
                              documentClassification: null,
                              documentService: null,
                              serviceCount: null,
                              comments: null,
                              createdBy: file?.createdBy || 1,
                              updatedBy: file?.updatedBy || 1,
                              isActive: 1,
                              createdAt:
                                file?.createdAt || new Date()?.toISOString(),
                              updatedAt:
                                file?.updatedAt || new Date()?.toISOString(),
                            },
                          ])
                        ).values(),
                      ];

                      setFieldValue("evidenceAttachment", updatedFiles);
                    }}
                  />
                </Button>
                {touched.evidenceAttachment && errors.evidenceAttachment && (
                  <Typography color="error">
                    {errors.evidenceAttachment}
                  </Typography>
                )}
              </Box>

              {values.evidenceAttachment.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  {values.evidenceAttachment?.map((file, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={file?.actualDocName || file.name}
                          onDelete={() => {
                            const updatedFiles =
                              values.evidenceAttachment.filter(
                                (_, fileIndex) => fileIndex !== index
                              );
                            setFieldValue("evidenceAttachment", updatedFiles);
                          }}
                        />
                      </Stack>
                    </Box>
                  ))}
                </Box>
              )}

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="evidenceStatus">
                  Evidence Status
                </InputLabel>
                <Select
                  labelId="evidenceStatus"
                  id="evidenceStatus"
                  name="evidenceStatus"
                  value={values?.evidenceStatus}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find((item) => item?.categoryName === "Evidence Status")
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.categoryName}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.evidenceStatus && errors.evidenceStatus && (
                  <Typography color="error">{errors.evidenceStatus}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Evidence Served to Claimant
                </Typography>
                <Select
                  labelId="evidenceServedToClaimant"
                  id="evidenceServedToClaimant"
                  name="evidenceServedToClaimant"
                  value={values?.evidenceServedToClaimant}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
                {touched.evidenceServedToClaimant &&
                  errors.evidenceServedToClaimant && (
                    <Typography color="error">
                      {errors.evidenceServedToClaimant}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="evidenceServedToClaimantBy">
                  Evidence Served to Claimant by
                </InputLabel>
                <Select
                  labelId="evidenceServedToClaimantBy"
                  id="evidenceServedToClaimantBy"
                  name="evidenceServedToClaimantBy"
                  value={values?.evidenceServedToClaimantBy}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find(
                      (item) =>
                        item?.categoryName ===
                        "Evidence Served to Respondent by"
                    )
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.categoryName}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.evidenceServedToClaimantBy &&
                  errors.evidenceServedToClaimantBy && (
                    <Typography color="error">
                      {errors.evidenceServedToClaimantBy}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Evidence Served to Claimant On
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="evidenceServedToClaimantOn"
                  selected={values?.evidenceServedToClaimantOn}
                  onChange={(date) =>
                    setFieldValue("evidenceServedToClaimantOn", date)
                  }
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.evidenceServedToClaimantOn &&
                  errors.evidenceServedToClaimantOn && (
                    <Typography color="error">
                      {errors.evidenceServedToClaimantOn}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="claimantsAcknowledgementReceived">
                  Claimant’s Acknowledgement Received
                </InputLabel>
                <Select
                  labelId="claimantsAcknowledgementReceived"
                  id="claimantsAcknowledgementReceived"
                  name="claimantsAcknowledgementReceived"
                  value={values?.claimantsAcknowledgementReceived}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
                {touched.claimantsAcknowledgementReceived &&
                  errors.claimantsAcknowledgementReceived && (
                    <Typography color="error">
                      {errors.claimantsAcknowledgementReceived}
                    </Typography>
                  )}
              </Box>

              {values?.claimantsAcknowledgementReceived && (
                <Box sx={{ display: "grid", mb: 2 }}>
                  <Typography variant="body1" component="label">
                    Acknowledgement Received On
                  </Typography>
                  <DatePicker
                    style={{ width: "100%" }}
                    id="acknowledgementReceivedOn"
                    selected={values?.acknowledgementReceivedOn}
                    onChange={(date) =>
                      setFieldValue("acknowledgementReceivedOn", date)
                    }
                    // minDate={minDate}
                    onBlur={handleBlur}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="DD-MM-YYYY"
                    className="form-control w-100"
                  />
                  {touched.acknowledgementReceivedOn &&
                    errors.acknowledgementReceivedOn && (
                      <Typography color="error">
                        {errors.acknowledgementReceivedOn}
                      </Typography>
                    )}
                </Box>
              )}
              <Box sx={{ textAlign: "right" }}>
                <Button
                  onClick={() => {
                    setOpenModel(false);
                    setEditIndex(null);
                  }}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  {editIndex !== null ? "Update" : "Submit"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};
