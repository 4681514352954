import { Cookies } from "react-cookie";

const defaultExpires = 30 * 60;
const cookies = new Cookies();
export const setCookie = (key, value, expires = defaultExpires, path = "/") => {
  try {
    let d = new Date();
    d.setTime(d.getTime() + expires * 1000);

    cookies.set(key, value, { path, expires: d });
  } catch (error) {
    console.log(error);
  }
};
export const getCookie = (key) => {
  try {
    return cookies.get(key);
  } catch (error) {}
};

export const deleteCookie = (key) => {
  document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/go;";
};
