import axios from "axios";
import { api } from "./api_route";

export const exportPDFDocument = async (values) => {
  try {
    const response = await axios.post(`${api}/export-to-pdf`, values, {
      responseType: "blob",
    });

    const blob = response.data;
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(blob);

    link.href = url;
    link.setAttribute("download", "caseDetails.pdf");

    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to PDF:", error);
    return [];
  }
};

export const exportCSVDocument = async (values) => {
  try {
    const response = await axios.post(`${api}/export-to-csv`, values, {
      responseType: "blob",
    });

    const blob = response.data;
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(blob);

    link.href = url;
    link.setAttribute("download", "caseDetails.csv");

    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to PDF:", error);
    return [];
  }
};

export const exportExcel = async (values) => {
  try {
    const response = await axios.post(`${api}/export-to-excel`, values, {
      responseType: "blob",
    });

    const blob = response.data;
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(blob);

    link.href = url;
    link.setAttribute("download", "Case_Details.xlsx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to Excel:", error);
  }
};

export const exportWord = async (values) => {
  try {
    const response = await axios.post(`${api}/export-to-word`, values, {
      responseType: "blob",
    });

    const blob = response.data;
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(blob);

    link.href = url;
    link.setAttribute("download", "AllotmentCaseData.docx");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting to Word:", error);
  }
};
