import { createSlice } from "@reduxjs/toolkit";
import { animateCss } from "../../../pages/TosterAlert";
import {
  checklistDocumentUplodOptionsAction,
  createChecklistDocumentAction,
  createSplitChecklistDocumentAction,
  deleteCaseChecklistDocumentAction,
  findCaseChecklistDocumentAction,
} from "../../actions/checklistDocuments/checklistDocumentsAction";

const checklistDocuments = {
  data: [],
  settings: [],
  caseChecklistDocument: [],
  loading: false,
  createChecklistDocuments: {
    loading: false,
    error: "",
  },
};

const checklistDocumentSlice = createSlice({
  name: "checklistDocuments",
  initialState: checklistDocuments,
  reducers: {
    resetChecklistDocuments: (state, { payload }) => {
      state = { ...state, ...checklistDocumentSlice.createChecklistDocuments };
      return state;
    },
  },
  extraReducers: (builder) =>
    builder

      .addCase(createChecklistDocumentAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createChecklistDocumentAction.fulfilled, (state, action) => {
        if (action.payload?.data) {
          animateCss("success", "Checklist Create Successfully");
        } else if (!action.payload?.data) {
          animateCss("error", "Checklist Creation Failed");
        }
        state.loading = false;
        return state;
      })
      .addCase(createChecklistDocumentAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(findCaseChecklistDocumentAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(findCaseChecklistDocumentAction.fulfilled, (state, action) => {
        if (action.meta.arg.value.trim() !== "") {
          state.caseChecklistDocument = action.payload?.data;
        } else {
          state.caseChecklistDocument = [];
        }

        state.loading = false;
        return state;
      })
      .addCase(findCaseChecklistDocumentAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(checklistDocumentUplodOptionsAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        checklistDocumentUplodOptionsAction.fulfilled,
        (state, action) => {
          state.settings = action.payload?.data;
          state.loading = false;
          return state;
        }
      )
      .addCase(
        checklistDocumentUplodOptionsAction.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      .addCase(createSplitChecklistDocumentAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        createSplitChecklistDocumentAction.fulfilled,
        (state, action) => {
          return state;
        }
      )
      .addCase(createSplitChecklistDocumentAction.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteCaseChecklistDocumentAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteCaseChecklistDocumentAction.fulfilled, (state, action) => {
        return state;
      })
      .addCase(deleteCaseChecklistDocumentAction.rejected, (state, action) => {
        state.loading = false;
      }),
});

export const { resetChecklistDocuments } = checklistDocumentSlice.actions;
export default checklistDocumentSlice.reducer;
