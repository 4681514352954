import PropTypes from "prop-types";
import { forwardRef, useEffect, useState } from "react";
import { Link, useLocation, matchPath } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// project import
import { handlerActiveItem, useGetMenuMaster } from "../../../../../api/menu";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import { Collapse, List } from "@mui/material";

export default function CollapseNavItem({
  item,
  level,
  routesChecking,
  userRole,
  userRoleRouters,
}) {
  const [childMenu, setChileMenu] = useState(false);
  const theme = useTheme();

  const { menuMaster } = useGetMenuMaster();
  const drawerOpen = menuMaster.isDashboardDrawerOpened;
  const openItem = menuMaster.openedItem;

  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }
  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link
        underline="none"
        ref={ref}
        {...props}
        to={item.url}
        target={itemTarget}
      />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} />
  ) : (
    false
  );

  const { pathname } = useLocation();
  const isSelected =
    !!matchPath({ path: item.url, end: false }, pathname) ||
    openItem === item.id;

  // active menu item on page load
  useEffect(() => {
    if (pathname === item.url) handlerActiveItem(item.id);
    // eslint-disable-next-line
  }, [pathname]);

  const textColor = "text.primary";
  const iconSelectedColor = "primary.main";
  const [open, setOpen] = useState(false);
  const [openGroup, setOpenGroup] = useState();

  const handleClick = (e) => {
    setOpenGroup(e);
    setOpen(!open);
  };

  function printRoutes(routeGroup) {
    const groupData = userRoleRouters?.map((group, i) => {
      if (group?.routeGroup === routeGroup.id) {
        return (
          <Collapse in={open} timeout="auto" unmountOnExit key={i}>
            <List component="div" disablePadding>
              {group.url?.map((userRoute, index) => {
                const subMenu = item.children.find(
                  (item) => item.url === userRoute.url
                );

                if (subMenu) {
                  return (
                    <Link
                      key={index}
                      to={subMenu.url}
                      style={{ textDecoration: "none" }}
                      onClick={() => window.location.pathname == subMenu.url}
                    >
                      <ListItemButton
                        key={index}
                        style={
                          window.location.pathname == subMenu.url
                            ? { color: "#1677ff" }
                            : { color: "black" }
                        }
                        sx={{
                          zIndex: 1201,
                          pl: drawerOpen ? `${level * 28}px` : 1.5,
                          py: !drawerOpen && level === 1 ? 1.25 : 1,
                          ...(drawerOpen && {
                            "&:hover": {
                              bgcolor: "primary.lighter",
                            },
                            "&.Mui-selected": {
                              bgcolor: "primary.lighter",
                              borderRight: `2px solid ${theme.palette.primary.main}`,
                              color: iconSelectedColor,
                              "&:hover": {
                                color: iconSelectedColor,
                                bgcolor: "primary.lighter",
                              },
                            },
                          }),
                          ...(!drawerOpen && {
                            "&:hover": {
                              bgcolor: "transparent",
                            },
                            "&.Mui-selected": {
                              "&:hover": {
                                bgcolor: "transparent",
                              },
                              bgcolor: "transparent",
                            },
                          }),
                        }}
                      >
                        {/* <ListItemIcon
                          sx={{
                            minWidth: 28,
                            color: isSelected ? iconSelectedColor : textColor,
                            ...(!drawerOpen && {
                              borderRadius: 1.5,
                              width: 36,
                              height: 36,
                              alignItems: "center",
                              justifyContent: "center",
                              "&:hover": {
                                bgcolor: "secondary.lighter",
                              },
                            }),
                            ...(!drawerOpen &&
                              isSelected && {
                                bgcolor: "primary.lighter",
                                "&:hover": {
                                  bgcolor: "primary.lighter",
                                },
                              }),
                          }}
                        > */}
                        {/* <Icon src={subMenu.icon} alt="icon" /> */}
                        {/* </ListItemIcon> */}

                        <ListItemText primary={subMenu.title} />
                      </ListItemButton>
                    </Link>
                  );
                } else {
                  return null;
                }
              })}
            </List>
          </Collapse>
        );
      } else {
        return null;
      }
    });

    return groupData;
  }

  const isActiveNav = item.children.find(
    (nav) => nav.url === window.location.pathname || false
  );

  return (
    <>
      <ListItemButton
        onClick={() => {
          handleClick(item);
        }}
        disabled={item.disabled}
        selected={
          isActiveNav !== null && typeof isActiveNav === "object"
            ? true
            : isActiveNav
        }
        sx={{
          zIndex: 1201,
          pl: drawerOpen ? `${level * 28}px` : 1.5,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          ...(drawerOpen && {
            "&:hover": {
              bgcolor: "primary.lighter",
            },
            "&.Mui-selected": {
              bgcolor: "primary.lighter",
              borderRight: `2px solid ${theme.palette.primary.main}`,
              //   bgcolor: "white",
              color: iconSelectedColor,
              "&:hover": {
                color: iconSelectedColor,
                bgcolor: "primary.lighter",
              },
            },
          }),
          ...(!drawerOpen && {
            "&:hover": {
              bgcolor: "transparent",
            },
            "&.Mui-selected": {
              "&:hover": {
                bgcolor: "transparent",
              },
              bgcolor: "transparent",
            },
          }),
        }}
      >
        <ListItemIcon> {itemIcon}</ListItemIcon>
        <ListItemText primary={item.title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {printRoutes(item)}
    </>
  );
}

CollapseNavItem.propTypes = { item: PropTypes.object, level: PropTypes.number };
