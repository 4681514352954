import React from "react";
import {
  Drawer,
  Grid,
  Stack,
  IconButton,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { api } from "../../../api/api_route";
import { Delete } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { deleteCaseChecklistDocumentAction } from "../../../redux/actions/checklistDocuments/checklistDocumentsAction";
import {
  allAgreementDocumentDeleteAction,
  allAgreementDocumentListAction,
  singleCaseDetailsAction,
} from "../../../redux/actions/Case/caseAction";
import { useParams } from "react-router-dom";
import { caseChecklistDocumentAction } from "../../../redux/actions/Checklist/checklistAction";
import { animateCss } from "../../TosterAlert";
import { CgPlayListRemove } from "react-icons/cg";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 320,
    padding: theme.spacing(2),
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  closeButton: {
    marginLeft: "auto",
  },
  formControl: {
    minWidth: "100%",
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

const ArbitrationDocumentList = ({ open, setOpen, checklistDocuments }) => {
  const classes = useStyles();

  const dateFormate = (date) => {
    const createdAtDate = new Date(date);
    const formattedDate = createdAtDate.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    return formattedDate;
  };

  const handleDownload = (item) => {
    const downloadUrl = `${api}/agreementDocument/${item.uploadDocName}`;
    const fileName = item.uploadDocName;

    fetch(downloadUrl)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const dispatch = useDispatch();
  const { id } = useParams();
  const params = { caseID: id };

  const arbitrationDocument = useSelector(
    (state) => state?.case?.agreementDocumentList
  );
  const loading = useSelector((state) => state?.case?.loading);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      classes={{ paper: classes.drawerPaper }}
    >
      <div>
        <div className="MuiStack-root css-jjtu05" sx={{ zIndex: 0 }}>
          {/* Header */}
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h4">
                  Arbitration Agreement Document
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                className={classes.closeButton}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          {/* Divider */}
          <hr className="MuiDivider-root MuiDivider-fullWidth css-d7wf4l" />

          {/* Form */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <form>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  {arbitrationDocument?.[0] ? (
                    arbitrationDocument?.map((item) => {
                      return (
                        <>
                          <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleDownload(item);
                                }}
                              >
                                <Avatar
                                  alt="Remy Sharp"
                                  src={require("../../../assets/images/download.webp")}
                                />
                              </a>
                            </ListItemAvatar>
                            <ListItemText
                              primary={<>{item?.actualDocName} </>}
                              secondary={
                                <React.Fragment>
                                  <p>Date: {dateFormate(item?.createdAt)}</p>
                                  {/* <Typography
                                  sx={{ display: "inline" }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                >
                                  {item?.creator?.name}
                                </Typography>
                                {` —   ${item?.comments}`} */}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                          <div className="text-end">
                            <Tooltip title="Delete">
                              <IconButton>
                                <Delete
                                  className="text-danger"
                                  onClick={() => {
                                    try {
                                      if (window.delete) {
                                        dispatch(
                                          allAgreementDocumentDeleteAction({
                                            checkListDocID: item.checkListDocID,
                                          })
                                        );
                                        setTimeout(() => {
                                          dispatch(
                                            allAgreementDocumentListAction({
                                              value: "Arbitration Agreement",
                                              caseID: id,
                                            })
                                          );
                                        }, 1000);
                                      } else {
                                        animateCss(
                                          "info",
                                          "You are not authorized to delete"
                                        );
                                      }
                                    } catch (err) {
                                      console.error(
                                        "Error deleting checklist document:",
                                        err
                                      );
                                    }
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <Divider variant="inset" component="li" />
                        </>
                      );
                    })
                  ) : (
                    <div
                      style={{ height: "70vh" }}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <CgPlayListRemove className="fs-1" /> No Records
                    </div>
                  )}
                </List>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
    </Drawer>
  );
};

export default ArbitrationDocumentList;
