import { createSlice } from "@reduxjs/toolkit";
import { formDataAction } from "../actions/UserAction/usersAction";

const formSlice = createSlice({
  name: "formData",
  initialState: {},
  reducers: {
    setformData: (state, { payload }) => {},
    userFormData: (state, { payload }) => {},
  },
  extraReducers: (builder) =>
    builder
      .addCase(formDataAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(formDataAction.fulfilled, (state, { payload }) => {
        state.loading = false;

        state.roles = payload?.roles;
        state.designations = payload?.designations;
        return state;
      })
      .addCase(formDataAction.rejected, (state, action) => {
        state.loading = false;
      }),
});

export const { setformData } = formSlice.actions;
export default formSlice.reducer;
