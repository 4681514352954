import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Grid,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  singleCaseDetailsAction,
  updateArbitrationDurationAndTimelineManagementAction,
  updateArbitrationNoticesKeyDatesAction,
} from "../../../redux/actions/Case/caseAction";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { animateCss } from "../../TosterAlert";
import dayjs from "dayjs";

const ArbitrationDurationAndTimelineManagement = () => {
  const rowData = useSelector((state) => state?.case?.editCaseDetails);
  const [
    editArbitrationDurationAndTimelineManagement,
    setEditArbitrationDurationAndTimelineManagement,
  ] = useState(false);
  const editCaseTracker = false;

  const useStyles = makeStyles((theme) => ({
    textarea: {
      width: "100%",
      padding: theme.spacing(2),
      marginTop: "5%",
      fontSize: "1rem",
      borderRadius: "4px",
      border: "1px solid #ccc",
      resize: "vertical",
      minHeight: "100px",
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  function trackerFormatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  const { id } = useParams();
  const params = { caseID: id };
  const user = useSelector((state) => state.login.data);
  const settings = useSelector((state) => state?.ChecklistDocument?.settings);
  return (
    <Card variant="outlined" className=" mt-3" sx={{ maxWidth: "100%" }}>
      <Box sx={{ p: 2, height: "100vh" }}>
        {editArbitrationDurationAndTimelineManagement ? (
          <Formik
            initialValues={{
              agreedArbitrationDuration: !rowData?.caseID
                ? null
                : rowData?.AgreedArbitrationDuration,
              arbitrationDurationNotes: !rowData?.caseID
                ? null
                : rowData?.ArbitrationDurationNotes,
              startDateOfArbitration: !rowData?.caseID
                ? null
                : rowData?.StartDateOfArbitration,
              endDateOfArbitration: !rowData?.caseID
                ? null
                : rowData?.EndDateOfArbitration,
              daysRemaining: !rowData?.caseID ? null : rowData?.daysRemaining,
              extensionOfArbitrationPeriod: !rowData?.caseID
                ? null
                : rowData?.ExtensionOfArbitrationPeriod,
              newArbitrationEndDate: !rowData?.caseID
                ? null
                : rowData?.NewArbitrationEndDate,
              durationOfExtension: !rowData?.caseID
                ? null
                : rowData?.DurationOfExtension,
              exceededAgreedDuration: !rowData?.caseID
                ? null
                : rowData?.ExceededAgreedDuration,
              numberOfDaysExceeded: !rowData?.caseID
                ? null
                : rowData?.NumberOfDaysExceeded,
              reasonForArbitrationExtension: !rowData?.caseID
                ? null
                : rowData?.ReasonForArbitrationExtension,
              complianceWithAgreedDuration: !rowData?.caseID
                ? null
                : rowData?.ComplianceWithAgreedDuration,
              params,
              user,
            }}
            // validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              try {
                dispatch(
                  updateArbitrationDurationAndTimelineManagementAction(values)
                );
                setEditArbitrationDurationAndTimelineManagement(false);
                setTimeout(() => {
                  dispatch(singleCaseDetailsAction(params));
                }, 1000);
              } catch (error) {
                // Handle submission error if needed
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
              resetForm,
            }) => {
              // if (
              //   values.startDateOfArbitration &&
              //   values.endDateOfArbitration
              // ) {
              //   const startDate = dayjs(values.startDateOfArbitration);
              //   const endDate = dayjs(values.endDateOfArbitration);

              //   const daysRemaining = endDate.diff(startDate, "day") + 2;

              //   setFieldValue("daysRemaining", daysRemaining);
              // }

              return (
                <form onSubmit={handleSubmit}>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          backgroundColor: "white",
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="m-0 "
                        >
                          Arbitration Duration And Timeline Management
                        </Typography>

                        <div>
                          <Button
                            className="mx-2"
                            type="button"
                            variant="contained"
                            color="secondary"
                            onClick={(event) => {
                              event.stopPropagation();
                              setEditArbitrationDurationAndTimelineManagement(
                                false
                              );
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            // color="primary"
                            variant="contained"
                            onClick={async () => {
                              //   try {
                              //     dispatch(caseTrackingUpdateAction(values));
                              //     setEditCaseTracker(false);
                              //     setTimeout(() => {
                              //       // dispatch(resetSingleCaseDetailsReducer());
                              //       // dispatch(allCaseDetailsAction());
                              //       // newData(null);
                              //     }, 1000);
                              //   } catch (error) {}
                            }}
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        <Box
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            backgroundColor: "white",
                            flexWrap: "wrap",
                            alignItems: "stretch",
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="DaysUnderSection11Complied"
                                sx={{ textWrap: "balance" }}
                              >
                                Agreed Arbitration Duration
                              </InputLabel>

                              <Select
                                labelId="agreedArbitrationDuration"
                                id="agreedArbitrationDuration"
                                name="agreedArbitrationDuration"
                                value={values?.agreedArbitrationDuration}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                {settings
                                  ?.find(
                                    (item) =>
                                      item.categoryName ==
                                      "Agreed Arbitration Duration"
                                  )
                                  ?.Children?.map((data, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={data?.generalSettingsID}
                                      >
                                        {data?.categoryName}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ textWrap: "balance" }}
                              >
                                Arbitration Duration Notes
                              </Typography>

                              <OutlinedInput
                                id="arbitrationDurationNotes"
                                name="arbitrationDurationNotes"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                value={values?.arbitrationDurationNotes}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Start Date of Arbitration
                              </Typography>

                              <DatePicker
                                id="startDateOfArbitration"
                                selected={values?.startDateOfArbitration}
                                onChange={(date) => {
                                  setFieldValue("startDateOfArbitration", date);
                                  const startDate = dayjs(date);
                                  const endDate = dayjs(
                                    values.endDateOfArbitration
                                  );

                                  const daysRemaining = endDate.diff(
                                    startDate,
                                    "day"
                                  );

                                  setFieldValue("daysRemaining", daysRemaining);
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                End Date of Arbitration
                              </Typography>

                              <DatePicker
                                id="endDateOfArbitration"
                                selected={values?.endDateOfArbitration}
                                onChange={(date) => {
                                  setFieldValue("endDateOfArbitration", date);
                                  const startDate = dayjs(
                                    values.startDateOfArbitration
                                  );
                                  const endDate = dayjs(date);

                                  const daysRemaining = endDate.diff(
                                    startDate,
                                    "day"
                                  );

                                  setFieldValue("daysRemaining", daysRemaining);
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ textWrap: "balance" }}
                              >
                                Days Remaining
                              </Typography>

                              <OutlinedInput
                                id="daysRemaining"
                                name="daysRemaining"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                value={values?.daysRemaining}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="DaysUnderSection11Complied"
                                sx={{ textWrap: "balance" }}
                              >
                                Extension of Arbitration Period
                              </InputLabel>

                              <Select
                                labelId="extensionOfArbitrationPeriod"
                                id="extensionOfArbitrationPeriod"
                                name="extensionOfArbitrationPeriod"
                                value={values?.extensionOfArbitrationPeriod}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem value={"Yes"} defaultValue>
                                  Yes
                                </MenuItem>
                                <MenuItem value={"No"} defaultValue>
                                  No
                                </MenuItem>
                              </Select>
                            </Box>

                            {values?.extensionOfArbitrationPeriod == "Yes" && (
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "25%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  sx={{ marginRight: 2, textWrap: "balance" }}
                                >
                                  New Arbitration End Date
                                </Typography>

                                <DatePicker
                                  id="newArbitrationEndDate"
                                  selected={values?.newArbitrationEndDate}
                                  onChange={(date) => {
                                    setFieldValue(
                                      "newArbitrationEndDate",
                                      date
                                    );
                                  }}
                                  sx={{
                                    width: "100%",
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                    },
                                  }}
                                  onBlur={handleBlur}
                                  dateFormat="dd-MM-yyyy"
                                  placeholderText="DD-MM-YYYY"
                                  className="form-control"
                                />
                              </Box>
                            )}

                            {values?.extensionOfArbitrationPeriod == "Yes" && (
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "25%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  sx={{ textWrap: "balance" }}
                                >
                                  Duration of Extension
                                </Typography>

                                <OutlinedInput
                                  id="durationOfExtension"
                                  name="durationOfExtension"
                                  sx={{
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                    },
                                  }}
                                  value={values?.durationOfExtension}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </Box>
                            )}

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="exceededAgreedDuration"
                                sx={{ textWrap: "balance" }}
                              >
                                Exceeded Agreed Duration
                              </InputLabel>

                              <Select
                                labelId="exceededAgreedDuration"
                                id="exceededAgreedDuration"
                                name="exceededAgreedDuration"
                                value={values?.exceededAgreedDuration}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem value={"Yes"} defaultValue>
                                  Yes
                                </MenuItem>
                                <MenuItem value={"No"} defaultValue>
                                  No
                                </MenuItem>
                              </Select>
                            </Box>

                            {values?.exceededAgreedDuration == "Yes" && (
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "25%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  sx={{ textWrap: "balance" }}
                                >
                                  Number of Days Exceeded
                                </Typography>

                                <OutlinedInput
                                  id="numberOfDaysExceeded"
                                  name="numberOfDaysExceeded"
                                  sx={{
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                    },
                                  }}
                                  value={values?.numberOfDaysExceeded}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </Box>
                            )}

                            {values?.exceededAgreedDuration == "Yes" && (
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "25%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  sx={{ textWrap: "balance" }}
                                >
                                  Reason for Arbitration Extension
                                </Typography>

                                <OutlinedInput
                                  id="reasonForArbitrationExtension"
                                  name="reasonForArbitrationExtension"
                                  sx={{
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                    },
                                  }}
                                  value={values?.reasonForArbitrationExtension}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              </Box>
                            )}

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="complianceWithAgreedDuration"
                                sx={{ textWrap: "balance" }}
                              >
                                Compliance With Agreed Duration
                              </InputLabel>

                              <Select
                                labelId="complianceWithAgreedDuration"
                                id="complianceWithAgreedDuration"
                                name="complianceWithAgreedDuration"
                                value={values?.complianceWithAgreedDuration}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem value={"Yes"} defaultValue>
                                  Yes
                                </MenuItem>
                                <MenuItem value={"No"} defaultValue>
                                  No
                                </MenuItem>
                              </Select>
                            </Box>
                          </LocalizationProvider>
                        </Box>
                      </>
                    </AccordionDetails>
                  </Accordion>
                </form>
              );
            }}
          </Formik>
        ) : (
          //   <CaseTracking
          //     classes={classes}
          //     rowData={caseDetailsData}
          //     settings={settings}
          //     handleFormSubmit={handleFormSubmit}
          //     dispatch={dispatch}
          //     setEditCaseTracker={setEditCaseTracker}
          //     newData={newData}
          //     initialValues={initialValues}
          //     validationSchema={validationSchema}
          //   />
          <>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="m-0 "
                  >
                    Arbitration Duration And Timeline Management
                  </Typography>

                  {window?.edit && (
                    <div>
                      <Button
                        type="button"
                        color="primary"
                        onClick={(event) => {
                          event.stopPropagation();
                          if (window.edit) {
                            event.stopPropagation();
                            // resetForm();
                            setEditArbitrationDurationAndTimelineManagement(
                              true
                            );
                          } else {
                            animateCss(
                              "info",
                              "You are not authorized to update"
                            );
                          }
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Agreed Arbitration Duration"
                        secondary={
                          rowData?.agreedArbitrationDuration?.categoryName
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Arbitration Duration Notes"
                        secondary={rowData?.ArbitrationDurationNotes}
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Start Date of Arbitration"
                        secondary={
                          rowData?.StartDateOfArbitration
                            ? trackerFormatDate(
                                new Date(rowData?.StartDateOfArbitration)
                              )
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="End Date of Arbitration"
                        secondary={
                          rowData?.EndDateOfArbitration
                            ? trackerFormatDate(
                                new Date(rowData?.EndDateOfArbitration)
                              )
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Days Remaining"
                        secondary={rowData?.daysRemaining}
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Extension of Arbitration Period"
                        secondary={rowData?.ExtensionOfArbitrationPeriod}
                      />
                    </List>
                  </Grid>
                  {rowData?.ExtensionOfArbitrationPeriod == "Yes" && (
                    <Grid item xs={12} sm={6} md={4}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="New Arbitration End Date"
                          secondary={
                            rowData?.NewArbitrationEndDate
                              ? trackerFormatDate(
                                  new Date(rowData?.NewArbitrationEndDate)
                                )
                              : "DD-MM-YYYY"
                          }
                        />
                      </List>
                    </Grid>
                  )}

                  {rowData?.ExtensionOfArbitrationPeriod == "Yes" && (
                    <Grid item xs={12} sm={6} md={4}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Duration of Extension"
                          secondary={rowData?.DurationOfExtension}
                        />
                      </List>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary=" Exceeded Agreed Duration"
                        secondary={rowData?.ExceededAgreedDuration}
                      />
                    </List>
                  </Grid>
                  {rowData?.ExceededAgreedDuration == "Yes" && (
                    <Grid item xs={12} sm={6} md={4}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Number of Days Exceeded"
                          secondary={rowData?.NumberOfDaysExceeded}
                        />
                      </List>
                    </Grid>
                  )}

                  {rowData?.ExceededAgreedDuration == "Yes" && (
                    <Grid item xs={12} sm={6} md={4}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Reason for Arbitration Extension"
                          secondary={rowData?.ReasonForArbitrationExtension}
                        />
                      </List>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Advocate for Defendant / Respondent"
                        secondary={rowData?.ComplianceWithAgreedDuration}
                      />
                    </List>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Box>
    </Card>
  );
};

export default ArbitrationDurationAndTimelineManagement;
