import axios from "axios";
import { api } from "./api_route";
import { getCookie } from "../helpers/cookie";
import { resetDataAndLogout } from "../helpers/auth";

export const getUsers = async () => {
  try {
    const result = await axios.get(`${api}/users/`);

    return result.data;
  } catch (error) {
    return [];
  }
};

export const cretaeUser = async (values) => {
  try {
    const result = await axios.post(`${api}/users/create`, { values });

    return result;
  } catch (error) {
    return [];
  }
};

export const updateUser = async (values) => {
  try {
    const result = await axios.post(`${api}/users/update`, { values });

    return result.data;
  } catch (error) {
    return [];
  }
};

export const changePassword = async (values) => {
  try {
    const result = await axios.post(`${api}/users/changePassword`, { values });

    return result.data;
  } catch (error) {
    return [];
  }
};

export const resetPassword = async (values) => {
  try {
    const result = await axios.post(`${api}/users/resetPassword`, { values });

    return result.data;
  } catch (error) {
    return [];
  }
};

export const isActiveUser = async (values) => {
  try {
    const result = await axios.post(`${api}/users/updateIsActive`, { values });
    return result.data;
  } catch (error) {
    return [];
  }
};

export const deleteUsers = async (values) => {
  try {
    const result = await axios.post(`${api}/users/deleteUsers`, { values });

    if (result) {
    } else if (!result) {
    }
    return result.data;
  } catch (error) {
    return [];
  }
};

export const login = async (values) => {
  try {
    const result = await axios.post(`${api}/users/login`, {
      values,
    });

    return result.data;
  } catch (error) {
    return [];
  }
};

export const forgotPassword = async (values) => {
  try {
    const result = await axios.post(`${api}/login/forgotpassword`, {
      values,
    });

    return result;
  } catch (error) {
    return [];
  }
};

export const forgotPasswordOTPValidation = async (values) => {
  try {
    const result = await axios.post(
      `${api}/login/forgotpasswordotpvalidation`,
      {
        values,
      }
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const createUserForm = async () => {
  try {
    const role = await axios.get(`${api}/userRole`);
    const designation = await axios.get(`${api}/userDesignation`);
    const roles = role?.data?.data;
    const designations = designation?.data?.data;
    return { roles, designations };
  } catch (error) {
    return [];
  }
};

export const getLoginUser = async () => {
  try {
    const result = await axios.get(`${api}/users/getLoginUser`);

    return result.data;
  } catch (error) {
    resetDataAndLogout();

    return [];
  }
};

export const allVishlawGlobalOrganisationUser = async (values) => {
  try {
    const result = await axios.post(
      `${api}/caseDetails/allVishlawGlobalOrganisationUser`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};
