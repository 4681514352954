import * as React from "react";
import { useState, useEffect } from "react";
import {
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SvgIcon,
  Tab,
  Tabs,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { GrEdit } from "react-icons/gr";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  allChecklistAction,
  caseChecklistDocumentAction,
} from "../../../redux/actions/Checklist/checklistAction";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { animateCss } from "../../TosterAlert";
import { deepOrange } from "@mui/material/colors";
import { FcDocument } from "react-icons/fc";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import ReactDatePicker from "react-datepicker";
import { FormHelperText, FormControl } from "@mui/material";
import {
  checklistDocumentUplodOptionsAction,
  createChecklistDocumentAction,
} from "../../../redux/actions/checklistDocuments/checklistDocumentsAction";
import { IoMdAttach } from "react-icons/io";
import ChecklistDocumentList from "./ChecklistDocumentList";
import CaseDetailEdit from "./CaseDetailsEdit";
import {
  allCaseDetailsAction,
  allCaseTypeAction,
  caseTrackingUpdateAction,
  singleCaseDetailsAction,
} from "../../../redux/actions/Case/caseAction";
import EditDocumentTracker from "./EditDocumentTracker";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import {
  // DatePicker,
  DesktopDatePicker,
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker CSS
import { resetSingleCaseDetailsReducer } from "../../../redux/slices/Case/caseSlice";

import { useNavigate, useParams } from "react-router-dom";
import Loadable from "../../../components/Loadable";
import CasePrimaryInformation from "./CasePrimaryInformation";
import ArbitrationProceedings from "./ArbitrationProceedings";
import ArbitrationNoticesKeyDates from "./ArbitrationNoticesKeyDates";
import ArbitrationDurationAndTimelineManagement from "./ArbitrationDurationAndTimelineManagement";
import { Triangle } from "react-loader-spinner";
import PreliminaryChecksCommencement from "./preliminaryChecksCommencement";
import ArbitrationInterimOrdersReliefsAwards from "./arbitrationInterimOrdersReliefsAwards";
import { allVishlawGlobalOrganisationUserAction } from "../../../redux/actions/UserAction/usersAction";
import TrialProceedings from "./trialProceedings";
// import NoRecordsComponent from "../../NoRecordComponents";

const NoRecordsComponent = Loadable(
  React.lazy(() => import("../../NoRecordComponents"))
);

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 320px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const columnHelper = createMRTColumnHelper();

const DatePickerField = ({ field, form, ...other }) => {
  const { name, value } = field;

  const handleChange = (val) => {
    form.setFieldValue(name, val);
  };

  return (
    <React.Fragment>
      <ReactDatePicker
        sx={{ m: 0, p: 2 }}
        className="p-2 mt-1 form-control"
        {...other}
        selected={value}
        onChange={handleChange}
        dateFormat="dd-MM-yyyy"
        placeholderText="Select Date"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        error={form.touched[name] && form.errors[name]}
      />
      {form.touched[name] && form.errors[name] && (
        <FormHelperText error>{form.errors[name]}</FormHelperText>
      )}
    </React.Fragment>
  );
};

const MAX_COUNT = 50;

const CaseChecklist = ({ newData, setNewData }) => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [checklistDocuments, setChecklistDocuments] = useState();
  const [trackerOpen, setTrackerOpen] = useState(false);
  const [editCaseTracker, setEditCaseTracker] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [caseDetailsTab, setCaseDetailsTab] = useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { id } = useParams();
  const params = { caseID: id };

  const user = useSelector((state) => state.login.data);

  const useStyles = makeStyles((theme) => ({
    textarea: {
      width: "100%",
      padding: theme.spacing(2),
      marginTop: "5%",
      fontSize: "1rem",
      borderRadius: "4px",
      border: "1px solid #ccc",
      resize: "vertical",
      minHeight: "100px",
    },
  }));

  const classes = useStyles();

  const dispatch = useDispatch();
  const apiValues = useSelector((state) => state?.checklist?.list);

  const settings = useSelector((state) => state?.ChecklistDocument?.settings);

  const value = settings?.find((item) => item.categoryName == "Case Type");

  const editCase = useSelector((state) => state?.case?.editCaseDetails);
  const caseDetailsData = useSelector((state) => state?.case?.editCaseDetails);
  const excelData = editCase?.alterCaseDetails;

  const caseIDDetails = async (caseID) => {
    try {
      await dispatch(singleCaseDetailsAction(caseID));
      await dispatch(caseChecklistDocumentAction(caseID));
      await dispatch(checklistDocumentUplodOptionsAction());
    } catch (err) {}
  };

  useEffect(() => {
    caseIDDetails(params);
  }, []);

  useEffect(() => {
    dispatch(singleCaseDetailsAction(params));
  }, [open]);

  useEffect(() => {
    caseIDDetails(params);
    dispatch(singleCaseDetailsAction(params));
  }, [editCaseTracker, dispatch]);

  useEffect(() => {
    if (excelData) {
      const transformedData = Object.entries(excelData)
        .filter(([key, value]) => !value?.fieldNotNeed)
        .map(([key, value]) => {
          return {
            id: key,
            Header: value?.alterHeader,
            value: value?.alterHeader?.toLowerCase()?.includes("date")
              ? new Date(value?.value)
              : value.value,
            group: value.group?.group
              ? value.group?.group
              : "Other Case Details",
            fieldNotNeed: value?.fieldNotNeed,
            reportsAndTemplates: value?.reportsAndTemplates,
          };
        });

      setData(transformedData);
    }
  }, []);

  useEffect(() => {
    if (excelData) {
      const transformedData = Object.entries(excelData)
        .filter(([key, value]) => !value?.fieldNotNeed)
        .map(([key, value]) => {
          return {
            id: key,
            Header: value?.alterHeader,
            value: value?.value,
            group: value.group?.group
              ? value.group?.group
              : "Other Case Details",
            fieldNotNeed: value?.fieldNotNeed,
            reportsAndTemplates: value?.reportsAndTemplates,
          };
        });

      setData(transformedData);
    }
  }, [editCase]);

  const columns = [
    columnHelper.accessor("Header", {
      header: "Header",
    }),
    columnHelper.accessor("value", {
      header: "value",
    }),
  ];

  function formatDate(createdAt) {
    if (!createdAt) {
      return "";
    }

    let date = new Date(createdAt);

    if (isNaN(date.getTime())) {
      return "";
    }

    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let day = date.getDate();
    let monthIndex = date.getMonth();
    let year = date.getFullYear();

    let formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

    return formattedDate;
  }

  // function formatDateAndTime(createdAt) {
  //   if (!createdAt) {
  //     return "Invalid date";
  //   }

  //   let date = new Date(createdAt);

  //   if (isNaN(date.getTime())) {
  //     return "Invalid date";
  //   }

  //   let monthNames = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];

  //   let day = date.getDate().toString().padStart(2, "0");
  //   let monthIndex = date.getMonth();
  //   let year = date.getFullYear();

  //   let hours = date.getHours();
  //   let minutes = date.getMinutes().toString().padStart(2, "0");
  //   let ampm = hours >= 12 ? "PM" : "AM";

  //   // Convert to 12-hour format
  //   hours = hours % 12;
  //   hours = hours ? hours : 12; // If hour is 0, set to 12

  //   let formattedDate = `${day} ${monthNames[monthIndex]} ${year} ${hours}:${minutes} ${ampm}`;

  //   return formattedDate;
  // }

  function formatDateAndTime(createdAt) {
    if (!createdAt) {
      return "Invalid date";
    }

    let date = new Date(createdAt);

    if (isNaN(date.getTime())) {
      return "Invalid date";
    }

    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let day = date.getDate().toString().padStart(2, "0");
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();

    let hours = date.getHours();
    let minutes = date.getMinutes().toString().padStart(2, "0");
    let ampm = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // If hour is 0, set to 12

    let formattedDate = `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;

    return formattedDate;
  }

  const trackerDate = (dateString) => {
    if (!dateString) return null;
    const parsedDate = dayjs(dateString);
    return parsedDate.isValid() ? parsedDate : null;
  };

  const table = useMaterialReactTable({
    columns,
    data: data,
    enablePagination: false,
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    enableFullScreenToggle: false,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    enableRowVirtualization: true,
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableStickyHeader: true,
    enableGlobalFilter: true,
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },
    keyAccessor: (row, rowIndex) =>
      row.orderStatus + "_" + row.id + "_" + rowIndex,
  });

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleDelete = (chipToDelete) => () => {
    setUploadedFiles((chips) =>
      chips.filter((chip) => chip.name !== chipToDelete.name)
    );
  };

  useEffect(() => {
    setRowData(caseDetailsData);
  }, [caseDetailsData]);

  useEffect(() => {
    setRowData(caseDetailsData);
    dispatch(allCaseTypeAction());
    dispatch(checklistDocumentUplodOptionsAction());
    dispatch(allVishlawGlobalOrganisationUserAction());

    if (!window.edit) {
      navigate(-1);
    }
  }, []);
  const navigate = useNavigate();

  return (
    <div className="d-flex row">
      {rowData?.caseID ? (
        <>
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="p-0 m-0"
          >
            <ChecklistDocumentList
              open={open}
              setOpen={setOpen}
              checklistDocuments={checklistDocuments}
            />

            {openEdit && (
              <CaseDetailEdit
                rawData={data}
                setOpenEdit={setOpenEdit}
                setEditCaseTracker={setEditCaseTracker}
                openEdit={openEdit}
                caseID={rowData?.caseID}
                setNewData={setNewData}
                newData={newData}
              />
            )}
            {trackerOpen && (
              <EditDocumentTracker
                open={trackerOpen}
                setOpen={setTrackerOpen}
                id={rowData}
              />
            )}

            {rowData?.caseID && (
              <div
                className=" bg-white shadow p-3 rounded"
                sx={{
                  width: 400,
                  "@media (min-width: 300px)": {
                    width: 300,
                  },
                  "@media (min-width: 400px)": {
                    width: 350,
                  },
                  "@media (min-width: 600px)": {
                    width: 280,
                  },
                  "@media (min-width: 900px)": {
                    width: 400,
                  },
                  "@media (min-width: 1200px)": {
                    width: 400,
                  },
                  "@media (min-width: 1536px)": {
                    width: 400,
                  },
                }}
              >
                <div>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    // spacing={2}
                  >
                    <Grid item xs={6} sm={6} md={6}>
                      <Typography variant="h5" className="text-secondary">
                        Case Number:{" "}
                        <span className="text-black">
                          {rowData?.caseDetailsID ? ` ${rowData?.caseID} ` : ""}
                        </span>
                      </Typography>
                      <Typography variant="h5" className="text-secondary">
                        Case Name:{" "}
                        <span className="text-black">
                          {rowData?.caseName?.trim() !== ""
                            ? rowData?.caseName
                            : rowData?.Claimant && rowData?.Respondent
                              ? `${rowData.Claimant} Vs ${rowData?.Respondent}`
                              : ""}
                        </span>
                      </Typography>

                      <Typography variant="h5" className="text-secondary">
                        Practice Area:{" "}
                        <span className="text-black">
                          {rowData?.PracticeArea?.categoryName
                            ? ` ${rowData.PracticeArea.categoryName} `
                            : ""}
                        </span>
                      </Typography>
                      <Typography variant="h5" className="text-secondary">
                        Case Type:{" "}
                        <span className="text-black">
                          {rowData?.caseDetailsID
                            ? `${rowData?.caseType?.categoryName}`
                            : ""}
                        </span>
                      </Typography>

                      <Typography variant="h5" className="text-secondary">
                        Priority Level:{" "}
                        <span
                          className={
                            rowData?.priorityLevelID?.categoryName === "Low"
                              ? "text-success"
                              : rowData?.priorityLevelID?.categoryName ===
                                  "Medium"
                                ? "text-warning"
                                : rowData?.priorityLevelID?.categoryName ===
                                    "High"
                                  ? "text-danger"
                                  : ""
                          }
                        >
                          {rowData?.priorityLevelID?.categoryName !== null
                            ? `${rowData?.priorityLevelID?.categoryName}`
                            : ""}
                        </span>
                      </Typography>
                      <Typography variant="h5" className="text-secondary">
                        Organization:{" "}
                        <span className="text-black">
                          {rowData?.caseDetailsID
                            ? `${rowData.organization?.organizationName}`
                            : ""}
                        </span>
                      </Typography>

                      {/* {rowData?.ArbitrationProceedingAction.trim().length > 0 ||
                        (rowData?.ExistenceOfWrittenArbitrationAgreement ==
                          "No" && (
                          <Alert variant="filled" severity="error">
                            <b> Attention:</b>{" "}
                            {rowData?.ExistenceOfWrittenArbitrationAgreement ==
                            "Yes"
                              ? rowData?.ArbitrationProceedingAction
                              : "No Written Arbitration Exists. Do not initiate Arbitration Proceedings"}
                          </Alert>
                        ))} */}
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      container
                      direction="column"
                      alignItems="flex-start"
                    >
                      <Typography variant="h5" className="text-secondary">
                        Case Opened On:
                        <span className="text-black">
                          {formatDate(rowData?.allocationDate)}
                        </span>
                      </Typography>
                      <Typography variant="h5" className="text-secondary">
                        Case Closed On:{" "}
                        <span className="text-black">
                          {formatDate(rowData?.caseCloseDate)}
                        </span>
                      </Typography>
                      <Typography variant="h5" className="text-secondary">
                        Case Status:{" "}
                        <span
                          className={
                            rowData?.caseStatusID?.categoryName === "Open"
                              ? "text-success"
                              : rowData?.caseStatusID?.categoryName ===
                                  "On Hold"
                                ? "text-warning"
                                : rowData?.caseStatusID?.categoryName ===
                                    "Closed"
                                  ? "text-danger"
                                  : ""
                          }
                        >
                          {rowData?.caseStatusID?.categoryName ??
                            rowData?.caseStatus}
                        </span>
                      </Typography>
                      <Typography variant="h5" className="text-secondary">
                        Last Activity On:{" "}
                        <span className="text-black">
                          {formatDateAndTime(rowData?.updatedAt)}{" "}
                        </span>
                      </Typography>
                      <Grid
                        container
                        justifyContent="flex-end "
                        className="mt-3"
                        spacing={1}
                      >
                        <Grid item>
                          <Button
                            className="btn btn-primary"
                            onClick={() => {
                              setTrackerOpen(true);
                            }}
                            variant="text"
                          >
                            History
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="text"
                            className="btn btn-primary"
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            Back
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className="mt-2"
                    justifyContent="space-between"
                  >
                    <Grid item xs={6} sm={3} md={3}>
                      <Typography variant="h6" fontWeight="bold">
                        Previous Stage : {rowData?.previousStage}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <Typography variant="h6" fontWeight="bold">
                        Current Stage : {rowData?.currentStage}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <Typography variant="h6" fontWeight="bold">
                        Next Stage : {rowData?.nextStage}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} md={3}>
                      <Typography variant="h6" fontWeight="bold">
                        Days Remaining : {rowData?.daysRemaining}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </Box>

          <Box sx={{ width: "100%" }} bgcolor={"white"}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={caseDetailsTab}
                onChange={(event, newValue) => setCaseDetailsTab(newValue)}
                aria-label="basic tabs example"
                sx={{
                  display: "flex",
                  overflowX: "auto",
                  whiteSpace: "nowrap",
                  "& .MuiTabs-scrollButtons": {
                    display: { xs: "flex", sm: "flex" },
                  },
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="Case Primary Information"
                  sx={{
                    minWidth: { xs: "100%", sm: "120px" },
                    textAlign: "center",
                  }}
                />

                {rowData?.caseType?.categoryName
                  ?.toLowerCase()
                  .includes("arbitration") && (
                  <Tab
                    label="Pre-Checks & Commencement"
                    sx={{
                      minWidth: { xs: "100%", sm: "120px" },
                      textAlign: "center",
                    }}
                  />
                )}

                {rowData?.caseType?.categoryName
                  ?.toLowerCase()
                  .includes("arbitration") && (
                  <Tab
                    label="Preliminary Checks & Commencement"
                    sx={{
                      minWidth: { xs: "100%", sm: "120px" },
                      textAlign: "center",
                    }}
                  />
                )}

                {rowData?.caseType?.categoryName
                  ?.toLowerCase()
                  .includes("arbitration") && (
                  <Tab
                    label="Arbitration Notices/Key Dates"
                    sx={{
                      minWidth: { xs: "100%", sm: "120px" },
                      textAlign: "center",
                    }}
                  />
                )}

                {rowData?.caseType?.categoryName
                  ?.toLowerCase()
                  .includes("arbitration") && (
                  <Tab
                    label="Trial Proceedings"
                    sx={{
                      minWidth: { xs: "100%", sm: "120px" },
                      textAlign: "center",
                    }}
                  />
                )}
                {/* New */}

                {rowData?.caseType?.categoryName
                  ?.toLowerCase()
                  .includes("arbitration") && (
                  <Tab
                    label="Arbitration Orders/Awards"
                    sx={{
                      minWidth: { xs: "100%", sm: "120px" },
                      textAlign: "center",
                    }}
                  />
                )}

                {/* New */}

                <Tab
                  label="Custom Fields"
                  sx={{
                    minWidth: { xs: "100%", sm: "120px" },
                    textAlign: "center",
                  }}
                />

                {apiValues?.length !== 0 && (
                  <Tab
                    label="Case Checklist"
                    sx={{
                      minWidth: { xs: "100%", sm: "120px" },
                      textAlign: "center",
                    }}
                  />
                )}

                {rowData?.caseType?.categoryName
                  ?.toLowerCase()
                  ?.includes("arbitration") && (
                  <Tab
                    label="Arbitration Duration And Timeline Management"
                    sx={{
                      minWidth: { xs: "100%", sm: "120px" },
                      textAlign: "center",
                    }}
                  />
                )}
              </Tabs>
            </Box>
            {caseDetailsTab === 0 && (
              <>
                <CasePrimaryInformation />
              </>
            )}

            {caseDetailsTab === 1 &&
              rowData?.caseType?.categoryName
                ?.toLowerCase()
                ?.includes("arbitration") && <ArbitrationProceedings />}

            {caseDetailsTab === 2 &&
              rowData?.caseType?.categoryName
                ?.toLowerCase()
                ?.includes("arbitration") && <PreliminaryChecksCommencement />}

            {caseDetailsTab === 3 &&
              rowData?.caseType?.categoryName
                ?.toLowerCase()
                ?.includes("arbitration") && <ArbitrationNoticesKeyDates />}

            {caseDetailsTab === 4 &&
              rowData?.caseType?.categoryName
                ?.toLowerCase()
                ?.includes("arbitration") && <TrialProceedings />}

            {caseDetailsTab === 5 &&
              rowData?.caseType?.categoryName
                ?.toLowerCase()
                ?.includes("arbitration") && (
                <ArbitrationInterimOrdersReliefsAwards />
              )}

            {(caseDetailsTab === 6 ||
              (caseDetailsTab === 1 &&
                !rowData?.caseType?.categoryName
                  ?.toLowerCase()
                  ?.includes("arbitration"))) && (
              <>
                <Box sx={{ p: 2 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography gutterBottom variant="h5" component="div">
                      Custom Fields
                    </Typography>
                    {/* <Typography gutterBottom variant="h6" component="div">
                  $4.50
                </Typography> */}
                  </Stack>
                  <SectionView
                    data={data}
                    setOpenEdit={setOpenEdit}
                    user={user}
                  />
                </Box>
              </>
            )}
            {(caseDetailsTab === 7 ||
              (!rowData?.caseType?.categoryName
                ?.toLowerCase()
                .includes("arbitration") &&
                apiValues.length > 0 &&
                caseDetailsTab === 2) ||
              (!rowData?.caseType?.categoryName
                ?.toLowerCase()
                .includes("arbitration") &&
                caseDetailsTab === 2)) && (
              <Box sx={{ p: 3 }}>
                {apiValues?.map((item, index) => {
                  return (
                    <Accordion
                      key={index}
                      className="mt-2"
                      expanded={window.edit && expanded === item.checklistID}
                      onChange={handleChange(item.checklistID)}
                    >
                      <AccordionSummary
                        expandIcon={
                          <>
                            {item.checklistDocument?.[0] && (
                              <IoMdAttach
                                className="fs-6 mx-2 text-primary"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setOpen(true);
                                  setChecklistDocuments(item);
                                }}
                              />
                            )}
                            <ExpandMoreIcon />
                          </>
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className="d-flex justify-content-between"
                      >
                        <Typography sx={{ color: "text.black" }}>
                          {item.checklistName}
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <h5>Document Upload</h5>
                        <Formik
                          initialValues={{
                            date: new Date(),
                            notes: "",
                            user,
                            documentClassification: "",
                            documentService: "",
                            serviceCount: "",
                            uploadedFiles,
                            checklistID: item.checklistID,
                            caseID: rowData?.caseID,
                            caseRowID: rowData?.caseDetailsID,
                          }}
                          validationSchema={Yup.object().shape({
                            date: Yup.date().required("Date is required"),
                            documentClassification: Yup.string()
                              .required("Classification is required")
                              .min(1, "Select the valid option"),
                            documentService: Yup.string()
                              .required("Document Service is required")
                              .min(1, "Select the valid option"),
                            serviceCount: Yup.string()
                              .required("Service Count is required")
                              .test(
                                "is-greater-than-or-equal-to",
                                "Value must be greater than or equal to zero",
                                (value) => {
                                  return (
                                    value !== undefined &&
                                    !isNaN(value) &&
                                    Number(value) >= 0
                                  );
                                }
                              ),
                          })}
                          onSubmit={async (values, actions) => {
                            try {
                              let uploadValue = { values, uploadedFiles };
                              if (uploadedFiles.length > 0) {
                                dispatch(
                                  createChecklistDocumentAction(uploadValue)
                                );
                                newData("");
                                setUploadedFiles([]);
                                animateCss(
                                  "success",
                                  "Checklist Document Upload Successfully"
                                );
                              } else if (uploadedFiles.length === 0) {
                                animateCss("warn", "Pleae Upload the Document");
                              }
                            } catch (error) {}
                          }}
                        >
                          {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            touched,
                            values,
                            setFieldValue,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <FormControl
                                style={{
                                  minWidth: "200px",
                                  marginRight: "20px",
                                  marginBottom: "10px",
                                }}
                              >
                                <Field name="date" sx={{ p: 5 }}>
                                  {({ field, form }) => (
                                    <DatePickerField
                                      error={Boolean(
                                        touched.date && errors.date
                                      )}
                                      field={field}
                                      form={form}
                                    />
                                  )}
                                </Field>
                              </FormControl>

                              <Button
                                component="label"
                                variant="outlined"
                                color="primary"
                                className="my-2"
                                multiple
                                type="file"
                                onChange={handleFileEvent}
                                startIcon={
                                  <SvgIcon>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth={1.5}
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                      />
                                    </svg>
                                  </SvgIcon>
                                }
                              >
                                Upload File
                                <input
                                  type="file"
                                  multiple
                                  style={{ display: "none" }}
                                />
                              </Button>
                              <Stack
                                spacing={2}
                                direction="row"
                                className="my-2"
                              >
                                <FormControl
                                  className="col-5"
                                  style={{
                                    minWidth: "200px",
                                    marginBottom: "10px",
                                  }}
                                  error={Boolean(
                                    touched.documentClassification &&
                                      errors.documentClassification
                                  )}
                                >
                                  <InputLabel id="documentClassification-label">
                                    Document Classification
                                  </InputLabel>

                                  <Select
                                    id="documentClassification"
                                    name="documentClassification"
                                    value={values?.documentClassification}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    {settings
                                      ?.find(
                                        (item) =>
                                          item.categoryName ==
                                          "Document Classification"
                                      )
                                      ?.Children?.map((data, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={data?.generalSettingsID}
                                          >
                                            {data?.categoryName}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                  <FormHelperText>
                                    {touched.documentClassification &&
                                      errors.documentClassification}
                                  </FormHelperText>
                                </FormControl>

                                <FormControl
                                  className="col-5"
                                  style={{
                                    minWidth: "200px",
                                    marginBottom: "10px",
                                  }}
                                  error={Boolean(
                                    touched.documentService &&
                                      errors.documentService
                                  )}
                                >
                                  <InputLabel id="documentService-label">
                                    Document Service
                                  </InputLabel>
                                  <Select
                                    id="documentService"
                                    label="Document Service"
                                    name="documentService"
                                    value={values?.documentService}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ height: "42px" }}
                                  >
                                    {settings
                                      ?.find(
                                        (item) =>
                                          item.categoryName ==
                                          "Document Service"
                                      )
                                      ?.Children?.map((data, index) => {
                                        return (
                                          <MenuItem
                                            key={index}
                                            value={data?.generalSettingsID}
                                          >
                                            {data?.categoryName}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                  {touched.documentService &&
                                    errors.documentService && (
                                      <FormHelperText>
                                        {errors.documentService}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Stack>
                              <Stack spacing={2} className="my-2">
                                <FormControl className="col-5 ">
                                  <TextField
                                    required
                                    id="serviceCount"
                                    label="Service Count"
                                    type="number"
                                    name="serviceCount"
                                    value={values?.serviceCount}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(
                                      touched.serviceCount &&
                                        errors.serviceCount
                                    )}
                                  />
                                </FormControl>
                                <FormHelperText className="text-danger">
                                  {touched?.serviceCount &&
                                    errors?.serviceCount}
                                </FormHelperText>
                              </Stack>
                              <DemoItem label="Notes" className="my-2">
                                <TextareaAutosize
                                  className={classes.textarea}
                                  // rowsMin={5}
                                  placeholder="Enter Notes..."
                                  onChange={handleChange}
                                  name="notes"
                                />
                              </DemoItem>
                              <Stack
                                spacing={2}
                                direction="row"
                                className="mt-2"
                              >
                                <Button
                                  type="submit"
                                  variant="contained"
                                  color="primary"
                                >
                                  Submit
                                </Button>
                              </Stack>
                            </form>
                          )}
                        </Formik>

                        {uploadedFiles[0] && (
                          <Stack>
                            <p className="mt-3">Uploading Documents</p>
                            <Stack className="d-flex row " spacing={2}>
                              <Paper
                                sm={{ height: "250px" }}
                                sx={{
                                  display: "flex",
                                  direction: "row",
                                  justifyContent: "start",
                                  flexWrap: "wrap",
                                  height: 200,
                                  overflow: "scroll",
                                }}
                                component="ul"
                              >
                                {uploadedFiles.map((data, index) => {
                                  let icon;

                                  icon = (
                                    <FcDocument
                                      sx={{
                                        bgcolor: "white",
                                        color: deepOrange[500],
                                      }}
                                    />
                                  );

                                  return (
                                    <ListItem key={index}>
                                      <Chip
                                        color="primary"
                                        variant="outlined"
                                        avatar={
                                          <Avatar variant="square">
                                            {icon}
                                          </Avatar>
                                        }
                                        label={data.name}
                                        onDelete={handleDelete(data)}
                                      />
                                    </ListItem>
                                  );
                                })}
                              </Paper>
                            </Stack>
                          </Stack>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Box>
            )}

            {caseDetailsTab === 8 &&
              rowData?.caseType?.categoryName
                ?.toLowerCase()
                ?.includes("arbitration") && (
                <ArbitrationDurationAndTimelineManagement />
              )}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Triangle
            visible={true}
            height="150"
            width="200"
            color="#a36ceb"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </Box>
      )}
    </div>
  );
};

export default CaseChecklist;

const SectionView = ({ data, setOpenEdit, user }) => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const groupedData = data.reduce((groups, item) => {
    const group = item.group || "";
    if (!groups[group]) {
      groups[group] = [];
    }
    groups[group].push(item);
    return groups;
  }, {});

  function formatDate(isoDate) {
    const date = new Date(isoDate);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <div className=" mt-2">
      {Object.keys(groupedData)
        .sort((a, b) => a.localeCompare(b))
        .map((group, index) => {
          return (
            <div key={index} className="list-group my-2">
              <Accordion
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  expandIcon={
                    <>
                      {" "}
                      <ExpandMoreIcon />
                    </>
                  }
                  aria-controls="panel3-content"
                  id="panel3-header"
                  className="d-flex justify-content-between "
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      width: "100%",
                      backgroundColor: "white",
                    }}
                  >
                    <Typography>{group}</Typography>

                    {window?.edit && (
                      <div>
                        <Button
                          type="button"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              setOpenEdit(groupedData?.[group]);
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {groupedData[group].map((item, idx) => (
                    <a key={idx} className="list-group-item ">
                      <div
                        key={idx}
                        className="d-flex w-100 justify-content-between"
                      >
                        <p className="mb-1">{item.Header}</p>
                        <Typography
                          variant="body2"
                          sx={{ textAlign: "right", color: "text.secondary" }}
                        >
                          {item.Header?.toLowerCase().includes("date")
                            ? // ? formatDate(new Date(item.value))
                              item.value
                            : item.value}
                        </Typography>
                      </div>
                    </a>
                  ))}
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
    </div>
  );
};

const CaseTracking = ({
  rowData,
  settings,
  handleFormSubmit,
  validationSchema,
  resetForm,
  initialValues,
  classes,
  dispatch = useDispatch,
  setEditCaseTracker,
  newData,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        try {
          // dispatch(caseTrackingUpdateAction(values));
          // setEditCaseTracker(false);
          // setTimeout(() => {
          //   dispatch(resetSingleCaseDetailsReducer());
          //   dispatch(allCaseDetailsAction());
          //   newData(null);
          // }, 1000);
        } catch (error) {
          // Handle submission error if needed
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        resetForm,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="m-0 "
                  >
                    Case Tracker
                  </Typography>

                  <div>
                    <Button
                      type="button"
                      color="primary"
                      onClick={(event) => {
                        event.stopPropagation();
                        // resetForm();
                        setEditCaseTracker(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      onClick={async () => {
                        try {
                          dispatch(caseTrackingUpdateAction(values));
                          setEditCaseTracker(false);
                          setTimeout(() => {
                            // dispatch(resetSingleCaseDetailsReducer());
                            // dispatch(allCaseDetailsAction());
                            // newData(null);
                          }, 1000);
                        } catch (error) {}
                      }}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      backgroundColor: "white",
                      flexWrap: "wrap",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography variant="body1" component="label">
                            SOA Date
                          </Typography>

                          <DatePicker
                            id="soaDate"
                            selected={values?.soaDate}
                            onChange={(date) => {
                              setFieldValue("soaDate", date);
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>
                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography
                            variant="body1"
                            component="label"
                            sx={{ marginRight: 2 }}
                          >
                            LRN Notice Date
                          </Typography>

                          <DatePicker
                            id="lrnDate"
                            selected={values?.lrnDate}
                            onChange={(date) => {
                              setFieldValue("lrnDate", date);
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          TRN Date
                          <DatePicker
                            id="trnDate"
                            selected={values?.trnDate}
                            onChange={(date) => {
                              setFieldValue("trnDate", date);
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>
                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography
                            className="pr-4"
                            variant="body1"
                            component="label"
                          >
                            Notice For Arbitrator Consent Date
                          </Typography>

                          <DatePicker
                            id="noticeForArbitrationConsentDate"
                            selected={values?.noticeForArbitrationConsentDate}
                            onChange={(date) => {
                              setFieldValue(
                                "noticeForArbitrationConsentDate",
                                date
                              );
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2 mt-4"
                        >
                          <Typography variant="body1" component="label">
                            Arbitrator Consent Letter Date
                          </Typography>
                          <DatePicker
                            id="arbitrationConsentLetterDate"
                            selected={values?.arbitrationConsentLetterDate}
                            onChange={(date) => {
                              setFieldValue(
                                "arbitrationConsentLetterDate",
                                date
                              );
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>

                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography
                            className="pr-4"
                            variant="body1"
                            component="label"
                          >
                            Notice Of Arbitrator Disclosure Date
                          </Typography>

                          <DatePicker
                            id="noticeOfArbitratorDisclosureDate"
                            selected={values?.noticeOfArbitratorDisclosureDate}
                            onChange={(date) => {
                              setFieldValue(
                                "noticeOfArbitratorDisclosureDate",
                                date
                              );
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography
                            className="pr-4"
                            variant="body1"
                            component="label"
                          >
                            Notice of Appointment of Arbitrator Date
                          </Typography>
                          <DatePicker
                            id="noticeOfAppointmentOfArbitratorDate"
                            selected={
                              values?.noticeOfAppointmentOfArbitratorDate
                            }
                            onChange={(date) => {
                              setFieldValue(
                                "noticeOfAppointmentOfArbitratorDate",
                                date
                              );
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>

                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography
                            className="pr-4"
                            variant="body1"
                            component="label"
                          >
                            Claim Filling Date
                          </Typography>

                          <DatePicker
                            id="claimStatementDate"
                            selected={values?.claimStatementDate}
                            onChange={(date) => {
                              setFieldValue("claimStatementDate", date);
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control mt-4"
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography
                            className="pr-4"
                            variant="body1"
                            component="label"
                          >
                            IA Application Date
                          </Typography>

                          <DatePicker
                            id="iaApplicationDate"
                            selected={values?.iaApplicationDate}
                            onChange={(date) => {
                              setFieldValue("iaApplicationDate", date);
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>
                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography
                            className="pr-4"
                            variant="body1"
                            component="label"
                          >
                            First Hearing Date
                          </Typography>

                          <DatePicker
                            id="firstNoticeDate"
                            selected={values?.firstNoticeDate}
                            onChange={(date) => {
                              setFieldValue("firstNoticeDate", date);
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography
                            className="pr-4"
                            variant="body1"
                            component="label"
                          >
                            IA Order Date
                          </Typography>

                          <DatePicker
                            id="iaOrderDate"
                            selected={values?.iaOrderDate}
                            onChange={(date) => {
                              setFieldValue("iaOrderDate", date);
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>

                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography
                            className="pr-4"
                            variant="body1"
                            component="label"
                          >
                            Second Hearing Date
                          </Typography>
                          <DatePicker
                            id="secondNoticeDate"
                            selected={values?.secondNoticeDate}
                            onChange={(date) => {
                              setFieldValue("secondNoticeDate", date);
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography
                            className="pr-4"
                            variant="body1"
                            component="label"
                          >
                            Award Date
                          </Typography>

                          <DatePicker
                            id="awardDate"
                            selected={values?.awardDate}
                            onChange={(date) => {
                              setFieldValue("awardDate", date);
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>

                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography
                            className="pr-4"
                            variant="body1"
                            component="label"
                          >
                            Third Hearing Date
                          </Typography>

                          <DatePicker
                            id="thirdHearing"
                            selected={values?.thirdHearing}
                            onChange={(date) => {
                              setFieldValue("thirdHearing", date);
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography
                            className="pr-4"
                            variant="body1"
                            component="label"
                          >
                            REF Notice Date
                          </Typography>

                          <DatePicker
                            id="refNoticeDate"
                            selected={values?.refNoticeDate}
                            onChange={(date) => {
                              setFieldValue("refNoticeDate", date);
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>

                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography
                            className="pr-4"
                            variant="body1"
                            component="label"
                          >
                            Final Hearing Date
                          </Typography>

                          <DatePicker
                            id="finalNoticeDate"
                            selected={values?.finalNoticeDate}
                            onChange={(date) => {
                              setFieldValue("finalNoticeDate", date);
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="grid"
                          alignItems="center"
                          style={{ width: "250px" }}
                          className="px-2"
                        >
                          <Typography
                            className="pr-4"
                            variant="body1"
                            component="label"
                          >
                            Case Close Date
                          </Typography>

                          <DatePicker
                            id="caseCloseDate"
                            selected={values?.caseCloseDate}
                            onChange={(date) => {
                              setFieldValue("caseCloseDate", date);
                            }}
                            onBlur={handleBlur}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="DD-MM-YYYY"
                            className="form-control"
                          />
                        </Box>
                      </Box>
                    </LocalizationProvider>

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box
                        display="grid"
                        alignItems="center"
                        style={{ width: "250px" }}
                        className="px-2"
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Stage
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          label="Amount"
                          name="stage"
                          value={values?.stage}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box
                        display="grid"
                        alignItems="center"
                        style={{ width: "250px" }}
                        className="px-2"
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Contested
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="contested"
                          value={values?.contested}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box
                        display="grid"
                        alignItems="center"
                        style={{ width: "250px" }}
                        className="px-2"
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Arbitral Tribunal
                        </InputLabel>

                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="arbitralTribunal"
                          value={values?.arbitralTribunal}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {settings
                            ?.find(
                              (item) => item.categoryName == "Arbitral Tribunal"
                            )
                            ?.Children?.map((data, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={data?.generalSettingsID}
                                >
                                  {data?.categoryName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </Box>

                      <Box
                        display="grid"
                        alignItems="center"
                        style={{ width: "250px" }}
                        className="px-2"
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Number of Arbitrators
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          name="numberOfArbitrators"
                          value={values?.numberOfArbitrators}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box
                        display="grid"
                        alignItems="center"
                        style={{ width: "250px" }}
                        className="px-2"
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Appointing Authority Name
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          name="appointingAuthorityName"
                          value={values?.appointingAuthorityName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Box>
                      <Box
                        display="grid"
                        alignItems="center"
                        style={{ width: "250px" }}
                        className="px-2"
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Nature of Proceedings
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="natureOfProceedings"
                          value={values?.natureOfProceedings}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {settings
                            ?.find(
                              (item) =>
                                item.categoryName == "Nature of Proceedings"
                            )
                            ?.Children?.map((data, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={data?.generalSettingsID}
                                >
                                  {data?.categoryName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box
                        display="grid"
                        alignItems="center"
                        style={{ width: "250px" }}
                        className="px-2"
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Mode of Proceedings
                        </InputLabel>

                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="modeOfProceedings"
                          value={values?.modeOfProceedings}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {settings
                            ?.find(
                              (item) =>
                                item.categoryName == "Mode of Proceedings"
                            )
                            ?.Children?.map((data, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={data?.generalSettingsID}
                                >
                                  {data?.categoryName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </Box>
                      <Box
                        display="grid"
                        alignItems="center"
                        style={{ width: "250px" }}
                        className="px-2"
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">
                          IA Order Issued
                        </InputLabel>

                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="iaOrderIssued"
                          value={values?.iaOrderIssued}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box
                        display="grid"
                        alignItems="center"
                        style={{ width: "250px" }}
                        className="px-2"
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Case Status
                        </InputLabel>

                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="caseStatus"
                          value={values?.caseStatus}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {settings
                            ?.find((item) => item.categoryName == "Case Status")
                            ?.Children?.map((data, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={data?.generalSettingsID}
                                >
                                  {data?.categoryName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </Box>
                      <Box
                        display="grid"
                        alignItems="center"
                        style={{ width: "250px" }}
                        className="px-2"
                      >
                        <InputLabel htmlFor="outlined-adornment-amount">
                          Case Closing Reason
                        </InputLabel>

                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="caseClosingReason"
                          value={values?.caseClosingReason}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          {settings
                            ?.find(
                              (item) =>
                                item.categoryName == "Case Closing Reason"
                            )
                            ?.Children?.map((data, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={data?.generalSettingsID}
                                >
                                  {data?.categoryName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </Box>
                    </Box>
                    <Box fullWidth>
                      <Box display="grid" alignItems="center" fullWidth>
                        <InputLabel
                          htmlFor="outlined-adornment-amount"
                          fullWidth
                        >
                          PoA/LoA Name
                        </InputLabel>

                        <OutlinedInput
                          fullWidth
                          name="poaLoaName"
                          value={values?.poaLoaName}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </Box>
                    </Box>
                    <Box width="100%">
                      <Box display="grid" alignItems="center" width="100%">
                        <Box mr={2}>
                          <span>Notes</span>
                        </Box>
                        <Box>
                          <TextareaAutosize
                            className={classes.textarea}
                            rowsMin={5}
                            name="note"
                            aria-label="maximum height"
                            value={values?.note}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              </AccordionDetails>
            </Accordion>
          </form>
        );
      }}
    </Formik>
  );
};
