import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import SuccessAlert from "../assets/audio/success.mp3";
import DangerAlert from "../assets/audio/danger.mp3";
import WarnAlert from "../assets/audio/warn.mp3";
import { ThreeDots } from "react-loader-spinner";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { Button, styled } from "@mui/material";

export const bounce = cssTransition({
  enter: "animate__animated animate__bounceIn",
  exit: "animate__animated animate__bounceOut",
});

const playAudio = (audioFile) => {
  const audio = new Audio(audioFile);
  audio.play();
};

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

export function animateCss(color, text, time) {
  const show = time ? time : 1000;
  let audioFile = null;

  if (color === "message") {
    audioFile = DangerAlert;
    // Play the alert sound (if needed)
    new Audio(audioFile).play();

    enqueueSnackbar(
      <span
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />,
      {
        // variant: "error", // Corrected to string
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: null,
        action: (key) => (
          <>
            <StyledButton
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              Cancel
            </StyledButton>
          </>
        ),
        style: {
          backgroundColor: "#d32f2f",
          // width: "600px",
          color: "#fff",
        },
      }
    );
  }
  if (color == "success") {
    audioFile = SuccessAlert;
    toast.success(text, {
      transition: bounce,
      autoClose: show,
    });
  }
  if (color == "error") {
    audioFile = DangerAlert;
    toast.error(text, {
      transition: bounce,
      autoClose: show,
    });
  }
  if (color == "info") {
    audioFile = WarnAlert;
    toast.info(text, {
      transition: bounce,
      autoClose: show,
    });
  }
  if (color == "warn") {
    audioFile = WarnAlert;
    toast.warn(text, {
      transition: bounce,
      autoClose: show,
    });
  }
  if (color == "default") {
    audioFile = WarnAlert;
    toast(text, {
      transition: bounce,
      autoClose: show,
    });
  }
  if (audioFile) {
    playAudio(audioFile);
  }
}
