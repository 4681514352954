import axios from "axios";
import { api } from "./api_route";
import { useDispatch } from "react-redux";
import { animateCss } from "../pages/TosterAlert";
import {
  allotmentOrganizationCaseAction,
  selectAllotmentOrganizationCaseDetailsAction,
} from "../redux/actions/allotmentDocuments/allotmentDocumentsAction";

export const allotmentDocumentUpload = async (values) => {
  try {
    const result = await axios.post(`${api}/allotmentDocuments/upload`, values);

    return result;
  } catch (error) {
    return [];
  }
};

export const selectAllotmentOrganizationCaseDetails = async (values) => {
  try {
    const result = await axios.post(
      `${api}/allotmentDocuments/selectedOrganzationCase`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const allotmentOrganizationCaseDetails = async (values) => {
  try {
    const result = await axios.post(
      `${api}/allotmentDocuments/organzationCase`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const selectAllotmentDeleteCaseDetails = async (values) => {
  try {
    const result = await axios.post(
      `${api}/allotmentDocuments/selectAllotmentDeleteCaseDetails`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};

export const deleteAllotmentDeleteCaseDetails = async (values) => {
  try {
    let result = await axios.post(
      `${api}/allotmentDocuments/deleteAllotmentDeleteCaseDetails`,
      values
    );

    if (result) {
      allotmentOrganizationCaseAction();
      animateCss(
        "success",
        "AllotmentDocument & Case Detail Delete Successfully"
      );
      dispatchEvent(selectAllotmentOrganizationCaseDetailsAction());
    }

    return await result;
  } catch (error) {
    return [];
  }
};

export const findAgreementNumber = async (values) => {
  try {
    const result = await axios.post(
      `${api}/allotmentDocuments/findAgreementNumber`,
      values
    );

    return result;
  } catch (error) {
    return [];
  }
};
