import * as React from "react";
import { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ReactDOMServer from "react-dom/server";

import * as XLSX from "xlsx";

import SvgIcon from "@mui/material/SvgIcon";
import styled from "@mui/material/styles/styled";
import {
  Avatar,
  CardContent,
  CardHeader,
  Chip,
  Paper,
  Stack,
} from "@mui/material";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { animateCss } from "../../TosterAlert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { deepOrange, green } from "@mui/material/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { MobileDatePicker, TabContext, TabList, TabPanel } from "@mui/lab";
import ReactDatePicker from "react-datepicker";
import { allOrganizationAction } from "../../../redux/actions/OrganizationAction/OrganizationAction";
import { allCaseTypeAction } from "../../../redux/actions/Case/caseAction";
import AllotmentMapping from "./AllotmentMapping";
import { SiMicrosoftexcel } from "react-icons/si";
import { allotmentHeaderReducer } from "../../../redux/slices/Case/caseSlice";
import {
  allCaseMappingDetailAction,
  caseMappingDetailAction,
  organizationActiveCaseTemplateAction,
} from "../../../redux/actions/Case/caseMappingAction";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  allotmentDocumentUploadAction,
  allotmentOrganizationCaseAction,
  findAgreementNumberAction,
  selectAllotmentOrganizationCaseDetailsAction,
} from "../../../redux/actions/allotmentDocuments/allotmentDocumentsAction";
import AllotmentCaseDetails from "./AllotmentCaseDetails";
import { api } from "../../../api/api_route";
import { closeSnackbar } from "notistack";

const DatePickerField = ({ field, form, ...other }) => {
  const { name, value } = field;

  const handleChange = (val) => {
    form.setFieldValue(name, val);
  };

  return (
    <React.Fragment>
      <ReactDatePicker
        sx={{ m: 0, p: 2 }}
        className="p-2 form-control"
        {...other}
        selected={value}
        onChange={handleChange}
        dateFormat="dd-MM-yyyy"
        placeholderText="Select Date"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        error={form.touched[name] && form.errors[name]}
      />
      {form.touched[name] && form.errors[name] && (
        <FormHelperText error>{form.errors[name]}</FormHelperText>
      )}
    </React.Fragment>
  );
};

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const MAX_COUNT = 1;

const Allotment = () => {
  const [excelData, setExcelData] = useState(null);
  const [excelHeader, setExcelHeader] = useState(null);
  const [excelAlterData, setExcelAlterData] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectOrganization, setSelectOrganization] = useState();
  const [exportTemplate, setExportTemplate] = useState();
  const [fileLimit, setFileLimit] = useState(false);
  const [value, setValue] = useState("1");
  const [headerMapping, setHeaderMapping] = useState({
    organizationName: null,
    caseType: null,
    template: null,
  });

  const [showUpload, setShowUpload] = useState(false);
  const user = useSelector((state) => state.login.data);
  const organizationName = useSelector((state) => state?.organization?.profile);
  const caseType = useSelector((state) => state?.case?.settings);
  const organizationTemplate = useSelector(
    (state) => state.case.allotment.allotmentOrganizationTemplate?.[0]
  );

  const dispatch = useDispatch();

  const tableStyle = {
    WebkitAlignItems: "flex-start",
    WebkitBoxAlign: "flex-start",
    MsFlexAlign: "flex-start",
    alignItems: "flex-start",
    boxSizing: "border-box",
    display: "-webkit-box",
    display: "-webkit-flex",
    display: "-ms-flexbox",
    display: "flex",
    gap: "0.5rem",
    WebkitBoxPack: "justify",
    WebkitJustifyContent: "space-between",
    justifyContent: "flex-end",
    padding: "0.5rem",
    position: "relative",
    right: 0,
    top: 0,
    width: "100%",
    baseBackgroundColor: "white",
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const columns = useMemo(() => {
    if (!excelData || excelData.length === 0) {
      return [];
    }

    const firstRow = excelData.find((row) => Object.keys(row).length > 0);
    if (!firstRow) {
      return [];
    }

    return Object.keys(firstRow).map((columnId) => ({
      Header: columnId,
      accessor: columnId,
      id: columnId,
      Cell: ({ row }) => {
        const cellValue = row.original[columnId];

        return <Typography variant="body1">{cellValue}</Typography>;
      },
    }));
  }, [excelData]);

  const table = useMaterialReactTable({
    columns,
    data: excelData ?? [],
    enableRowNumbers: true,
    rowNumberDisplayMode: "original",
    enableFullScreenToggle: false,
    enableRowSelection: false,
    columnFilterDisplayMode: "popover",
    enableRowVirtualization: true,
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableStickyHeader: true,
    enableGlobalFilter: true,
    initialState: {
      pagination: { pageSize: 20, pageIndex: 0 },
      density: "compact",
    },
    keyAccessor: (row, rowIndex) =>
      row.orderStatus + "_" + row.id + "_" + rowIndex,
  });

  const handleUploadFiles = (files) => {
    const uploaded = uploadedFiles;
    let limitExceeded = false;
    files.some((file) => {
      if (
        file.type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          uploaded.pop();
          setExcelData(null);
          setExcelHeader(null);
          uploaded.push(file);

          if (uploaded?.length === MAX_COUNT) setFileLimit(true);
          if (uploaded?.length > MAX_COUNT) {
            animateCss(
              "error",
              `You can only add a maximum of ${MAX_COUNT} files`,
              1000
            );
            setFileLimit(false);
            limitExceeded = true;
            return true;
          }
        }
      } else if (
        file.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        animateCss("error", "Please Upload Excel Document Only");
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
    setExcelData([]);
    setExcelHeader([]);
    handleFileUpload(uploaded);
    handleFileHeader(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleDelete = (chipToDelete) => () => {
    setUploadedFiles([]);
    setUploadedFiles((chips) =>
      chips.filter((chip) => chip.name !== chipToDelete.name)
    );
    setUploadedFiles([]);
    setExcelData();
    setExcelHeader();
  };

  const handleFileUpload = (files) => {
    Array.from(files).forEach((file) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, {
          header: 1,
          raw: false,
          dateNF: "YYYY-MM-DD",
        });

        const headers = jsonData[0];

        function processRow(row, headers) {
          const convertedRow = {};

          headers.forEach((header, index) => {
            const value = row[index];

            convertedRow[header] = value;
          });

          return convertedRow;
        }

        const parsedData = jsonData.slice(1).map((row) => {
          return processRow(row, headers);
        });

        setExcelData(parsedData);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleFileHeader = async (files) => {
    await setExcelHeader(null);
    await setExcelData(null);
    Array.from(files).forEach((file) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        const arrayBuffer = event?.target.result;
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const headers = jsonData?.[0];

        const parsedData = await Promise.all(
          headers?.map(async (row, index) => {
            if (!row) return null;

            const match = organizationTemplate?.find(
              (item) => item.header?.trim() === row?.trim()
            );

            return {
              id: index + 1,
              header: row,
              matchHeader: match ?? "",
              fieldNotNeed: match?.fieldNotNeeded === true,
              alterHeader: match?.alterHeader || row,
              group: match ?? null,
              reportsAndTemplates: match?.reportsAndTemplates || false,
            };
          })
        );

        const filteredData = parsedData?.filter(Boolean);

        setExcelHeader((prevExcelHeader) => {
          const values = filteredData
            ? [...filteredData, ...(prevExcelHeader || [])]
            : prevExcelHeader || [];
          return values;
        });
      };

      reader.readAsArrayBuffer(file);
    });
  };

  function formatDate(isoDate) {
    const date = new Date(isoDate);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  const uploadFileMatchingData = async () => {
    function processRow(item) {
      const convertedRow = {};

      for (let key in item) {
        const value = item[key];

        if (typeof value === "number") {
          if (value > 0 && value < 1000000) {
            const date = XLSX.SSF.parse_date_code(value);
            convertedRow[key] = new Date(
              Date.UTC(
                date.y,
                date.m - 1,
                date.d,
                date.H || 0,
                date.M || 0,
                date.S || 0
              )
            );
          } else {
            convertedRow[key] = value;
          }
        } else {
          convertedRow[key] = value;
        }
      }

      return convertedRow;
    }

    if (!excelData) return;

    const transformedData = await Promise.all(
      excelData.map(async (item, i) => {
        if (item) {
          const transformedObj = {};

          // Process the row (asynchronously if needed)
          const convertedRow = processRow(item);

          for (let key in convertedRow) {
            transformedObj.alterData = transformedObj.alterData || {};
            transformedObj.alterData[`${key}`] = {
              alterHeader: key?.trim(),
              value: item[key]?.trim() ?? null,
              group: excelHeader?.find(
                (template) => template?.header?.trim() === key?.trim()
              )?.group,
              fieldNotNeed:
                organizationTemplate?.find(
                  (template) => template.header?.trim() === key?.trim()
                )?.fieldNotNeeded === true,
              reportsAndTemplates:
                organizationTemplate?.find(
                  (template) => template.header?.trim() === key?.trim()
                )?.reportsAndTemplates === true,
            };
          }

          transformedObj.actualData = convertedRow;

          return transformedObj;
        }
      })
    );

    setExcelAlterData(transformedData.filter(Boolean));
  };

  useEffect(() => {
    uploadFileMatchingData();
  }, [excelData]);

  useEffect(() => {
    dispatch(allOrganizationAction());
    dispatch(allCaseTypeAction());
    dispatch(allotmentOrganizationCaseAction());
    dispatch(allCaseMappingDetailAction());
  }, []);

  useEffect(() => {
    dispatch(allotmentHeaderReducer(excelHeader));
  }, [excelHeader]);

  useEffect(() => {
    handleFileHeader(uploadedFiles);
  }, [organizationTemplate]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const caseTemplates = useSelector(
    (state) => state?.case.caseMappingDetails.allCaseMappingDetails
  );

  const filteredTemplates = caseTemplates?.filter((item) => {
    return item.caseMapRowID === exportTemplate;
  });

  const exportData = async (fieldRows) => {
    try {
      const rows = JSON.parse(fieldRows)?.[0];

      const tableData = await Promise.all(
        await rows.map(async (item, index) => {
          // if (item.fieldNotNeeded !== true) {
          return item.header;
          // }
        })
      );

      const filteredTableData = tableData.filter((item) => item !== undefined);

      const valuesArray = [filteredTableData];

      const ws = XLSX.utils.aoa_to_sheet(valuesArray);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Case Template");

      XLSX.writeFile(wb, "case_template.xlsx");
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  return (
    <>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", paddingBottom: 2 }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Allotment Case Document  " value="1" />
            <Tab label="Document Upload" value="2" disabled={!window.edit} />
          </TabList>
        </Box>
        {value == "1" && (
          <Box
            sx={{ borderBottom: 1, borderColor: "divider" }}
            className="p-0 m-0"
          >
            <div
              className=" bg-white shadow p-3 mb-5 rounded"
              sx={{
                width: 400,
                "@media (min-width: 300px)": {
                  width: 300,
                },
                "@media (min-width: 400px)": {
                  width: 350,
                },
                "@media (min-width: 600px)": {
                  width: 280,
                },
                "@media (min-width: 900px)": {
                  width: 400,
                },
                "@media (min-width: 1200px)": {
                  width: 400,
                },
                "@media (min-width: 1536px)": {
                  width: 400,
                },
              }}
            >
              <h5>Allotment Case Details</h5>

              <hr />
              <Formik
                initialValues={{
                  organization: "",
                  caseType: "",
                }}
                validationSchema={Yup.object().shape({
                  organization: Yup.string().required(
                    "Organization Name is required"
                  ),
                  caseType: Yup.string().required("Case Type is required"),
                })}
                onSubmit={(values, actions) => {
                  try {
                    dispatch(
                      selectAllotmentOrganizationCaseDetailsAction(values)
                    );
                  } catch (error) {}
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        flexWrap: "wrap",
                      }}
                    >
                      <FormControl
                        style={{
                          minWidth: "200px",
                          marginRight: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="organization">
                          Organization Name
                        </InputLabel>
                        <Select
                          labelId="organization"
                          placeholder="Organization Name"
                          id="organization"
                          label="Organization Name"
                          name="organization"
                          value={values.organization}
                          onChange={async (event) => {
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.organization && errors.organization
                          )}
                        >
                          {organizationName?.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                              {item.organizationName}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.organization && errors.organization && (
                          <FormHelperText error>
                            {errors.organization}
                          </FormHelperText>
                        )}
                      </FormControl>
                      <FormControl
                        style={{
                          minWidth: "200px",
                          marginRight: "20px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="caseType">Case Type</InputLabel>
                        <Select
                          labelId="caseType"
                          id="caseType"
                          label="Case Type"
                          name="caseType"
                          value={values.caseType}
                          onChange={async (event) => {
                            handleChange(event);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(touched.caseType && errors.caseType)}
                        >
                          {caseType?.map((item, index) => (
                            <MenuItem
                              key={item.generalSettingsID}
                              value={item.generalSettingsID}
                            >
                              {item.categoryName}
                            </MenuItem>
                          ))}
                        </Select>
                        {touched.caseType && errors.caseType && (
                          <FormHelperText error>
                            {errors.caseType}
                          </FormHelperText>
                        )}
                      </FormControl>

                      <Button
                        className="p-0"
                        style={{
                          padding: "10px",
                          height: "40px",
                        }}
                        variant="contained"
                        type="submit"
                      >
                        Search
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
            <AllotmentCaseDetails />
          </Box>
        )}
        {value == "2" && (
          <>
            <Box
              sx={{ width: "100%", typography: "body1" }}
              className="mb-5 pb-5"
            >
              <div
                className=" bg-white shadow p-3 mb-5 rounded"
                sx={{
                  width: 400,
                  "@media (min-width: 300px)": {
                    width: 300,
                  },
                  "@media (min-width: 400px)": {
                    width: 350,
                  },
                  "@media (min-width: 600px)": {
                    width: 280,
                  },
                  "@media (min-width: 900px)": {
                    width: 400,
                  },
                  "@media (min-width: 1200px)": {
                    width: 400,
                  },
                  "@media (min-width: 1536px)": {
                    width: 400,
                  },
                }}
              >
                <Formik
                  initialValues={{
                    user,
                    template: "",
                    organizationName: "",
                    caseType: "",
                    date: new Date(),
                    uploadedFiles,
                  }}
                  validationSchema={Yup.object().shape({
                    template: Yup.string().required(
                      "Case Template is required"
                    ),
                    organizationName: Yup.string().required(
                      "Organization Name is required"
                    ),
                    caseType: Yup.string().required("Case Type is required"),
                  })}
                  onSubmit={async (values, actions) => {
                    try {
                      const agreementNumbers = excelAlterData
                        ?.map((item) => {
                          return Object.values(item.alterData)
                            .filter(
                              (value) =>
                                value.group?.alterHeader === "Agreement Number"
                            )
                            .map((value) => value.value);
                        })
                        .flat();

                      const data = {
                        agreementNumbers,
                        organizationName: values.organizationName,
                        caseType: values.caseType,
                      };
                      dispatch(findAgreementNumberAction(data))
                        .unwrap()
                        .then((result) => {
                          if (result?.result?.length > 0) {
                            const agreementList = result?.result
                              .map((item) => `<p>${item}</p>`)
                              .join("");

                            const messageContent = `
                            Please upload a valid allotment document and remove the following agreement numbers as they already exist in the case:
                            <div style="max-height: 150px; overflow-y: auto; border: 1px solid #ccc;">
                              ${result?.result.map((item) => `<li>${item}</li>`)}
                            </div>
                            Kindly verify the details, make the necessary corrections, and reupload the document
                            `;

                            actions.resetForm();
                            setUploadedFiles([]);
                            setExcelData(null);
                            setExcelHeader(null);
                            setTimeout(() => {
                              setValue("1");
                            }, 1000);

                            animateCss("message", messageContent);
                          } else {
                            let uploadValue = {
                              values,
                              uploadedFiles,
                              excelData,
                              excelAlterData,
                            };

                            if (uploadedFiles?.length > 0) {
                              if (headerMapping) {
                                const initialValues = {
                                  user,
                                  organizationName: "",
                                  caseType: "",
                                  date: new Date(),
                                };

                                dispatch(
                                  allotmentDocumentUploadAction(uploadValue)
                                );
                                actions.resetForm();
                                setUploadedFiles([]);
                                setExcelData(null);
                                setExcelHeader(null);
                                setTimeout(() => {
                                  setValue("1");
                                }, 1000);
                              } else if (!headerMapping) {
                              }
                            } else {
                              animateCss("warn", "Please Upload The Document");
                            }
                          }
                        })
                        .catch((error) => {
                          console.error("Action rejected with error:", error);
                        });

                      // let uploadValue = {
                      //   values,
                      //   uploadedFiles,
                      //   excelData,
                      //   excelAlterData,
                      // };

                      // if (uploadedFiles?.length > 0) {
                      //   if (headerMapping) {
                      //     const initialValues = {
                      //       user,
                      //       organizationName: "",
                      //       caseType: "",
                      //       date: new Date(),
                      //     };

                      //     dispatch(allotmentDocumentUploadAction(uploadValue));
                      //     actions.resetForm();
                      //     setUploadedFiles([]);
                      //     setExcelData(null);
                      //     setExcelHeader(null);
                      //     setTimeout(() => {
                      //       setValue("1");
                      //     }, 1000);
                      //   } else if (!headerMapping) {
                      //   }
                      // } else {
                      //   animateCss("warn", "Please Upload The Document");
                      // }
                    } catch (error) {}
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    isSubmitting,
                    setFieldValue,
                    resetForm,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div className="d-flex row col-12 justify-content-between">
                        <div className="col-6">
                          {" "}
                          <h5>Document Upload</h5>
                        </div>
                      </div>

                      <hr />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <FormControl
                          style={{
                            minWidth: "200px",
                            marginRight: "20px",
                            marginBottom: "10px",
                          }}
                        >
                          <InputLabel id="organization">
                            Organization Name
                          </InputLabel>
                          <Select
                            labelId="organization"
                            placeholder="Organization Name"
                            id="organization"
                            label="Organization Name"
                            name="organizationName"
                            value={values.organizationName}
                            onChange={async (event) => {
                              handleChange(event);
                              let organizationData = headerMapping;
                              organizationData.organizationName =
                                await event?.target?.value;
                              organizationData.template = await "";
                              await setHeaderMapping(organizationData);

                              await dispatch(
                                organizationActiveCaseTemplateAction(
                                  headerMapping
                                )
                              );
                            }}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.organizationName &&
                                errors.organizationName
                            )}
                          >
                            {organizationName?.map((item, index) => (
                              <MenuItem key={index} value={item.id}>
                                {item.organizationName}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.organizationName &&
                            errors.organizationName && (
                              <FormHelperText error>
                                {errors.organizationName}
                              </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                          style={{
                            minWidth: "200px",
                            marginRight: "20px",
                            marginBottom: "10px",
                          }}
                        >
                          <InputLabel id="caseType">Case Type</InputLabel>
                          <Select
                            labelId="caseType"
                            id="caseType"
                            label="Case Type"
                            name="caseType"
                            value={values.caseType}
                            onChange={async (event) => {
                              handleChange(event);
                              let organizationData = headerMapping;
                              organizationData.caseType =
                                await event?.target?.value;
                              organizationData.template = await "";
                              await setHeaderMapping(organizationData);

                              await dispatch(
                                organizationActiveCaseTemplateAction(
                                  headerMapping
                                )
                              );
                            }}
                            onBlur={handleBlur}
                            error={Boolean(touched.caseType && errors.caseType)}
                          >
                            {values.organizationName &&
                              caseType?.map((item, index) => (
                                <MenuItem
                                  key={item.generalSettingsID}
                                  value={item.generalSettingsID}
                                >
                                  {item.categoryName}
                                </MenuItem>
                              ))}
                          </Select>
                          {touched.caseType && errors.caseType && (
                            <FormHelperText error>
                              {errors.caseType}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControl
                          style={{
                            minWidth: "200px",
                            marginRight: "20px",
                            marginBottom: "10px",
                          }}
                        >
                          <InputLabel id="template">Case Template</InputLabel>
                          <Select
                            labelId="template"
                            placeholder="Case Template"
                            id="template"
                            label="Case Template"
                            name="template"
                            value={values.caseType && values.template}
                            onChange={async (event) => {
                              setExportTemplate(event.target.value);
                              handleChange(event);
                              let organizationData = headerMapping;
                              organizationData.template =
                                await event?.target?.value;

                              await setHeaderMapping(organizationData);

                              await dispatch(
                                organizationActiveCaseTemplateAction(
                                  headerMapping
                                )
                              );

                              setSelectOrganization(values);
                            }}
                            onBlur={handleBlur}
                            error={Boolean(touched.template && errors.template)}
                          >
                            {values.caseType &&
                              caseTemplates?.map((item, index) => {
                                if (
                                  headerMapping?.organizationName ===
                                    item.organization.id &&
                                  headerMapping?.caseType ===
                                    item.caseType.generalSettingsID &&
                                  item.isActive
                                ) {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={item.caseMapRowID}
                                    >
                                      {item.templateName}
                                    </MenuItem>
                                  );
                                }
                              })}
                          </Select>
                          {touched.template && errors.template && (
                            <FormHelperText error>
                              {errors.template}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <Box
                          className="px-2"
                          display="flex"
                          justifyContent="center"
                        >
                          {values?.organizationName !== "" &&
                            values?.template !== "" &&
                            filteredTemplates?.[0]?.uploadName && (
                              // <a
                              //   href={`${api}/documents/template/${filteredTemplates?.[0]?.uploadName}`}
                              //   download="allotmentDocument.xlsx"
                              // >
                              //   <Tooltip title="Template download">
                              //     <IconButton
                              //       variant="circular"
                              //       edge="end"
                              //       className=""
                              //     >
                              //       <SiMicrosoftexcel className="text-success" />
                              //     </IconButton>
                              //   </Tooltip>
                              // </a>

                              <Box display="flex" justifyContent="center">
                                <Tooltip
                                  title="download"
                                  onClick={() => {
                                    exportData(
                                      JSON.stringify(
                                        filteredTemplates?.[0]?.mappingData
                                      )
                                    );
                                  }}
                                >
                                  <IconButton variant="circular" edge="end">
                                    <SiMicrosoftexcel className="text-success" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            )}
                        </Box>

                        <FormControl
                          style={{
                            minWidth: "200px",
                            marginRight: "20px",
                            marginBottom: "10px",
                          }}
                        >
                          <Field name="date" sx={{ p: 5 }}>
                            {({ field, form }) => (
                              <DatePickerField
                                error={Boolean(touched.date && errors.date)}
                                field={field}
                                form={form}
                              />
                            )}
                          </Field>
                        </FormControl>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "start",
                          flexWrap: "wrap",
                        }}
                      >
                        <Grid className="justify-content-center">
                          {headerMapping.template && (
                            <Button
                              component="label"
                              variant="outlined"
                              color="primary"
                              type="file"
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              onChange={handleFileEvent}
                              startIcon={
                                <SvgIcon>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                    />
                                  </svg>
                                </SvgIcon>
                              }
                            >
                              Upload Excel File
                              <input
                                type="file"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                style={{ display: "none" }}
                              />
                            </Button>
                          )}
                        </Grid>
                        <Paper
                          sx={{
                            boxShadow: "none",
                            listStyle: "none",
                          }}
                          component="ul"
                        >
                          {uploadedFiles.map((data, index) => {
                            let icon;

                            icon = (
                              <SiMicrosoftexcel
                                className="text-success bg-white h5 p-0 m-0"
                                sx={{ bgcolor: "white" }}
                              />
                            );

                            return (
                              <ListItem key={index}>
                                <Chip
                                  variant="outlined"
                                  avatar={
                                    <Avatar variant="square">{icon}</Avatar>
                                  }
                                  label={data.name}
                                  onDelete={handleDelete(data)}
                                />
                              </ListItem>
                            );
                          })}
                        </Paper>
                      </div>
                      <hr />
                      <div
                        className="m-2"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          alignItems: "end",
                          justifyContent: "flex-end",
                        }}
                      >
                        {excelHeader && (
                          <Button
                            className="p-0 mx-3"
                            style={{
                              padding: "10px",
                              height: "40px",
                            }}
                            variant="contained"
                            type="submit"
                          >
                            Submit
                          </Button>
                        )}
                        <Button
                          className="p-0 mx-3"
                          style={{
                            padding: "10px",
                            height: "40px",
                          }}
                          variant="contained"
                          type="button"
                          onClick={() => {
                            resetForm();
                            setUploadedFiles([]);
                            setExcelData();
                            setExcelHeader();
                            setHeaderMapping({
                              organizationName: null,
                              caseType: null,
                              template: null,
                            });
                          }}
                          disabled={isSubmitting}
                        >
                          Clear
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
              {excelHeader && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    {" "}
                    <Typography variant="h5">
                      Allotment Header Status
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AllotmentMapping excelHeader={excelHeader} />
                  </AccordionDetails>
                </Accordion>
              )}
              <br />
            </Box>

            {excelHeader && <MaterialReactTable table={table} />}
          </>
        )}
      </TabContext>
    </>
  );
};
export default Allotment;
