import {
  createMRTColumnHelper,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  styled,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  OrganizationCaseDetailsAction,
  allCaseDetailsAction,
  allCaseTypeAction,
  filterColumnAction,
} from "../../../redux/actions/Case/caseAction";
import { MdOutlineBackupTable } from "react-icons/md";
import * as XLSX from "xlsx";
import { animateCss } from "../../TosterAlert";
import {
  UpdateCaseFilterAction,
  userUpdateCaseFilterAction,
} from "../../../redux/actions/UserAction/usersAction";
import { allOrganizationAction } from "../../../redux/actions/OrganizationAction/OrganizationAction";
import { Typography } from "antd";
import { Triangle } from "react-loader-spinner";
import { FaRegFilePdf } from "react-icons/fa";
import { BsFiletypeCsv } from "react-icons/bs";
import { CiCircleInfo } from "react-icons/ci";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { alpha } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import {
  exportCSVDocumentAction,
  exportExcelAction,
  exportPDFDocumentAction,
  exportWordAction,
} from "../../../redux/actions/hooks/exportDocumentAction";
import { exportCSV, exportPDF, exportXLSX } from "../../../helpers/helperTool";

const dateFormate = (dateString) => {
  const date = new Date(dateString);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

const tableStyle = {
  WebkitAlignItems: "flex-start",
  WebkitBoxAlign: "flex-start",
  MsFlexAlign: "flex-start",
  alignItems: "flex-start",
  boxSizing: "border-box",
  display: "-webkit-box",
  display: "-webkit-flex",
  display: "-ms-flexbox",
  display: "flex",
  gap: "0.5rem",
  WebkitBoxPack: "justify",
  WebkitJustifyContent: "space-between",
  justifyContent: "space-between",
  // padding: "0.5rem",
  position: "relative",
  right: 0,
  top: 0,
  // width: "100%",
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top-start" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const OrganizationCaseDetails = () => {
  const [newData, setNewData] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filterOrganizationName, setFilterOrganizationName] = useState();
  const [filterCaseType, setFilterCaseType] = useState();

  const user = useSelector((state) => state.login.data);

  const casefilter = useSelector((state) => state?.case?.caseFilter) ?? {};

  const columnArrange =
    useSelector((state) => state?.case?.columnArrange) ?? [];

  const [columnVisibility, setColumnVisibility] = useState(casefilter);

  const pageCount = useSelector((state) => state?.case?.caseDetails?.pageCount);

  const dispatch = useDispatch();

  const AllotmentCase = useSelector((state) => state.case.caseDetails.allData);

  const isLoading = useSelector((state) => state.case.loading);
  const organizationName = useSelector((state) => state?.organization?.profile);
  const caseType = useSelector((state) => state?.case?.settings);
  const columnHelper = createMRTColumnHelper();
  const columns = useMemo(
    () => [
      columnHelper.accessor("case Number", {
        header: "case Number",
        Cell: ({ row }) => {
          return (
            <Link
              to={
                window?.edit
                  ? `/case/caseDetails/${row.original?.["case Number"]}`
                  : `/case/caseDetails/`
              }
              onClick={() => {
                if (!window?.edit) {
                  animateCss("info", "You are not authorized to update");
                } else {
                  setNewData(row.original);
                }
              }}
              style={{ textDecoration: "none" }}
            >
              {row.original?.["case Number"]}
            </Link>
          );
        },
      }),
      columnHelper.accessor("Case Name", {
        header: "Case Name",
        size: 200,
      }),
      columnHelper.accessor("Case Status", {
        header: "Case Status",
        size: 280,
      }),
      columnHelper.accessor("Case Opened On", {
        header: "Case Opened On",
        size: 250,
      }),
      columnHelper.accessor("Case Close On", {
        header: "Case Close On",
        size: 200,
      }),
      columnHelper.accessor("Case Closing Reason", {
        header: "Case Closing Reason",
        size: 250,
      }),
      columnHelper.accessor("Case Closing Description", {
        header: "Case Closing Description",
        size: 250,
      }),
      columnHelper.accessor("Priority Level", {
        header: "Priority Level",
        size: 250,
      }),
      columnHelper.accessor("Complexity", {
        header: "Complexity",
        size: 250,
      }),
      columnHelper.accessor("Risk – Monetary", {
        header: "Risk – Monetary",
        size: 250,
      }),
      columnHelper.accessor("Risk – Reputation", {
        header: "Risk – Reputation",
        size: 250,
      }),
      columnHelper.accessor("Risk - Regulatory", {
        header: "Risk - Regulatory",
        size: 300,
      }),
      columnHelper.accessor("Plaintiff /Claimant / Petitioner / Appellant", {
        header: "Plaintiff /Claimant / Petitioner / Appellant",
        size: 300,
      }),
      columnHelper.accessor(
        "Legal Representative of Plaintiff /Claimant / Petitioner / Appellant",
        {
          header:
            "Legal Representative of Plaintiff /Claimant / Petitioner / Appellant",
          size: 350,
        }
      ),
      columnHelper.accessor(
        "Advocate for Plaintiff /Claimant / Petitioner / Appellant",
        {
          header: "Advocate for Plaintiff /Claimant / Petitioner / Appellant",
          size: 350,
        }
      ),
      columnHelper.accessor("Vakalat by Claimant Counsel Filed", {
        header: "Vakalat by Claimant Counsel Filed",
        size: 300,
      }),
      columnHelper.accessor("Vakalat By Claimant Counsel Filed On", {
        header: "Vakalat By Claimant Counsel Filed On",
        size: 340,
      }),
      columnHelper.accessor("Defendant / Respondent", {
        header: "Defendant / Respondent",
        size: 280,
      }),
      columnHelper.accessor("Legal Representative of Defendant / Respondent", {
        header: "Legal Representative of Defendant / Respondent",
        size: 280,
      }),
      columnHelper.accessor("Advocate for Defendant / Respondent", {
        header: "Advocate for Defendant / Respondent",
        size: 300,
      }),
      columnHelper.accessor("Vakalat by Respondent Counsel Filed", {
        header: "Vakalat by Respondent Counsel Filed",
        size: 300,
      }),
      columnHelper.accessor("Vakalat by Respondent Counsel Filed On", {
        header: "Vakalat by Respondent Counsel Filed On",
        size: 300,
      }),
      columnHelper.accessor("Practice Area", {
        header: "Practice Area",
        size: 300,
      }),
      columnHelper.accessor("Case Type", {
        header: "Case Type",
        size: 300,
      }),
      columnHelper.accessor("Arbitration Matter Type", {
        header: "Arbitration Matter Type",
        size: 200,
      }),
      columnHelper.accessor("Arbitration Matter Description", {
        header: "Arbitration Matter Description",
        size: 250,
      }),
      columnHelper.accessor("Previous Stage", {
        header: "Previous Stage",
        size: 250,
      }),
      columnHelper.accessor("Current Stage", {
        header: "Current Stage",
        size: 300,
      }),
      columnHelper.accessor("Next Stage", {
        header: "Next Stage",
      }),
      columnHelper.accessor("Court/Forum", {
        header: "Court/Forum",
      }),
      columnHelper.accessor("Jurisdiction Type", {
        header: "Jurisdiction Type",
      }),
      columnHelper.accessor("Dispute Value", {
        header: "Dispute Value",
      }),
      columnHelper.accessor("Cause of Action On", {
        header: "Cause of Action On",
      }),
      columnHelper.accessor(
        "Is the dispute/claim within the Statute of Limitations?",
        {
          header: "Is the dispute/claim within the Statute of Limitations?",
          size: 400,
        }
      ),
      columnHelper.accessor("Case Description", {
        header: "Case Description",
      }),
      columnHelper.accessor("Mode of Communications", {
        header: "Mode of Communications",
        size: 350,
      }),
      columnHelper.accessor("City / Taluk / Village", {
        header: "City / Taluk / Village",
        size: 200,
      }),
      columnHelper.accessor("District", {
        header: "District",
        size: 200,
      }),
      columnHelper.accessor("State", {
        header: "State",
      }),
      columnHelper.accessor("Country", {
        header: "Country",
      }),
      columnHelper.accessor("Case Contested", {
        header: "Case Contested",
      }),
    ],
    [AllotmentCase, pagination]
  );

  const [columnOrder, setColumnOrder] = useState(columnArrange);

  useEffect(
    () => {
      setColumnOrder(columnArrange);
      setColumnVisibility(casefilter);
    },
    [],
    [columnArrange, casefilter]
  );

  function isValidJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    dispatch(filterColumnAction());
    dispatch(allCaseTypeAction());
    dispatch(allOrganizationAction());
  }, []);
  useEffect(
    () => {
      setColumnVisibility(casefilter);
    },
    [casefilter],
    []
  );

  useEffect(() => {
    const values = { user, columnVisibility };
    const trueKeys = Object.keys(columnVisibility).filter(
      (key) => columnVisibility[key]
    );

    // if (trueKeys.length <= 20) {
    if (columnVisibility !== null && Object.keys(columnVisibility).length > 0) {
      dispatch(UpdateCaseFilterAction(values));
    }
    // } else {
    //   animateCss(
    //     "error",
    //     `You have try selecte ${trueKeys.length} columns. The maximum limit is 20 columns Only.`,
    //     2500
    //   );
    // setColumnVisibility(casefilter);
    // }
  }, [columnVisibility]);

  useEffect(() => {
    const values = {
      organization: filterOrganizationName,
      caseType: filterCaseType,
      user,
    };
    const value = { values, pagination, user };

    filterOrganizationName
      ? dispatch(OrganizationCaseDetailsAction(value))
      : dispatch(allCaseDetailsAction(caseDetailsFetchData));
  }, [filterOrganizationName]);

  const caseDetailsFetchData = {
    pagination,
    user,
  };

  const [alertVisible, setAlertVisible] = useState(false);

  const handleColumnVisibilityChange = (updatedColumnVisibility) => {
    const visibleColumns = Object.values(updatedColumnVisibility).filter(
      Boolean
    ).length;

    if (visibleColumns > 20) {
      if (!alertVisible) {
        setAlertVisible(true);
      }

      return;
    } else if (visibleColumns < 20) {
      setColumnVisibility(updatedColumnVisibility);
    }
  };

  const handleOrder = (newColumnOrder) => {
    setColumnOrder(newColumnOrder);

    const arrangedFilterColumn = { "mrt-row-numbers": true };
    newColumnOrder?.forEach((key) => {
      if (casefilter.hasOwnProperty(key)) {
        arrangedFilterColumn[key] = casefilter[key];
      } else {
        arrangedFilterColumn[key] = false;
      }
    });

    if (newColumnOrder?.length > 2) {
      const values = {
        user,
        columnVisibility: arrangedFilterColumn,
      };

      setColumnVisibility(arrangedFilterColumn);
    }
  };

  const table = useMaterialReactTable(
    {
      columns,
      data: AllotmentCase ?? [],
      state: {
        pagination,
        columnOrder: columnOrder ?? Object.keys(casefilter),
        columnVisibility,
        isLoading,
      },
      enableRowNumbers: true,
      rowNumberDisplayMode: "static",
      muiPaginationProps: {
        rowsPerPageOptions: [5, 10, 20, 100, 500, 1000],
        color: "primary",
        shape: "rounded",
        showRowsPerPage: true,
        variant: "outlined",
      },
      paginationDisplayMode: "pages",
      onPaginationChange: setPagination,
      displayColumnDefOptions: {
        "#": {
          enableHiding: true,
        },
        "mrt-row-actions": {
          visibleInShowHideMenu: true,
        },
        "mrt-row-numbers": {
          enableHiding: true,
        },
      },
      enableColumnFilterModes: true,
      enableColumnOrdering: true,
      enableFacetedValues: true,
      // rowCount: pageCount,
      enableRowActions: false,
      enableColumnResizing: true,
      enableResizing: true,
      // rowCount: pageCount,
      rowNumberDisplayMode: "original",
      enableFullScreenToggle: false,
      enableRowSelection: false,
      columnFilterDisplayMode: "popover",
      enableRowVirtualization: true,
      positionToolbarAlertBanner: "bottom",
      enableStickyHeader: true,
      enableGlobalFilter: true,
      initialState: {
        columnVisibility,
        showColumnFilters: true,
        density: "compact",
      },
      muiCircularProgressProps: {
        color: "secondary",
        thickness: 5,
        size: 55,
      },
      muiSkeletonProps: {
        animation: "pulse",
        height: 28,
      },
      onColumnVisibilityChange: handleColumnVisibilityChange,
      onColumnOrderChange: handleOrder,
      renderTopToolbarCustomActions: ({ column, table }) => (
        <>
          {" "}
          <div>
            <Formik
              initialValues={{
                organization: "",
                caseType: "",
              }}
              validationSchema={Yup.object().shape({
                organization: Yup.string().required(
                  "Organization Name is required"
                ),
                caseType: Yup.string().required("Case Type is required"),
              })}
              onSubmit={(values, actions) => {
                try {
                  setFilterOrganizationName(values?.organization);
                  setFilterCaseType(values?.caseType);

                  const value = { values, pagination, user };

                  dispatch(OrganizationCaseDetailsAction(value));
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                resetForm,
                touched,
                values,
                setFieldValue,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",
                      flexWrap: "wrap",
                    }}
                  >
                    <FormControl
                      style={{
                        minWidth: "200px",
                        marginRight: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel id="organization">
                        Organization Name
                      </InputLabel>
                      <Select
                        labelId="organization"
                        placeholder="Organization Name"
                        id="organization"
                        label="Organization Name"
                        name="organization"
                        value={values.organization}
                        onChange={async (event) => {
                          handleChange(event);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.organization && errors.organization
                        )}
                      >
                        {organizationName?.map((item, index) => (
                          <MenuItem key={index} value={item.id}>
                            {item.organizationName}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.organization && errors.organization && (
                        <FormHelperText error>
                          {errors.organization}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      style={{
                        minWidth: "200px",
                        marginRight: "20px",
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel id="caseType">Case Type</InputLabel>
                      <Select
                        labelId="caseType"
                        id="caseType"
                        label="Case Type"
                        name="caseType"
                        value={values.caseType}
                        onChange={async (event) => {
                          handleChange(event);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(touched.caseType && errors.caseType)}
                      >
                        {caseType?.map((item, index) => (
                          <MenuItem
                            key={item.generalSettingsID}
                            value={item.generalSettingsID}
                          >
                            {item.categoryName}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.caseType && errors.caseType && (
                        <FormHelperText error>{errors.caseType}</FormHelperText>
                      )}
                    </FormControl>

                    <Button
                      className="p-0 mx-2"
                      style={{
                        padding: "10px",
                        height: "40px",
                      }}
                      variant="contained"
                      type="submit"
                    >
                      Search
                    </Button>
                    <Button
                      className="p-0"
                      style={{ padding: "10px", height: "40px" }}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        resetForm();
                        setFilterOrganizationName(null);
                        setFilterCaseType(null);
                      }}
                      type="button"
                    >
                      Clear
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <Box style={tableStyle}>
            {/* <Button
              onClick={async () => {
                try {
                  exportToExcel();
                } catch (error) {
                  console.error("Export failed:", error);
                }
              }}
              startIcon={<MdOutlineBackupTable className="text-success" />}
            >
              Export
            </Button> */}

            <CustomizedMenus
              visibleCount={columnVisibility}
              excel={() => {
                exportToExcel(table.getPrePaginationRowModel().rows);
              }}
              PDF={() => {
                exportToPDF(table.getPrePaginationRowModel().rows);
              }}
              CSV={() => {
                exportToCSV(table.getPrePaginationRowModel().rows);
              }}
              WORD={() => {
                exportToWORD(table.getPrePaginationRowModel().rows);
              }}
            />
          </Box>
        </>
      ),
    },
    [AllotmentCase, columnVisibility, casefilter]
  );

  const truncateString = (str, maxLength) => {
    return str && str.length > maxLength ? str.slice(0, maxLength) : str;
  };

  const exportToExcel = async (rows) => {
    const tableData = rows.map((row, index) => {
      const originalData = row.original;
      const reorderedData = {};

      reorderedData["S No"] = index + 1;

      columnOrder.forEach((column) => {
        if (columnVisibility[column]) {
          if (column === "mrt-row-numbers") {
            reorderedData["S No"] = index + 1;
          } else {
            reorderedData[column] = originalData[column] || "";
          }
        }
      });

      return reorderedData;
    });

    exportXLSX(tableData, "CaseDetails", "CaseDetails");
  };

  const exportToPDF = async (rows) => {
    const tableData = rows.map((row, index) => {
      const originalData = row.original;
      const reorderedData = {};

      reorderedData["S No"] = index + 1;

      columnOrder.forEach((column) => {
        if (columnVisibility[column]) {
          if (column === "mrt-row-numbers") {
            reorderedData["S No"] = index + 1;
          } else {
            reorderedData[column] = originalData[column] || "";
          }
        }
      });

      return reorderedData;
    });

    exportPDF(tableData, "CaseDetails", "CaseDetails");
  };

  const exportToCSV = async (rows) => {
    const tableData = rows.map((row, index) => {
      const originalData = row.original;
      const reorderedData = {};

      reorderedData["S No"] = index + 1;

      columnOrder.forEach((column) => {
        if (columnVisibility[column]) {
          if (column === "mrt-row-numbers") {
            reorderedData["S No"] = index + 1;
          } else {
            reorderedData[column] = originalData[column] || "";
          }
        }
      });

      return reorderedData;
    });

    exportCSV(tableData, "CaseDetails", "CaseDetails");
  };

  const exportToWORD = () => {
    const filterColumns = Object.keys(columnVisibility).filter(
      (key) => columnVisibility[key]
    );

    const matchColumns = columns
      ?.filter((item) => filterColumns.includes(item.id))
      .map((item) => item.header);

    const dataToExport = AllotmentCase.map((item) => {
      const row = {};

      matchColumns.forEach((col) => {
        const cellValue = item[col];

        let extractedValue = null;

        if (typeof cellValue === "string") {
          try {
            const parsedValue = JSON.parse(cellValue);

            if (typeof parsedValue === "object" && parsedValue !== null) {
              extractedValue =
                parsedValue.categoryName ||
                parsedValue.organizationName ||
                parsedValue.name ||
                null;
            }
          } catch (e) {
            extractedValue = cellValue;
          }
        }

        row[col] = truncateString(extractedValue || cellValue, 32767);
      });

      return row;
    });

    dispatch(exportWordAction(dataToExport));
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="p-0 m-0">
        {!newData && (
          <>
            <MaterialReactTable table={table} />
          </>
        )}
      </Box>
    </>
  );
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

function CustomizedMenus({ excel, PDF, CSV, WORD, visibleCount }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const trueKeys = Object.keys(visibleCount).filter((key) => visibleCount[key]);

  return (
    <HtmlTooltip
      title={
        <>
          <Typography color="inherit" className="text-info">
            <CiCircleInfo className="text-warn" /> Export Info
          </Typography>
          <Typography variant="body2" className="text-black">
            <strong>Note:</strong> You have selected{" "}
            <b>{trueKeys?.length || 0} Columns</b>. Only a maximum of{" "}
            <b>20 columns</b> will be exported.
          </Typography>
        </>
      }
    >
      <div className="p-0 m-0">
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Export
        </Button>

        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={excel} disableRipple>
            <MdOutlineBackupTable className="text-success mx-2" />
            Excel
          </MenuItem>
          <MenuItem onClick={PDF} disableRipple>
            <FaRegFilePdf className="text-danger mx-2" />
            PDF
          </MenuItem>

          <MenuItem onClick={CSV} disableRipple>
            <BsFiletypeCsv className="text-black mx-2" />
            CSV
          </MenuItem>
          {/* <MenuItem onClick={WORD} disableRipple>
          <BsFiletypeCsv className="text-black mx-2" />
          Word
        </MenuItem> */}
        </StyledMenu>
      </div>
    </HtmlTooltip>
  );
}

export default OrganizationCaseDetails;
