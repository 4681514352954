import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FieldArray, Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "react-datepicker";
import { json, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  allAgreementDocumentListAction,
  singleCaseDetailsAction,
  updateArbitrationInterimOrdersReliefsAwardsAction,
  updateFinalAwardAction,
  updateInterimOrdersAction,
  updateSec17InterimOrdersAction,
  updateSec9InterimOrdersAction,
} from "../../../redux/actions/Case/caseAction";
import React, { useEffect, useState } from "react";
import { animateCss } from "../../TosterAlert";
import ArbitrationDocumentList from "./ArbitrationDocumentList";
import { MdDelete } from "react-icons/md";
import { Table } from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import dayjs from "dayjs";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ArbitrationInterimOrdersReliefsAwards = () => {
  const [open, setOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [editIndex, setEditIndex] = useState();

  const [
    editArbitrationInterimOrdersReliefsAwards,
    setEditArbitrationInterimOrdersReliefsAwards,
  ] = useState(false);

  const [sec17InterimOrders, setSec17InterimOrders] = useState(false);
  const [sec9InterimOrders, setSec9InterimOrders] = useState(false);
  const [interimOrders, setInterimOrders] = useState(false);
  const [finalAward, setFinalAward] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const dispatch = useDispatch();

  function trackerFormatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const { id } = useParams();
  const params = { caseID: id };
  const user = useSelector((state) => state.login.data);
  const settings = useSelector((state) => state?.ChecklistDocument?.settings);
  const rowData = useSelector((state) => state?.case?.editCaseDetails);

  const defaultCategory = settings
    ?.find((item) => item?.categoryName === "Mode of Proceedings")
    ?.Children?.find(
      (data) => data?.categoryName.toLowerCase() === "physical"
    )?.generalSettingsID;

  useEffect(() => {
    dispatch(
      allAgreementDocumentListAction({
        value: "Arbitration Agreement",
        caseID: id,
      })
    );
  }, []);

  return (
    <>
      <ArbitrationDocumentList
        open={open}
        setOpen={setOpen}
        checklistDocuments={{}}
      />
      <Card
        variant="outlined"
        className=" mt-3 md-6 xl-6 xxl-6"
        sx={{ maxWidth: "100%" }}
        xs={12}
        sm={12}
        md={6}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className="m-0 px-2 bg-white  text-secondary"
        >
          Arbitration Orders/Awards
        </Typography>

        <div
          className="d-flex row"
          spacing={2}
          style={{ backgroundColor: "#fbfbfc" }}
        >
          {/* {editArbitrationInterimOrdersReliefsAwards ? (
            <Formik
              initialValues={{
                appForInterimRelief_orderByArbitratorUnderSection17Filed:
                  !rowData?.caseID
                    ? null
                    : rowData.AppForInterimRelief_orderByArbitratorUnderSection17Filed,
                iaApplicationDate: !rowData?.caseID
                  ? null
                  : rowData.iaApplicationDate,
                iaOrderIssued: !rowData?.caseID ? null : rowData.iaOrderIssued,
                iaOrderDate: !rowData?.caseID ? null : rowData.iaOrderDate,
                interimRelief_OrderByArbitratorUnderSection17Notes:
                  !rowData?.caseID
                    ? null
                    : rowData.InterimRelief_OrderByArbitratorUnderSection17Notes,
                appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed:
                  !rowData?.caseID
                    ? null
                    : rowData.AppForInterimRelief_OrderBeforeHighCourtUnderSection9Filed,
                appForInterimRelief_OrderBeforeHighCourtUnderSection9FiledOn:
                  !rowData?.caseID
                    ? null
                    : rowData.AppForInterimRelief_OrderBeforeHighCourtUnderSection9FiledOn,
                statusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9:
                  !rowData?.caseID
                    ? null
                    : rowData.StatusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9,
                interimRelief_OrderByHighCourtUnderSection9PassedOn:
                  !rowData?.caseID
                    ? null
                    : rowData.InterimRelief_OrderByHighCourtUnderSection9PassedOn,
                interimRelief_OrderByHighCourtUnderSection9Notes:
                  !rowData?.caseID
                    ? null
                    : rowData.InterimRelief_OrderByHighCourtUnderSection9Notes,
                appForInterimAwardFiled: !rowData?.caseID
                  ? null
                  : rowData.AppForInterimAwardFiled,
                appForInterimAwardFieldOn: !rowData?.caseID
                  ? null
                  : rowData.AppForInterimAwardFieldOn,
                statusOfAppForInterimAwardField: !rowData?.caseID
                  ? null
                  : rowData.StatusOfAppForInterimAwardField,
                interimAwardPassed: !rowData?.caseID
                  ? null
                  : rowData.InterimAwardPassed,
                interimAwardPassedOn: !rowData?.caseID
                  ? null
                  : rowData.InterimAwardPassedOn,
                interimAwardNotes: !rowData?.caseID
                  ? null
                  : rowData.InterimAwardNotes,
                params,
                user,
              }}
              onSubmit={async (values, actions) => {
                try {
                  dispatch(
                    updateArbitrationInterimOrdersReliefsAwardsAction(values)
                  );

                  setEditArbitrationInterimOrdersReliefsAwards(false);

                  setTimeout(() => {
                    dispatch(singleCaseDetailsAction(params));
                  }, 1000);
                } catch (error) {}
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                resetForm,
              }) => {
                return (
                  <div className="d-flex  col-12 mt-2">
                    <form
                      onSubmit={handleSubmit}
                      style={{ boxShadow: "none" }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Accordion
                        expanded={
                          expanded ===
                          "Arbitration Interim Orders/Reliefs/Awards"
                        }
                        onChange={handleChange(
                          "Arbitration Interim Orders/Reliefs/Awards"
                        )}
                      >
                        <AccordionSummary
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "end",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className="m-0 "
                            >
                              Arbitration Orders/Awards
                            </Typography>

                            <div>
                              <Button
                                type="button"
                                color="primary"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setEditArbitrationInterimOrdersReliefsAwards(
                                    false
                                  );
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                color="primary"
                                onClick={async () => {}}
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <Box
                              xs={12}
                              sm={12}
                              md={12}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                backgroundColor: "white",
                                flexWrap: "wrap",
                                alignItems: "stretch",
                              }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <InputLabel
                                    htmlFor="appForInterimRelief_orderByArbitratorUnderSection17Filed"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Application for Interim Relief/Order by
                                    Arbitrator under Section 17 Filed
                                  </InputLabel>

                                  <Select
                                    labelId="appForInterimRelief_orderByArbitratorUnderSection17Filed"
                                    id="appForInterimRelief_orderByArbitratorUnderSection17Filed"
                                    name="appForInterimRelief_orderByArbitratorUnderSection17Filed"
                                    value={
                                      values?.appForInterimRelief_orderByArbitratorUnderSection17Filed
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                  >
                                    <MenuItem value={"Yes"} defaultValue>
                                      Yes
                                    </MenuItem>
                                    <MenuItem value={"No"} defaultValue>
                                      No
                                    </MenuItem>
                                  </Select>
                                </Box>

                                {values?.appForInterimRelief_orderByArbitratorUnderSection17Filed ==
                                  "Yes" && (
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Application for Interim Relief/Order by
                                      Arbitrator under Section 17 Filed On
                                    </Typography>

                                    <DatePicker
                                      id="iaApplicationDate"
                                      selected={values?.iaApplicationDate}
                                      onChange={(date) => {
                                        setFieldValue(
                                          "iaApplicationDate",
                                          date
                                        );
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>
                                )}

                                {values?.appForInterimRelief_orderByArbitratorUnderSection17Filed ==
                                  "Yes" && (
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Status of Application for Interim
                                      Relief/Order by Arbitrator Section 17
                                    </Typography>

                                    <OutlinedInput
                                      id="iaOrderIssued"
                                      name="iaOrderIssued"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.iaOrderIssued}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>
                                )}

                                {values?.appForInterimRelief_orderByArbitratorUnderSection17Filed ==
                                  "Yes" && (
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Interim Relief/Order by Arbitrator under
                                      Section 17 Passed On
                                    </Typography>

                                    <DatePicker
                                      id="iaOrderDate"
                                      selected={values?.iaOrderDate}
                                      onChange={(date) => {
                                        setFieldValue("iaOrderDate", date);
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>
                                )}

                                {values?.appForInterimRelief_orderByArbitratorUnderSection17Filed ==
                                  "Yes" && (
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Interim Relief/Order by Arbitrator under
                                      Section 17 Notes
                                    </Typography>

                                    <OutlinedInput
                                      id="interimRelief_OrderByArbitratorUnderSection17Notes"
                                      name="interimRelief_OrderByArbitratorUnderSection17Notes"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={
                                        values?.interimRelief_OrderByArbitratorUnderSection17Notes
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>
                                )}

                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <InputLabel
                                    htmlFor="caseStatus"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Application for Interim Relief/Order before
                                    High Court under Section 9 Filed
                                  </InputLabel>

                                  <Select
                                    labelId="appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed"
                                    id="appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed"
                                    name="appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed"
                                    value={
                                      values?.appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                  >
                                    <MenuItem value={"Yes"} defaultValue>
                                      Yes
                                    </MenuItem>
                                    <MenuItem value={"No"} defaultValue>
                                      No
                                    </MenuItem>
                                  </Select>
                                </Box>

                                {values?.appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                                  "Yes" && (
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Application for Interim Relief/Order
                                      before High Court under Section 9 Filed On
                                    </Typography>

                                    <DatePicker
                                      id="appForInterimRelief_OrderBeforeHighCourtUnderSection9FiledOn"
                                      selected={
                                        values?.appForInterimRelief_OrderBeforeHighCourtUnderSection9FiledOn
                                      }
                                      onChange={(date) => {
                                        setFieldValue(
                                          "appForInterimRelief_OrderBeforeHighCourtUnderSection9FiledOn",
                                          date
                                        );
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>
                                )}

                                {values?.appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                                  "Yes" && (
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="statusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Status of Application for Interim
                                      Relief/Order before High Court under
                                      Section 9
                                    </InputLabel>

                                    <Select
                                      labelId="statusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9"
                                      id="statusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9"
                                      name="statusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9"
                                      value={
                                        values?.statusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth
                                    >
                                      {settings
                                        ?.find(
                                          (item) =>
                                            item?.categoryName ==
                                            "Status of Application"
                                        )
                                        ?.Children?.map((data, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={data?.generalSettingsID}
                                            >
                                              {data?.categoryName}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Box>
                                )}
                                {values?.appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                                  "Yes" && (
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Interim Relief/Order by High Court under
                                      Section 9 Passed On
                                    </Typography>

                                    <DatePicker
                                      id="interimRelief_OrderByHighCourtUnderSection9PassedOn"
                                      selected={
                                        values?.interimRelief_OrderByHighCourtUnderSection9PassedOn
                                      }
                                      onChange={(date) => {
                                        setFieldValue(
                                          "interimRelief_OrderByHighCourtUnderSection9PassedOn",
                                          date
                                        );
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>
                                )}
                                {values?.appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                                  "Yes" && (
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Interim Relief/Order by High Court under
                                      Section 9 Notes
                                    </Typography>

                                    <OutlinedInput
                                      id="interimRelief_OrderByHighCourtUnderSection9Notes"
                                      name="interimRelief_OrderByHighCourtUnderSection9Notes"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={
                                        values?.interimRelief_OrderByHighCourtUnderSection9Notes
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>
                                )}

                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <InputLabel
                                    htmlFor="appForInterimAwardFiled"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Application for Interim Award Filed
                                  </InputLabel>

                                  <Select
                                    labelId="appForInterimAwardFiled"
                                    id="appForInterimAwardFiled"
                                    name="appForInterimAwardFiled"
                                    value={values?.appForInterimAwardFiled}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                  >
                                    <MenuItem value={"Yes"} defaultValue>
                                      Yes
                                    </MenuItem>
                                    <MenuItem value={"No"} defaultValue>
                                      No
                                    </MenuItem>
                                  </Select>
                                </Box>
                                {values?.appForInterimAwardFiled == "Yes" && (
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Application for Interim Award Field On
                                    </Typography>

                                    <DatePicker
                                      id="appForInterimAwardFieldOn"
                                      selected={
                                        values?.appForInterimAwardFieldOn
                                      }
                                      onChange={(date) => {
                                        setFieldValue(
                                          "appForInterimAwardFieldOn",
                                          date
                                        );
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>
                                )}
                                {values?.appForInterimAwardFiled == "Yes" && (
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="statusOfAppForInterimAwardField"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Status of Application for Interim Award
                                      Field
                                    </InputLabel>

                                    <Select
                                      labelId="statusOfAppForInterimAwardField"
                                      id="statusOfAppForInterimAwardField"
                                      name="statusOfAppForInterimAwardField"
                                      value={
                                        values?.statusOfAppForInterimAwardField
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth
                                    >
                                      {settings
                                        ?.find(
                                          (item) =>
                                            item?.categoryName ==
                                            "Status of Application"
                                        )
                                        ?.Children?.map((data, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={data?.generalSettingsID}
                                            >
                                              {data?.categoryName}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Box>
                                )}
                                {values?.appForInterimAwardFiled == "Yes" && (
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="interimAwardPassed"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Interim Award Passed
                                    </InputLabel>

                                    <Select
                                      labelId="interimAwardPassed"
                                      id="interimAwardPassed"
                                      name="interimAwardPassed"
                                      value={values?.interimAwardPassed}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth
                                    >
                                      <MenuItem value={"Yes"} defaultValue>
                                        Yes
                                      </MenuItem>
                                      <MenuItem value={"No"} defaultValue>
                                        No
                                      </MenuItem>
                                    </Select>
                                  </Box>
                                )}

                                {values?.interimAwardPassed == "Yes" && (
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Interim Award Passed On
                                    </Typography>

                                    <DatePicker
                                      id="interimAwardPassedOn"
                                      selected={values?.interimAwardPassedOn}
                                      onChange={(date) => {
                                        setFieldValue(
                                          "interimAwardPassedOn",
                                          date ? date.toISOString() : null
                                        );
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>
                                )}
                                {values?.interimAwardPassed == "Yes" && (
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Interim Award Notes
                                    </Typography>

                                    <OutlinedInput
                                      id="interimAwardNotes"
                                      name="interimAwardNotes"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.interimAwardNotes}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>
                                )}
                              </LocalizationProvider>
                            </Box>
                          </>
                        </AccordionDetails>
                      </Accordion>
                    </form>
                  </div>
                );
              }}
            </Formik>
          ) : (
            <>
              <Accordion
                className="mt-2"
                expanded={
                  expanded === "Arbitration Interim Orders/Reliefs/Awards"
                }
                onChange={handleChange(
                  "Arbitration Interim Orders/Reliefs/Awards"
                )}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      width: "100%",
                      backgroundColor: "white",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="m-0 "
                    >
                      Arbitration Orders/Awards
                    </Typography>

                    {window?.edit && (
                      <div>
                        <Button
                          type="button"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              event.stopPropagation();
                              setEditArbitrationInterimOrdersReliefsAwards(
                                true
                              );
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Application for Interim Relief/Order by Arbitrator under Section 17 Filed"
                          secondary={
                            rowData?.AppForInterimRelief_orderByArbitratorUnderSection17Filed
                          }
                        />
                      </List>
                    </Grid>
                    {rowData?.AppForInterimRelief_orderByArbitratorUnderSection17Filed ==
                      "Yes" && (
                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Application for Interim Relief/Order by Arbitrator under Section 17 Filed On"
                            secondary={
                              rowData?.iaApplicationDate
                                ? trackerFormatDate(
                                    new Date(rowData?.iaApplicationDate)
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>
                    )}
                    {rowData?.AppForInterimRelief_orderByArbitratorUnderSection17Filed ==
                      "Yes" && (
                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Status of Application for Interim Relief/Order by Arbitrator Section 17"
                            secondary={rowData?.iaOrderIssued}
                          />
                        </List>
                      </Grid>
                    )}
                    {rowData?.AppForInterimRelief_orderByArbitratorUnderSection17Filed ==
                      "Yes" && (
                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Interim Relief/Order by Arbitrator under Section 17 Passed On"
                            secondary={
                              rowData?.iaOrderDate
                                ? trackerFormatDate(
                                    new Date(rowData?.iaOrderDate)
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>
                    )}
                    {rowData?.AppForInterimRelief_orderByArbitratorUnderSection17Filed ==
                      "Yes" && (
                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Interim Relief/Order by Arbitrator under Section 17 Notes"
                            secondary={
                              rowData?.InterimRelief_OrderByArbitratorUnderSection17Notes
                            }
                          />
                        </List>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Application for Interim Relief/Order before High Court under Section 9 Filed"
                          secondary={
                            rowData?.AppForInterimRelief_OrderBeforeHighCourtUnderSection9Filed
                          }
                        />
                      </List>
                    </Grid>

                    {rowData?.AppForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                      "Yes" && (
                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Application for Interim Relief/Order before High Court under Section 9 Filed On"
                            secondary={
                              rowData?.AppForInterimRelief_OrderBeforeHighCourtUnderSection9FiledOn
                                ? trackerFormatDate(
                                    new Date(
                                      rowData?.AppForInterimRelief_OrderBeforeHighCourtUnderSection9FiledOn
                                    )
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>
                    )}
                    {rowData?.AppForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                      "Yes" && (
                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Status of Application for Interim Relief/Order before High Court under Section 9"
                            secondary={
                              rowData?.StatusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9
                            }
                          />
                        </List>
                      </Grid>
                    )}
                    {rowData?.AppForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                      "Yes" && (
                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary=" Interim Relief/Order by High Court under Section 9 Passed On"
                            secondary={
                              rowData?.InterimRelief_OrderByHighCourtUnderSection9PassedOn
                                ? trackerFormatDate(
                                    new Date(
                                      rowData?.InterimRelief_OrderByHighCourtUnderSection9PassedOn
                                    )
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>
                    )}
                    {rowData?.AppForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                      "Yes" && (
                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Interim Relief/Order by High Court under Section 9 Notes"
                            secondary={
                              rowData?.InterimRelief_OrderByHighCourtUnderSection9Notes
                            }
                          />
                        </List>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Application for Interim Award Filed"
                          secondary={rowData?.AppForInterimAwardFiled}
                        />
                      </List>
                    </Grid>
                    {rowData?.AppForInterimAwardFiled == "Yes" && (
                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary=" Application for Interim Award Field On"
                            secondary={
                              rowData?.AppForInterimAwardFieldOn
                                ? trackerFormatDate(
                                    new Date(rowData?.AppForInterimAwardFieldOn)
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>
                    )}
                    {rowData?.AppForInterimAwardFiled == "Yes" && (
                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Status of Application for Interim Award
                                    Field"
                            secondary={
                              rowData?.statusOfAppForInterimAwardField
                                ?.categoryName
                            }
                          />
                        </List>
                      </Grid>
                    )}
                    {rowData?.AppForInterimAwardFiled == "Yes" && (
                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Interim Award Passed"
                            secondary={rowData?.InterimAwardPassed}
                          />
                        </List>
                      </Grid>
                    )}
                    {rowData?.InterimAwardPassed == "Yes" && (
                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Interim Award Passed On"
                            secondary={
                              rowData?.InterimAwardPassedOn
                                ? trackerFormatDate(
                                    new Date(rowData?.InterimAwardPassedOn)
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>
                    )}
                    {rowData?.InterimAwardPassed == "Yes" && (
                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Interim Award Notes"
                            secondary={rowData?.InterimAwardNotes}
                          />
                        </List>
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )} */}

          {sec17InterimOrders && expanded === "Section 17 - Interim Orders" ? (
            <Formik
              initialValues={{
                sec17ApplicationForInterimOrderFiled:
                  rowData?.sec17ApplicationForInterimOrderFiled
                    ? rowData?.sec17ApplicationForInterimOrderFiled
                    : null,

                sec17InterimOrders:
                  rowData.sec17InterimOrders &&
                  JSON.parse(rowData.sec17InterimOrders)
                    ? JSON.parse(rowData.sec17InterimOrders)
                    : [],
                params,
                user,
              }}
              onSubmit={async (values, actions) => {
                try {
                  dispatch(updateSec17InterimOrdersAction(values));

                  setSec17InterimOrders(false);

                  setTimeout(() => {
                    dispatch(singleCaseDetailsAction(params));
                  }, 1000);
                } catch (error) {}
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                resetForm,
              }) => {
                return (
                  <div className="d-flex row col-12 mt-2">
                    <form
                      onSubmit={handleSubmit}
                      style={{ boxShadow: "none" }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Accordion
                        expanded={expanded === "Section 17 - Interim Orders"}
                        onClick={handleChange("Section 17 - Interim Orders")}
                      >
                        <AccordionSummary
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "end",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className="m-0 "
                            >
                              Section 17 - Interim Orders
                            </Typography>

                            <div>
                              <Button
                                className="mx-2"
                                type="button"
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setSec17InterimOrders(false);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                // color="primary"
                                variant="contained"
                                onClick={async () => {}}
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Box
                                xs={12}
                                sm={12}
                                md={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "end",
                                  backgroundColor: "white",
                                  flexWrap: "wrap",
                                  alignItems: "stretch",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <InputLabel
                                    htmlFor="sec17ApplicationForInterimOrderFiled"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Application for Interim Order Filed
                                  </InputLabel>

                                  <Select
                                    labelId="sec17ApplicationForInterimOrderFiled"
                                    id="sec17ApplicationForInterimOrderFiled"
                                    name="sec17ApplicationForInterimOrderFiled"
                                    value={
                                      values?.sec17ApplicationForInterimOrderFiled
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                  >
                                    <MenuItem value={1} defaultValue>
                                      Yes
                                    </MenuItem>
                                    <MenuItem value={0} defaultValue>
                                      No
                                    </MenuItem>
                                  </Select>
                                </Box>

                                {values?.sec17ApplicationForInterimOrderFiled ==
                                  1 && (
                                  <FieldArray
                                    name="sec17InterimOrders"
                                    render={(arrayHelpers) => (
                                      <>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "end",
                                            justifyContent: "end",
                                            px: 2,
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                              md: "1fr",
                                            },
                                            width: {
                                              xs: "100%",
                                              sm: "100%",
                                              md: "100%",
                                            },
                                          }}
                                          xs={12}
                                          sm={12}
                                          md={12}
                                        >
                                          <Button
                                            color="primary"
                                            onClick={() => {
                                              setEditIndex(null);
                                              setOpenModel(true);
                                            }}
                                            disabled={
                                              values?.sec17InterimOrders
                                                ?.length == 8
                                            }
                                            className="justify-content-end"
                                          >
                                            Add Interim Order
                                          </Button>
                                        </Box>
                                        <IntrimOrder
                                          values={values}
                                          setFieldValue={setFieldValue}
                                          handleBlur={handleBlur}
                                          handleChange={handleChange}
                                          settings={settings}
                                          arrayHelpers={arrayHelpers}
                                          setOpenModel={setOpenModel}
                                          openModel={openModel}
                                          editIndex={editIndex}
                                          setEditIndex={setEditIndex}
                                        />

                                        <Box
                                          sx={{
                                            display: "grid",
                                            // px: 2,
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                              md: "1fr",
                                            },
                                            width: {
                                              xs: "100%",
                                              sm: "100%",
                                              md: "100%",
                                            },
                                          }}
                                          xs={12}
                                          sm={12}
                                          md={12}
                                        >
                                          {values.sec17InterimOrders?.length >
                                            0 && (
                                            <Box className="d-flex col-12">
                                              <Table striped bordered hover>
                                                <thead>
                                                  <tr>
                                                    <th>Intrim Order</th>
                                                    <th>
                                                      Application for Interim
                                                      Order Filed On
                                                    </th>
                                                    <th>Interim Order Type</th>
                                                    <th>
                                                      Interim Order Type Desc.,
                                                    </th>
                                                    <th>
                                                      Interim Order Application
                                                      Status
                                                    </th>
                                                    <th>
                                                      Interim Order Passed On
                                                    </th>
                                                    <th>Interim Order Notes</th>
                                                    <th>Action</th>
                                                  </tr>
                                                </thead>

                                                <tbody>
                                                  {values.sec17InterimOrders.map(
                                                    (order, index) => {
                                                      return (
                                                        <tr key={index}>
                                                          <td>{index + 1}</td>
                                                          <td>
                                                            {dayjs(
                                                              order?.sec17ApplicationForInterimOrderFiledOn
                                                            ).format(
                                                              "DD-MM-YYYY"
                                                            )}
                                                          </td>
                                                          <td>
                                                            {
                                                              order?.sec17InterimOrderType
                                                            }
                                                          </td>
                                                          <td>
                                                            {order?.sec17InterimOrderType ==
                                                            "Other"
                                                              ? order?.sec17InterimOrderTypeDescription
                                                              : ""}
                                                          </td>
                                                          <td>
                                                            {
                                                              order?.sec17InterimOrderApplicationStatus
                                                            }
                                                          </td>
                                                          <td>
                                                            {order?.sec17InterimOrderPassedOn ==
                                                            null
                                                              ? ""
                                                              : dayjs(
                                                                  new Date(
                                                                    order.sec17InterimOrderPassedOn
                                                                  )
                                                                ).format(
                                                                  "DD-MM-YYYY"
                                                                )}
                                                          </td>
                                                          <td>
                                                            {
                                                              order?.Sec17InterimOrderNotes
                                                            }
                                                          </td>
                                                          <td>
                                                            <IconButton
                                                              color="primary"
                                                              style={{
                                                                marginTop: "4%",
                                                              }}
                                                              onClick={() => {
                                                                setOpenModel(
                                                                  true
                                                                );
                                                                setEditIndex(
                                                                  index
                                                                );
                                                              }}
                                                            >
                                                              <MdEdit
                                                                style={{
                                                                  fontSize:
                                                                    "24px",
                                                                }}
                                                              />
                                                            </IconButton>{" "}
                                                            <IconButton
                                                              color="error"
                                                              style={{
                                                                marginTop: "4%",
                                                              }}
                                                              onClick={() => {
                                                                arrayHelpers.remove(
                                                                  index
                                                                );
                                                              }}
                                                            >
                                                              <MdDelete
                                                                style={{
                                                                  fontSize:
                                                                    "24px",
                                                                }}
                                                              />
                                                            </IconButton>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                                </tbody>
                                              </Table>
                                            </Box>
                                          )}
                                        </Box>
                                      </>
                                    )}
                                  />
                                )}
                              </Box>

                              {/* Dynamice Data */}
                            </LocalizationProvider>
                          </>
                        </AccordionDetails>
                      </Accordion>
                    </form>
                  </div>
                );
              }}
            </Formik>
          ) : (
            <>
              <Accordion
                className="mt-2"
                expanded={expanded === "Section 17 - Interim Orders"}
                onChange={handleChange("Section 17 - Interim Orders")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      width: "100%",
                      backgroundColor: "white",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="m-0 "
                    >
                      Section 17 - Interim Orders
                    </Typography>

                    {window?.edit && (
                      <div>
                        <Button
                          type="button"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              event.stopPropagation();
                              setSec17InterimOrders(true);
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Application for Interim Order Filed"
                          secondary={
                            rowData?.sec17ApplicationForInterimOrderFiled == 1
                              ? "Yes"
                              : "No"
                          }
                        />
                      </List>
                    </Grid>
                    {rowData?.sec17ApplicationForInterimOrderFiled == 1 && (
                      <>
                        <Box
                          sx={{
                            display: "grid",
                            // px: 2,
                            gridTemplateColumns: {
                              xs: "1fr",
                              sm: "1fr",
                              md: "1fr",
                            },
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "100%",
                            },
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          {JSON.parse(rowData?.sec17InterimOrders)?.length >
                            0 && (
                            <Box className="d-flex col-12">
                              <Table striped bordered hover>
                                <thead>
                                  <tr>
                                    <th>Intrim Order</th>
                                    <th>
                                      Application for Interim Order Filed On
                                    </th>
                                    <th>Interim Order Type</th>
                                    <th>Interim Order Type Desc.,</th>
                                    <th>Interim Order Application Status</th>
                                    <th>Interim Order Passed On</th>
                                    <th>Interim Order Notes</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {JSON.parse(rowData?.sec17InterimOrders).map(
                                    (order, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>
                                            {dayjs(
                                              order?.sec17ApplicationForInterimOrderFiledOn
                                            ).format("DD-MM-YYYY")}
                                          </td>
                                          <td>
                                            {order?.sec17InterimOrderType}
                                          </td>
                                          <td>
                                            {order?.sec17InterimOrderType ==
                                            "Other"
                                              ? order?.sec17InterimOrderTypeDescription
                                              : ""}
                                          </td>
                                          <td>
                                            {
                                              order?.sec17InterimOrderApplicationStatus
                                            }
                                          </td>
                                          <td>
                                            {order?.sec17InterimOrderPassedOn ==
                                            null
                                              ? ""
                                              : dayjs(
                                                  new Date(
                                                    order.sec17InterimOrderPassedOn
                                                  )
                                                ).format("DD-MM-YYYY")}
                                          </td>
                                          <td>
                                            {order?.Sec17InterimOrderNotes}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </Table>
                            </Box>
                          )}
                        </Box>
                      </>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}

          {sec9InterimOrders && expanded === "Section 9 - Interim Orders" ? (
            <Formik
              initialValues={{
                sec9ApplicationForInterimOrderFiled: rowData.caseID
                  ? rowData.sec9ApplicationForInterimOrderFiled
                  : null,
                sec9HighCourtName: rowData.caseID
                  ? rowData.sec9HighCourtName
                  : null,
                sec9HighCourtCaseNumber: rowData.caseID
                  ? rowData.sec9HighCourtCaseNumber
                  : null,
                sec9PetitionFiledBy: rowData.caseID
                  ? rowData.sec9PetitionFiledBy
                  : null,
                sec9PetitionFiledOn: rowData.caseID
                  ? rowData.sec9PetitionFiledOn
                  : null,
                sec9InterimReliefRequestedFor: rowData.caseID
                  ? rowData.sec9InterimReliefRequestedFor
                  : null,
                sec9DateOfHearing: rowData.caseID
                  ? rowData.sec9DateOfHearing
                  : null,
                sec9InterimOrderPassedByHighCourt: rowData.caseID
                  ? rowData.sec9InterimOrderPassedByHighCourt
                  : null,
                sec9HighCourtInterimOrderNumber: rowData.caseID
                  ? rowData.sec9HighCourtInterimOrderNumber
                  : null,
                sec9HighCourtInterimOrderPassedOn: rowData.caseID
                  ? rowData.sec9HighCourtInterimOrderPassedOn
                  : null,
                sec9TypeOfInterimReliefGranted: rowData.caseID
                  ? rowData.sec9TypeOfInterimReliefGranted
                  : null,
                sec9HighCourtOrderStatus: rowData.caseID
                  ? rowData.sec9HighCourtOrderStatus
                  : null,
                sec9EnforcementofHighCourtOrderInitiated: rowData.caseID
                  ? rowData.sec9EnforcementofHighCourtOrderInitiated
                  : null,
                sec9EnforcementDate: rowData.caseID
                  ? rowData.sec9EnforcementDate
                  : null,
                sec9OppositionToInterimOrderFiled: rowData.caseID
                  ? rowData.sec9OppositionToInterimOrderFiled
                  : null,
                sec9InterimOrderNotes: rowData.caseID
                  ? rowData.sec9InterimOrderNotes
                  : null,
                params,
              }}
              onSubmit={async (values, actions) => {
                try {
                  dispatch(updateSec9InterimOrdersAction(values));

                  setSec9InterimOrders(false);

                  setTimeout(() => {
                    dispatch(singleCaseDetailsAction(params));
                  }, 1000);
                } catch (error) {}
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                resetForm,
              }) => {
                return (
                  <div className="d-flex row col-12 mt-2 m-0 p-0">
                    <form
                      onSubmit={handleSubmit}
                      style={{ boxShadow: "none" }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Accordion
                        expanded={expanded === "Section 9 - Interim Orders"}
                        onChange={handleChange("Section 9 - Interim Orders")}
                      >
                        <AccordionSummary
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "end",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className="m-0 "
                            >
                              Section 9 – Interim Orders
                            </Typography>

                            <div>
                              <Button
                                className="mx-2"
                                type="button"
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setSec9InterimOrders(false);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                // color="primary"
                                variant="contained"
                                onClick={async () => {}}
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Box
                                xs={12}
                                sm={12}
                                md={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "end",
                                  backgroundColor: "white",
                                  flexWrap: "wrap",
                                  alignItems: "stretch",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <InputLabel
                                    htmlFor="sec9ApplicationForInterimOrderFiled"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Application for Interim Order Filed
                                  </InputLabel>

                                  <Select
                                    labelId="sec9ApplicationForInterimOrderFiled"
                                    id="sec9ApplicationForInterimOrderFiled"
                                    name="sec9ApplicationForInterimOrderFiled"
                                    value={
                                      values?.sec9ApplicationForInterimOrderFiled
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                  >
                                    <MenuItem value={1} defaultValue>
                                      Yes
                                    </MenuItem>
                                    <MenuItem value={0} defaultValue>
                                      No
                                    </MenuItem>
                                  </Select>
                                </Box>
                                {values?.sec9ApplicationForInterimOrderFiled ==
                                  1 && (
                                  <>
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        High Court Name
                                      </Typography>

                                      <OutlinedInput
                                        id="sec9HighCourtName"
                                        name="sec9HighCourtName"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={values?.sec9HighCourtName}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        High Court Case Number
                                      </Typography>

                                      <OutlinedInput
                                        id="sec9HighCourtCaseNumber"
                                        name="sec9HighCourtCaseNumber"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={values?.sec9HighCourtCaseNumber}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Petition Filed By
                                      </Typography>

                                      <OutlinedInput
                                        id="sec9PetitionFiledBy"
                                        name="sec9PetitionFiledBy"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={values?.sec9PetitionFiledBy}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{
                                          marginRight: 2,
                                          textWrap: "balance",
                                        }}
                                      >
                                        Petition Filed On
                                      </Typography>

                                      <DatePicker
                                        id="sec9PetitionFiledOn"
                                        selected={values?.sec9PetitionFiledOn}
                                        onChange={(date) => {
                                          setFieldValue(
                                            "sec9PetitionFiledOn",
                                            date
                                          );
                                        }}
                                        sx={{
                                          width: "100%",
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="DD-MM-YYYY"
                                        className="form-control"
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Interim Relief Requested For
                                      </Typography>

                                      <OutlinedInput
                                        id="sec9InterimReliefRequestedFor"
                                        name="sec9InterimReliefRequestedFor"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={
                                          values?.sec9InterimReliefRequestedFor
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Date of Hearing
                                      </Typography>

                                      <OutlinedInput
                                        id="sec9DateOfHearing"
                                        name="sec9DateOfHearing"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={values?.sec9DateOfHearing}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="sec9InterimOrderPassedByHighCourt"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Interim Order Passed by High Court
                                      </InputLabel>

                                      <Select
                                        labelId="sec9InterimOrderPassedByHighCourt"
                                        id="sec9InterimOrderPassedByHighCourt"
                                        name="sec9InterimOrderPassedByHighCourt"
                                        value={
                                          values?.sec9InterimOrderPassedByHighCourt
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                      >
                                        <MenuItem value={1} defaultValue>
                                          Yes
                                        </MenuItem>
                                        <MenuItem value={0} defaultValue>
                                          No
                                        </MenuItem>
                                      </Select>
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        High Court Interim Order Number
                                      </Typography>

                                      <OutlinedInput
                                        id="sec9HighCourtInterimOrderNumber"
                                        name="sec9HighCourtInterimOrderNumber"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={
                                          values?.sec9HighCourtInterimOrderNumber
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{
                                          marginRight: 2,
                                          textWrap: "balance",
                                        }}
                                      >
                                        High Court Interim Order Passed On
                                      </Typography>
                                      <DatePicker
                                        id="sec9HighCourtInterimOrderPassedOn"
                                        selected={
                                          values?.sec9HighCourtInterimOrderPassedOn
                                        }
                                        onChange={(date) => {
                                          setFieldValue(
                                            "sec9HighCourtInterimOrderPassedOn",
                                            date
                                          );
                                        }}
                                        sx={{
                                          width: "100%",
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="DD-MM-YYYY"
                                        className="form-control"
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Type of Interim Relief Granted
                                      </Typography>

                                      <OutlinedInput
                                        id="sec9TypeOfInterimReliefGranted"
                                        name="sec9TypeOfInterimReliefGranted"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={
                                          values?.sec9TypeOfInterimReliefGranted
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="sec9HighCourtOrderStatus"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        High Court Order Status
                                      </InputLabel>

                                      <Select
                                        labelId="sec9HighCourtOrderStatus"
                                        id="sec9HighCourtOrderStatus"
                                        name="sec9HighCourtOrderStatus"
                                        value={values?.sec9HighCourtOrderStatus}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                      >
                                        {settings
                                          ?.find(
                                            (item) =>
                                              item?.categoryName ===
                                              "High Court Order Status"
                                          )
                                          ?.Children?.map((data, index) => (
                                            <MenuItem
                                              key={index}
                                              value={data?.generalSettingsID}
                                            >
                                              {data?.categoryName}
                                            </MenuItem>
                                          ))}
                                      </Select>
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="sec9EnforcementofHighCourtOrderInitiated"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Enforcement of High Court Order
                                        Initiated
                                      </InputLabel>

                                      <Select
                                        labelId="sec9EnforcementofHighCourtOrderInitiated"
                                        id="sec9EnforcementofHighCourtOrderInitiated"
                                        name="sec9EnforcementofHighCourtOrderInitiated"
                                        value={
                                          values?.sec9EnforcementofHighCourtOrderInitiated
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                      >
                                        <MenuItem value={1} defaultValue>
                                          Yes
                                        </MenuItem>
                                        <MenuItem value={0} defaultValue>
                                          No
                                        </MenuItem>
                                      </Select>
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{
                                          marginRight: 2,
                                          textWrap: "balance",
                                        }}
                                      >
                                        Enforcement Date
                                      </Typography>
                                      <DatePicker
                                        id="sec9EnforcementDate"
                                        selected={values?.sec9EnforcementDate}
                                        onChange={(date) => {
                                          setFieldValue(
                                            "sec9EnforcementDate",
                                            date
                                          );
                                        }}
                                        sx={{
                                          width: "100%",
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="DD-MM-YYYY"
                                        className="form-control"
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="sec9OppositionToInterimOrderFiled"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Opposition to Interim Order Filed
                                      </InputLabel>

                                      <Select
                                        labelId="sec9OppositionToInterimOrderFiled"
                                        id="sec9OppositionToInterimOrderFiled"
                                        name="sec9OppositionToInterimOrderFiled"
                                        value={
                                          values?.sec9OppositionToInterimOrderFiled
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                      >
                                        <MenuItem value={1} defaultValue>
                                          Yes
                                        </MenuItem>
                                        <MenuItem value={0} defaultValue>
                                          No
                                        </MenuItem>
                                      </Select>
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Interim Order Notes
                                      </Typography>

                                      <OutlinedInput
                                        id="sec9InterimOrderNotes"
                                        name="sec9InterimOrderNotes"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={values?.sec9InterimOrderNotes}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>
                                  </>
                                )}
                              </Box>
                            </LocalizationProvider>
                          </>
                        </AccordionDetails>
                      </Accordion>
                    </form>
                  </div>
                );
              }}
            </Formik>
          ) : (
            <>
              <Accordion
                className="mt-2"
                expanded={expanded === "Section 9 - Interim Orders"}
                onChange={handleChange("Section 9 - Interim Orders")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      width: "100%",
                      backgroundColor: "white",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="m-0 "
                    >
                      Section 9 - Interim Orders
                    </Typography>

                    {window?.edit && (
                      <div>
                        <Button
                          type="button"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              handleChange("Section 9 - Interim Orders");
                              event.stopPropagation();
                              setSec9InterimOrders(true);
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Application for Interim Order Filed"
                          secondary={
                            rowData?.sec9ApplicationForInterimOrderFiled == 1
                              ? "Yes"
                              : "No"
                          }
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="High Court Name"
                          secondary={rowData?.sec9HighCourtName}
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="High Court Case Number"
                          secondary={rowData?.sec9HighCourtCaseNumber}
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Petition Filed By"
                          secondary={rowData?.sec9PetitionFiledBy}
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Petition Filed On"
                          secondary={
                            rowData?.sec9PetitionFiledOn
                              ? trackerFormatDate(
                                  new Date(rowData?.sec9PetitionFiledOn)
                                )
                              : "DD-MM-YYYY"
                          }
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Interim Relief Requested For"
                          secondary={rowData?.sec9InterimReliefRequestedFor}
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Date of Hearing"
                          secondary={rowData?.sec9DateOfHearing}
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Interim Order Passed by High Court"
                          secondary={
                            rowData?.sec9InterimOrderPassedByHighCourt == 1
                              ? "Yes"
                              : "No"
                          }
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="High Court Interim Order Number"
                          secondary={rowData?.sec9HighCourtInterimOrderNumber}
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="High Court Interim Order Passed On"
                          secondary={
                            rowData?.sec9HighCourtInterimOrderPassedOn
                              ? trackerFormatDate(
                                  new Date(
                                    rowData?.sec9HighCourtInterimOrderPassedOn
                                  )
                                )
                              : "DD-MM-YYYY"
                          }
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Type of Interim Relief Granted"
                          secondary={rowData?.sec9TypeOfInterimReliefGranted}
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="High Court Order Status"
                          secondary={
                            rowData?.sec9HighCourtOrderStatusID?.categoryName
                          }
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Enforcement of High Court Order Initiated"
                          secondary={
                            rowData?.sec9EnforcementofHighCourtOrderInitiated ==
                            1
                              ? "Yes"
                              : "No"
                          }
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Enforcement Date"
                          secondary={
                            rowData?.sec9EnforcementDate
                              ? trackerFormatDate(
                                  new Date(rowData?.sec9EnforcementDate)
                                )
                              : "DD-MM-YYYY"
                          }
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Opposition to Interim Order Filed"
                          secondary={
                            rowData?.sec9OppositionToInterimOrderFiled == 1
                              ? "Yes"
                              : "No"
                          }
                        />
                      </List>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Interim Order Notes"
                          secondary={rowData?.sec9InterimOrderNotes}
                        />
                      </List>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}

          {interimOrders && expanded === "Interim Award" ? (
            <Formik
              initialValues={{
                appForInterimAwardFiled: rowData?.caseID
                  ? rowData?.AppForInterimAwardFiled
                  : null,
                appForInterimAwardFieldOn: rowData?.caseID
                  ? rowData?.AppForInterimAwardFieldOn
                  : null,
                interimAwardHearingDate: rowData?.caseID
                  ? rowData?.interimAwardHearingDate
                  : null,
                statusOfAppForInterimAwardField: rowData?.caseID
                  ? rowData?.StatusOfAppForInterimAwardField
                  : null,
                interimAwardPassedOn: rowData?.caseID
                  ? rowData?.InterimAwardPassedOn
                  : null,
                interimAwardInFavourOf: rowData?.caseID
                  ? rowData?.interimAwardInFavourOf
                  : null,
                interimAwardType: rowData?.caseID
                  ? rowData?.interimAwardType
                  : null,
                interimAwardTypeDesc: rowData?.caseID
                  ? rowData?.interimAwardTypeDesc
                  : null,
                interimAwardAmount: rowData?.caseID
                  ? rowData?.interimAwardAmount
                  : null,
                interimAwardCurrency: rowData?.caseID
                  ? rowData?.interimAwardCurrency
                  : null,
                interimAwardSummary: rowData?.caseID
                  ? rowData?.interimAwardSummary
                  : null,
                interimAwardNotes: rowData?.caseID
                  ? rowData?.InterimAwardNotes
                  : null,
                params,
                user,
              }}
              onSubmit={async (values, actions) => {
                try {
                  dispatch(updateInterimOrdersAction(values));

                  setInterimOrders(false);

                  setTimeout(() => {
                    dispatch(singleCaseDetailsAction(params));
                  }, 1000);
                } catch (error) {}
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                resetForm,
              }) => {
                return (
                  <div className="d-flex row col-12 px-0 m-0 mt-2">
                    <form
                      onSubmit={handleSubmit}
                      style={{ boxShadow: "none" }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Accordion
                        expanded={expanded === "Interim Award"}
                        onChange={handleChange("Interim Award")}
                      >
                        <AccordionSummary
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "end",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className="m-0 "
                            >
                              Interim Award
                            </Typography>

                            <div>
                              <Button
                                className="mx-2"
                                type="button"
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setInterimOrders(false);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                // color="primary"
                                variant="contained"
                                onClick={async () => {}}
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Box
                                xs={12}
                                sm={12}
                                md={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "end",
                                  backgroundColor: "white",
                                  flexWrap: "wrap",
                                  alignItems: "stretch",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <InputLabel
                                    htmlFor="appForInterimAwardFiled"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Application for Interim Order Filed
                                  </InputLabel>

                                  <Select
                                    labelId="appForInterimAwardFiled"
                                    id="appForInterimAwardFiled"
                                    name="appForInterimAwardFiled"
                                    value={values?.appForInterimAwardFiled}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                  >
                                    <MenuItem value={1} defaultValue>
                                      Yes
                                    </MenuItem>
                                    <MenuItem value={0} defaultValue>
                                      No
                                    </MenuItem>
                                  </Select>
                                </Box>

                                {values?.appForInterimAwardFiled == 1 && (
                                  <>
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{
                                          marginRight: 2,
                                          textWrap: "balance",
                                        }}
                                      >
                                        Application for Interim Award Field On
                                      </Typography>

                                      <DatePicker
                                        id="appForInterimAwardFieldOn"
                                        selected={
                                          values?.appForInterimAwardFieldOn
                                        }
                                        onChange={(date) => {
                                          setFieldValue(
                                            "appForInterimAwardFieldOn",
                                            date
                                          );
                                        }}
                                        sx={{
                                          width: "100%",
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="DD-MM-YYYY"
                                        className="form-control"
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{
                                          marginRight: 2,
                                          textWrap: "balance",
                                        }}
                                      >
                                        Interim Award Hearing Date
                                      </Typography>

                                      <DatePicker
                                        id="interimAwardHearingDate"
                                        selected={
                                          values?.interimAwardHearingDate
                                        }
                                        onChange={(date) => {
                                          setFieldValue(
                                            "interimAwardHearingDate",
                                            date
                                          );
                                        }}
                                        sx={{
                                          width: "100%",
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="DD-MM-YYYY"
                                        className="form-control"
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="statusOfAppForInterimAwardField"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Interim Awad Status
                                      </InputLabel>

                                      <Select
                                        labelId="statusOfAppForInterimAwardField"
                                        id="statusOfAppForInterimAwardField"
                                        name="statusOfAppForInterimAwardField"
                                        value={
                                          values?.statusOfAppForInterimAwardField
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                      >
                                        {settings
                                          ?.find(
                                            (item) =>
                                              item?.categoryName ==
                                              "Interim Award Status"
                                          )
                                          ?.Children?.map((data, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={data?.generalSettingsID}
                                              >
                                                {data?.categoryName}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    </Box>

                                    {settings
                                      ?.find(
                                        (item) =>
                                          item?.categoryName ===
                                          "Interim Award Status"
                                      )
                                      ?.Children?.filter(
                                        (data) =>
                                          data.generalSettingsID ==
                                          values?.statusOfAppForInterimAwardField
                                      )
                                      ?.some(
                                        (data) =>
                                          data.categoryName ===
                                            "Fully Granted" ||
                                          data.categoryName ===
                                            "Partially Granted"
                                      ) && (
                                      <>
                                        <Box
                                          sx={{
                                            display: "grid",
                                            alignItems: "end",
                                            px: 2,
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                              md: "1fr",
                                            },
                                            width: {
                                              xs: "100%",
                                              sm: "50%",
                                              md: "50%",
                                            },
                                          }}
                                          xs={12}
                                          sm={6}
                                          md={6}
                                        >
                                          <Typography
                                            variant="body1"
                                            component="label"
                                            sx={{
                                              marginRight: 2,
                                              textWrap: "balance",
                                            }}
                                          >
                                            Interim Award Passed On
                                          </Typography>

                                          <DatePicker
                                            id="interimAwardPassedOn"
                                            selected={
                                              values?.interimAwardPassedOn
                                            }
                                            onChange={(date) => {
                                              setFieldValue(
                                                "interimAwardPassedOn",
                                                date
                                              );
                                            }}
                                            sx={{
                                              width: "100%",
                                              gridTemplateColumns: {
                                                xs: "1fr",
                                                sm: "1fr",
                                              },
                                            }}
                                            onBlur={handleBlur}
                                            dateFormat="dd-MM-yyyy"
                                            placeholderText="DD-MM-YYYY"
                                            className="form-control"
                                          />
                                        </Box>

                                        <Box
                                          sx={{
                                            display: "grid",
                                            alignItems: "end",
                                            px: 2,
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                              md: "1fr",
                                            },
                                            width: {
                                              xs: "100%",
                                              sm: "50%",
                                              md: "50%",
                                            },
                                          }}
                                          xs={12}
                                          sm={6}
                                          md={6}
                                        >
                                          <InputLabel
                                            htmlFor="interimAwardInFavourOf"
                                            sx={{ textWrap: "balance" }}
                                          >
                                            Interim Award in Favour Of
                                          </InputLabel>

                                          <Select
                                            labelId="interimAwardInFavourOf"
                                            id="interimAwardInFavourOf"
                                            name="interimAwardInFavourOf"
                                            value={
                                              values?.interimAwardInFavourOf
                                            }
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                          >
                                            {settings
                                              ?.find(
                                                (item) =>
                                                  item?.categoryName ==
                                                  "Interim Award in Favour Of"
                                              )
                                              ?.Children?.map((data, index) => {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={
                                                      data?.generalSettingsID
                                                    }
                                                  >
                                                    {data?.categoryName}
                                                  </MenuItem>
                                                );
                                              })}
                                          </Select>
                                        </Box>

                                        <Box
                                          sx={{
                                            display: "grid",
                                            alignItems: "end",
                                            px: 2,
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                              md: "1fr",
                                            },
                                            width: {
                                              xs: "100%",
                                              sm: "50%",
                                              md: "50%",
                                            },
                                          }}
                                          xs={12}
                                          sm={6}
                                          md={6}
                                        >
                                          <InputLabel
                                            htmlFor="interimAwardType"
                                            sx={{ textWrap: "balance" }}
                                          >
                                            Interim Award Type
                                          </InputLabel>

                                          <Select
                                            labelId="interimAwardType"
                                            id="interimAwardType"
                                            name="interimAwardType"
                                            value={values?.interimAwardType}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                          >
                                            {settings
                                              ?.find(
                                                (item) =>
                                                  item?.categoryName ==
                                                  "Interim Award Type"
                                              )
                                              ?.Children?.map((data, index) => {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={
                                                      data?.generalSettingsID
                                                    }
                                                  >
                                                    {data?.categoryName}
                                                  </MenuItem>
                                                );
                                              })}
                                          </Select>
                                        </Box>

                                        {settings
                                          ?.find(
                                            (item) =>
                                              item?.categoryName ===
                                              "Interim Award Type"
                                          )
                                          ?.Children?.filter(
                                            (data) =>
                                              data.generalSettingsID ==
                                              values?.interimAwardType
                                          )
                                          ?.some(
                                            (data) =>
                                              data.categoryName === "Other"
                                          ) && (
                                          <Box
                                            sx={{
                                              display: "grid",
                                              alignItems: "end",
                                              px: 2,
                                              gridTemplateColumns: {
                                                xs: "1fr",
                                                sm: "1fr",
                                                md: "1fr",
                                              },
                                              width: {
                                                xs: "100%",
                                                sm: "50%",
                                                md: "50%",
                                              },
                                            }}
                                            xs={12}
                                            sm={6}
                                            md={6}
                                          >
                                            <Typography
                                              variant="body1"
                                              component="label"
                                              sx={{ textWrap: "balance" }}
                                            >
                                              Interim Award Type Description
                                            </Typography>

                                            <OutlinedInput
                                              id="interimAwardTypeDesc"
                                              name="interimAwardTypeDesc"
                                              sx={{
                                                gridTemplateColumns: {
                                                  xs: "1fr",
                                                  sm: "1fr",
                                                },
                                              }}
                                              value={
                                                values?.interimAwardTypeDesc
                                              }
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                            />
                                          </Box>
                                        )}

                                        <Box
                                          sx={{
                                            display: "grid",
                                            alignItems: "end",
                                            px: 2,
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                              md: "1fr",
                                            },
                                            width: {
                                              xs: "100%",
                                              sm: "50%",
                                              md: "50%",
                                            },
                                          }}
                                          xs={12}
                                          sm={6}
                                          md={6}
                                        >
                                          <Typography
                                            variant="body1"
                                            component="label"
                                            sx={{ textWrap: "balance" }}
                                          >
                                            Interim Award Amount
                                          </Typography>

                                          <OutlinedInput
                                            id="interimAwardAmount"
                                            name="interimAwardAmount"
                                            sx={{
                                              gridTemplateColumns: {
                                                xs: "1fr",
                                                sm: "1fr",
                                              },
                                            }}
                                            value={values?.interimAwardAmount}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                          />
                                        </Box>

                                        <Box
                                          sx={{
                                            display: "grid",
                                            alignItems: "end",
                                            px: 2,
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                              md: "1fr",
                                            },
                                            width: {
                                              xs: "100%",
                                              sm: "50%",
                                              md: "50%",
                                            },
                                          }}
                                          xs={12}
                                          sm={6}
                                          md={6}
                                        >
                                          <InputLabel
                                            htmlFor="interimAwardCurrency"
                                            sx={{ textWrap: "balance" }}
                                          >
                                            Interim Award Currency
                                          </InputLabel>

                                          <Select
                                            labelId="interimAwardCurrency"
                                            id="interimAwardCurrency"
                                            name="interimAwardCurrency"
                                            value={values?.interimAwardCurrency}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            fullWidth
                                          >
                                            {settings
                                              ?.find(
                                                (item) =>
                                                  item?.categoryName ==
                                                  "Interim Award Currency"
                                              )
                                              ?.Children?.map((data, index) => {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={
                                                      data?.generalSettingsID
                                                    }
                                                  >
                                                    {data?.categoryName}
                                                  </MenuItem>
                                                );
                                              })}
                                          </Select>
                                        </Box>

                                        <Box
                                          sx={{
                                            display: "grid",
                                            alignItems: "end",
                                            px: 2,
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                              md: "1fr",
                                            },
                                            width: {
                                              xs: "100%",
                                              sm: "50%",
                                              md: "50%",
                                            },
                                          }}
                                          xs={12}
                                          sm={6}
                                          md={6}
                                        >
                                          <Typography
                                            variant="body1"
                                            component="label"
                                            sx={{ textWrap: "balance" }}
                                          >
                                            Interim Award Summary
                                          </Typography>

                                          <OutlinedInput
                                            id="interimAwardSummary"
                                            name="interimAwardSummary"
                                            sx={{
                                              gridTemplateColumns: {
                                                xs: "1fr",
                                                sm: "1fr",
                                              },
                                            }}
                                            value={values?.interimAwardSummary}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                          />
                                        </Box>

                                        <Box
                                          sx={{
                                            display: "grid",
                                            alignItems: "end",
                                            px: 2,
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                              md: "1fr",
                                            },
                                            width: {
                                              xs: "100%",
                                              sm: "50%",
                                              md: "50%",
                                            },
                                          }}
                                          xs={12}
                                          sm={6}
                                          md={6}
                                        >
                                          <Typography
                                            variant="body1"
                                            component="label"
                                            sx={{ textWrap: "balance" }}
                                          >
                                            Interim l Award Notes
                                          </Typography>

                                          <OutlinedInput
                                            id="interimAwardNotes"
                                            name="interimAwardNotes"
                                            sx={{
                                              gridTemplateColumns: {
                                                xs: "1fr",
                                                sm: "1fr",
                                              },
                                            }}
                                            value={values?.interimAwardNotes}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                          />
                                        </Box>
                                      </>
                                    )}
                                  </>
                                )}
                              </Box>
                            </LocalizationProvider>
                          </>
                        </AccordionDetails>
                      </Accordion>
                    </form>
                  </div>
                );
              }}
            </Formik>
          ) : (
            <>
              <Accordion
                className="mt-2"
                expanded={expanded === "Interim Award"}
                onChange={handleChange("Interim Award")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      width: "100%",
                      backgroundColor: "white",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="m-0 "
                    >
                      Interim Awards
                    </Typography>

                    {window?.edit && (
                      <div>
                        <Button
                          type="button"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              handleChange("Interim Awards");
                              event.stopPropagation();
                              setInterimOrders(true);
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Application for Interim Order Filed"
                          secondary={
                            rowData?.AppForInterimAwardFiled == 1 ? "Yes" : "No"
                          }
                        />
                      </List>
                    </Grid>
                    {rowData?.AppForInterimAwardFiled == 1 && (
                      <>
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Application for Interim Award Field On"
                              secondary={
                                rowData?.AppForInterimAwardFieldOn
                                  ? trackerFormatDate(
                                      new Date(
                                        rowData?.AppForInterimAwardFieldOn
                                      )
                                    )
                                  : "DD-MM-YYYY"
                              }
                            />
                          </List>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Interim Award Hearing Date"
                              secondary={
                                rowData?.interimAwardHearingDate
                                  ? trackerFormatDate(
                                      new Date(rowData?.interimAwardHearingDate)
                                    )
                                  : "DD-MM-YYYY"
                              }
                            />
                          </List>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Interim Award Status"
                              secondary={
                                rowData?.statusOfAppForInterimAwardFieldID
                                  ?.categoryName
                              }
                            />
                          </List>
                        </Grid>
                        {(rowData?.statusOfAppForInterimAwardFieldID
                          ?.categoryName === "Fully Granted" ||
                          rowData?.statusOfAppForInterimAwardFieldID
                            ?.categoryName === "Partially Granted") && (
                          <>
                            <Grid item xs={12} sm={6} md={6} px={2}>
                              <List sx={{ bgcolor: "background.paper" }}>
                                <ListItemText
                                  primary="Interim Award Passed On"
                                  secondary={
                                    rowData?.InterimAwardPassedOn
                                      ? trackerFormatDate(
                                          new Date(
                                            rowData?.InterimAwardPassedOn
                                          )
                                        )
                                      : "DD-MM-YYYY"
                                  }
                                />
                              </List>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} px={2}>
                              <List sx={{ bgcolor: "background.paper" }}>
                                <ListItemText
                                  primary="Interim Award in Favour Of"
                                  secondary={
                                    rowData?.interimAwardInFavourOfID
                                      ?.categoryName
                                  }
                                />
                              </List>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} px={2}>
                              <List sx={{ bgcolor: "background.paper" }}>
                                <ListItemText
                                  primary="Interim Award Type"
                                  secondary={
                                    rowData?.interimAwardTypeID?.categoryName
                                  }
                                />
                              </List>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} px={2}>
                              <List sx={{ bgcolor: "background.paper" }}>
                                <ListItemText
                                  primary="Interim Award Amount"
                                  secondary={rowData?.interimAwardAmount}
                                />
                              </List>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} px={2}>
                              <List sx={{ bgcolor: "background.paper" }}>
                                <ListItemText
                                  primary="Interim Award Currency"
                                  secondary={
                                    rowData?.interimAwardCurrencyID
                                      ?.categoryName
                                  }
                                />
                              </List>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} px={2}>
                              <List sx={{ bgcolor: "background.paper" }}>
                                <ListItemText
                                  primary="Interim Award Summary"
                                  secondary={rowData?.interimAwardSummary}
                                />
                              </List>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} px={2}>
                              <List sx={{ bgcolor: "background.paper" }}>
                                <ListItemText
                                  primary="Interim l Award Notes"
                                  secondary={rowData?.InterimAwardNotes}
                                />
                              </List>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}

          {finalAward && expanded === "Final Award" ? (
            <Formik
              initialValues={{
                finalAwardPassed: rowData?.caseID
                  ? rowData?.finalAwardPassed
                  : null,
                finalAwardPassedOn: rowData?.caseID
                  ? rowData?.finalAwardPassedOn
                  : null,
                finalAwardReferenceNumber: rowData?.caseID
                  ? rowData?.finalAwardReferenceNumber
                  : null,
                finalAwardInFavourOf: rowData?.caseID
                  ? rowData?.finalAwardInFavourOf
                  : null,
                finalAwardType: rowData?.caseID
                  ? rowData?.finalAwardType
                  : null,
                finalAwardTypeDescription: rowData?.caseID
                  ? rowData?.finalAwardTypeDescription
                  : null,
                finalAwardAmount: rowData?.caseID
                  ? rowData?.finalAwardAmount
                  : null,
                finalAwardCurrency: rowData?.caseID
                  ? rowData?.finalAwardCurrency
                  : null,
                finalAwardSummary: rowData?.caseID
                  ? rowData?.finalAwardSummary
                  : null,
                finalAwardNotes: rowData?.caseID
                  ? rowData?.finalAwardNotes
                  : null,
                finalAwardPassedExParte: rowData?.caseID
                  ? rowData?.finalAwardPassedExParte
                  : null,
                params,
                user,
              }}
              onSubmit={async (values, actions) => {
                try {
                  dispatch(updateFinalAwardAction(values));

                  setFinalAward(false);

                  setTimeout(() => {
                    dispatch(singleCaseDetailsAction(params));
                  }, 1000);
                } catch (error) {}
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
                resetForm,
              }) => {
                return (
                  <div className="d-flex row p-0 m-0 col-12 mt-2">
                    <form
                      onSubmit={handleSubmit}
                      style={{ boxShadow: "none" }}
                      xs={12}
                      sm={12}
                      md={12}
                    >
                      <Accordion
                        expanded={expanded === "Final Award"}
                        onChange={handleChange("Final Award")}
                      >
                        <AccordionSummary
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "end",
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                              className="m-0 "
                            >
                              Final Award
                            </Typography>

                            <div>
                              <Button
                                className="mx-2"
                                type="button"
                                variant="contained"
                                color="secondary"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setFinalAward(false);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                // color="primary"
                                variant="contained"
                                onClick={async () => {}}
                                type="submit"
                              >
                                Submit
                              </Button>
                            </div>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Box
                                xs={12}
                                sm={12}
                                md={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "end",
                                  backgroundColor: "white",
                                  flexWrap: "wrap",
                                  alignItems: "stretch",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "grid",
                                    alignItems: "end",
                                    px: 2,
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                      md: "1fr",
                                    },
                                    width: {
                                      xs: "100%",
                                      sm: "50%",
                                      md: "50%",
                                    },
                                  }}
                                  xs={12}
                                  sm={6}
                                  md={6}
                                >
                                  <InputLabel
                                    htmlFor="finalAwardPassed"
                                    sx={{ textWrap: "balance" }}
                                  >
                                    Final Award Passed
                                  </InputLabel>

                                  <Select
                                    labelId="finalAwardPassed"
                                    id="finalAwardPassed"
                                    name="finalAwardPassed"
                                    value={values?.finalAwardPassed}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    fullWidth
                                  >
                                    <MenuItem value={1} defaultValue>
                                      Yes
                                    </MenuItem>
                                    <MenuItem value={0} defaultValue>
                                      No
                                    </MenuItem>
                                  </Select>
                                </Box>
                                {values?.finalAwardPassed == 1 && (
                                  <>
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{
                                          marginRight: 2,
                                          textWrap: "balance",
                                        }}
                                      >
                                        Final Award Passed On
                                      </Typography>

                                      <DatePicker
                                        id="finalAwardPassedOn"
                                        selected={values?.finalAwardPassedOn}
                                        onChange={(date) => {
                                          setFieldValue(
                                            "finalAwardPassedOn",
                                            date
                                          );
                                        }}
                                        sx={{
                                          width: "100%",
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="DD-MM-YYYY"
                                        className="form-control"
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Final Award Reference Number
                                      </Typography>

                                      <OutlinedInput
                                        id="finalAwardReferenceNumber"
                                        name="finalAwardReferenceNumber"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={
                                          values?.finalAwardReferenceNumber
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="finalAwardInFavourOf"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Final Award in Favour Of
                                      </InputLabel>

                                      <Select
                                        labelId="finalAwardInFavourOf"
                                        id="finalAwardInFavourOf"
                                        name="finalAwardInFavourOf"
                                        value={values?.finalAwardInFavourOf}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                      >
                                        {settings
                                          ?.find(
                                            (item) =>
                                              item?.categoryName ==
                                              "Interim Award in Favour Of"
                                          )
                                          ?.Children?.map((data, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={data?.generalSettingsID}
                                              >
                                                {data?.categoryName}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="finalAwardType"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Final Award Type
                                      </InputLabel>

                                      <Select
                                        labelId="finalAwardType"
                                        id="finalAwardType"
                                        name="finalAwardType"
                                        value={values?.finalAwardType}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                      >
                                        {settings
                                          ?.find(
                                            (item) =>
                                              item?.categoryName ==
                                              "Interim Award Type"
                                          )
                                          ?.Children?.map((data, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={data?.generalSettingsID}
                                              >
                                                {data?.categoryName}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    </Box>

                                    {settings
                                      ?.find(
                                        (item) =>
                                          item?.categoryName ===
                                          "Interim Award Type"
                                      )
                                      ?.Children?.filter(
                                        (data) =>
                                          data.generalSettingsID ==
                                          values?.finalAwardType
                                      )
                                      ?.some(
                                        (data) => data.categoryName === "Other"
                                      ) && (
                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="label"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Final Award Type Description
                                        </Typography>

                                        <OutlinedInput
                                          id="finalAwardTypeDescription"
                                          name="finalAwardTypeDescription"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.finalAwardTypeDescription
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>
                                    )}

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Final Award Amount
                                      </Typography>

                                      <OutlinedInput
                                        id="finalAwardAmount"
                                        name="finalAwardAmount"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={values?.finalAwardAmount}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="finalAwardCurrency"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Final Award Currency
                                      </InputLabel>

                                      <Select
                                        labelId="finalAwardCurrency"
                                        id="finalAwardCurrency"
                                        name="finalAwardCurrency"
                                        value={values?.finalAwardCurrency}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                      >
                                        {settings
                                          ?.find(
                                            (item) =>
                                              item?.categoryName ==
                                              "Interim Award Currency"
                                          )
                                          ?.Children?.map((data, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={data?.generalSettingsID}
                                              >
                                                {data?.categoryName}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Final Award Summary
                                      </Typography>

                                      <OutlinedInput
                                        id="finalAwardSummary"
                                        name="finalAwardSummary"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={values?.finalAwardSummary}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Final Award Notes
                                      </Typography>

                                      <OutlinedInput
                                        id="finalAwardNotes"
                                        name="finalAwardNotes"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={values?.finalAwardNotes}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>

                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Final Award Passed Ex-Parte?
                                      </Typography>

                                      <Select
                                        labelId="finalAwardPassedExParte"
                                        id="finalAwardPassedExParte"
                                        name="finalAwardPassedExParte"
                                        value={values?.finalAwardPassedExParte}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                      >
                                        <MenuItem value={1} defaultValue>
                                          Yes
                                        </MenuItem>
                                        <MenuItem value={0} defaultValue>
                                          No
                                        </MenuItem>
                                      </Select>
                                    </Box>
                                  </>
                                )}
                              </Box>
                            </LocalizationProvider>
                          </>
                        </AccordionDetails>
                      </Accordion>
                    </form>
                  </div>
                );
              }}
            </Formik>
          ) : (
            <>
              <Accordion
                className="mt-2"
                expanded={expanded === "Final Award"}
                onChange={handleChange("Final Award")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      width: "100%",
                      backgroundColor: "white",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="m-0 "
                    >
                      Final Award
                    </Typography>

                    {window?.edit && (
                      <div>
                        <Button
                          type="button"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              handleChange("Final Award");
                              event.stopPropagation();
                              setFinalAward(true);
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6} px={2}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Final Award Passed"
                          secondary={
                            rowData?.finalAwardPassed == 1 ? "Yes" : "No"
                          }
                        />
                      </List>
                    </Grid>
                    {rowData?.finalAwardPassed == 1 && (
                      <>
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Final Award Passed On"
                              secondary={
                                rowData?.finalAwardPassedOn
                                  ? trackerFormatDate(
                                      new Date(rowData?.finalAwardPassedOn)
                                    )
                                  : "DD-MM-YYYY"
                              }
                            />
                          </List>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Final Award Reference Number"
                              secondary={rowData?.finalAwardReferenceNumber}
                            />
                          </List>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Final Award in Favour Of"
                              secondary={
                                rowData?.finalAwardInFavourOfID?.categoryName
                              }
                            />
                          </List>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Final Award Type"
                              secondary={
                                rowData?.finalAwardTypeID?.categoryName
                              }
                            />
                          </List>
                        </Grid>
                        {settings
                          ?.find(
                            (item) =>
                              item?.categoryName === "Interim Award Type"
                          )
                          ?.Children?.filter(
                            (data) =>
                              data.generalSettingsID == rowData?.finalAwardType
                          )
                          ?.some((data) => data.categoryName === "Other") && (
                          <Grid item xs={12} sm={6} md={6} px={2}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Final Award Type Description"
                                secondary={rowData?.finalAwardTypeDescription}
                              />
                            </List>
                          </Grid>
                        )}

                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Final Award Amount"
                              secondary={rowData?.finalAwardAmount}
                            />
                          </List>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Final Award Currency"
                              secondary={
                                rowData?.finalAwardCurrencyID?.categoryName
                              }
                            />
                          </List>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Final Award Summary"
                              secondary={rowData?.finalAwardSummary}
                            />
                          </List>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Final Award Notes"
                              secondary={rowData?.finalAwardNotes}
                            />
                          </List>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Final Award Passed Ex-Parte? "
                              secondary={
                                rowData?.finalAwardPassedExParte == 1
                                  ? "Yes"
                                  : "No"
                              }
                            />
                          </List>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default ArbitrationInterimOrdersReliefsAwards;
const IntrimOrder = ({
  values,
  setFieldValue,
  handleBlur,
  handleChange,
  settings,
  arrayHelpers,
  setOpenModel,
  openModel,
  editIndex,
  setEditIndex,
}) => {
  const [formData, setFormData] = useState(null);
  const data = values.sec17InterimOrders;

  // Calculate minDate from existing data
  const minDate = data?.length
    ? editIndex !== null
      ? data?.[editIndex]?.sec17ApplicationForInterimOrderFiledOn
        ? new Date(data[editIndex].sec17ApplicationForInterimOrderFiledOn)
        : null
      : new Date(
          Math.max(
            ...data.map((item) =>
              new Date(item.sec17ApplicationForInterimOrderFiledOn).getTime()
            )
          )
        )
    : null;

  // Adjust minDate for adding a new record
  if (minDate && editIndex === null) {
    minDate.setDate(minDate.getDate() + 1);
  }

  // Updating formData based on editIndex
  useEffect(() => {
    if (editIndex >= 0) {
      setFormData(data?.[editIndex]);
    } else {
      setFormData(null);
    }
  }, [editIndex, data]);

  const initialValues = {
    InterimOrder: formData ? formData?.InterimOrder : null,
    sec17ApplicationForInterimOrderFiledOn:
      formData?.sec17ApplicationForInterimOrderFiledOn ?? minDate ?? "",
    sec17InterimOrderType: formData?.sec17InterimOrderType ?? "",
    sec17InterimOrderTypeDescription:
      formData?.sec17InterimOrderType == "Other"
        ? formData?.sec17InterimOrderTypeDescription
        : ("" ?? ""),
    sec17InterimOrderApplicationStatus:
      formData?.sec17InterimOrderApplicationStatus ?? "",
    sec17InterimOrderPassedOn: formData?.sec17InterimOrderPassedOn ?? null,
    Sec17InterimOrderNotes: formData?.Sec17InterimOrderNotes ?? "",
  };

  return (
    <Dialog
      open={openModel}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          width: "50%",
          maxWidth: "100%",
          padding: 16,
        },
      }}
    >
      <Box sx={{ display: "grid", p: 2, width: "100%", height: "100%" }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={Yup.object({
            sec17ApplicationForInterimOrderFiledOn: Yup.date()
              .nullable()
              .required("This date is required.")
              .test(
                "date-validation",
                "Invalid date based on existing entries.",
                (value) => {
                  if (!value) return false;

                  const selectedDate = new Date(value).getTime();

                  return !data.some((item, index) => {
                    const currentItemDate = new Date(
                      item.sec17ApplicationForInterimOrderFiledOn
                    ).getTime();

                    if (editIndex !== null && editIndex === index) {
                      return false;
                    }

                    return minDate
                      ? currentItemDate === selectedDate
                      : currentItemDate === selectedDate;
                  });
                }
              ),

            sec17InterimOrderApplicationStatus: Yup.string().required(
              "Please select a status."
            ),
            sec17InterimOrderPassedOn: Yup.date().nullable(),
            Sec17InterimOrderNotes: Yup.string(),
          })}
          onSubmit={async (values, actions) => {
            try {
              if (editIndex == null) {
                arrayHelpers.push({ ...values });
                setOpenModel(false);
              } else if (editIndex >= 0) {
                arrayHelpers.replace(editIndex, { ...values });
                setOpenModel(false);
              }

              actions.resetForm();

              setOpenModel(false);
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Application for Interim Order Filed On
                </Typography>
                <DatePicker
                  id="sec17ApplicationForInterimOrderFiledOn"
                  selected={values?.sec17ApplicationForInterimOrderFiledOn}
                  onChange={(date) =>
                    setFieldValue(
                      "sec17ApplicationForInterimOrderFiledOn",
                      date
                    )
                  }
                  minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control"
                />
                {touched.sec17ApplicationForInterimOrderFiledOn &&
                  errors.sec17ApplicationForInterimOrderFiledOn && (
                    <Typography color="error">
                      {errors.sec17ApplicationForInterimOrderFiledOn}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="sec17InterimOrderType">
                  Interim Order Type
                </InputLabel>
                <Select
                  labelId="sec17InterimOrderType"
                  id="sec17InterimOrderType"
                  name="sec17InterimOrderType"
                  value={values?.sec17InterimOrderType}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find(
                      (item) =>
                        item?.categoryName === "Section 17 - Interim Order Type"
                    )
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.categoryName}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.sec17InterimOrderType &&
                  errors.sec17InterimOrderType && (
                    <Typography color="error">
                      {errors.sec17InterimOrderType}
                    </Typography>
                  )}
              </Box>

              {values?.sec17InterimOrderType == "Other" && (
                <Box sx={{ display: "grid", mb: 2 }}>
                  <Typography variant="body1" component="label">
                    Interim Order Type Description
                  </Typography>
                  <OutlinedInput
                    id="sec17InterimOrderTypeDescription"
                    name="sec17InterimOrderTypeDescription"
                    value={values?.sec17InterimOrderTypeDescription}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </Box>
              )}

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Interim Order Passed On
                </Typography>
                <DatePicker
                  id="sec17InterimOrderPassedOn"
                  selected={values?.sec17InterimOrderPassedOn}
                  onChange={(date) => {
                    setFieldValue(
                      "sec17InterimOrderPassedOn",
                      date ? date.toISOString() : null
                    );
                  }}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control"
                />
                {touched.sec17InterimOrderPassedOn &&
                  errors.sec17InterimOrderPassedOn && (
                    <Typography color="error">
                      {errors.sec17InterimOrderPassedOn}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="sec17InterimOrderApplicationStatus">
                  Interim Order Application Status
                </InputLabel>
                <Select
                  labelId="sec17InterimOrderApplicationStatus"
                  id="sec17InterimOrderApplicationStatus"
                  name="sec17InterimOrderApplicationStatus"
                  value={values?.sec17InterimOrderApplicationStatus}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find(
                      (item) =>
                        item?.categoryName ===
                        "Section 17 - Interim Order Application Status"
                    )
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.categoryName}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.sec17InterimOrderApplicationStatus &&
                  errors.sec17InterimOrderApplicationStatus && (
                    <Typography color="error">
                      {errors.sec17InterimOrderApplicationStatus}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Interim Order Notes
                </Typography>
                <OutlinedInput
                  id="Sec17InterimOrderNotes"
                  name="Sec17InterimOrderNotes"
                  value={values?.Sec17InterimOrderNotes}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.Sec17InterimOrderNotes &&
                  errors.Sec17InterimOrderNotes && (
                    <Typography color="error">
                      {errors.Sec17InterimOrderNotes}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ textAlign: "right" }}>
                <Button
                  onClick={() => {
                    setOpenModel(false);
                    setEditIndex(null);
                  }}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  {editIndex !== null ? "Update" : "Submit"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};
