import axios from "axios";
import { api } from "./api_route";

export const getUserRoleRoute = async (values) => {
  try {
    const result = await axios.post(`${api}/userRoute/allRoute`, {
      values,
    });

    return result.data;
  } catch (error) {
    if (!window.location.pathname == "/") {
      window.location.href = "/";
    }
    return [];
  }
};

export const allUserRoutes = async (values) => {
  try {
    const result = await axios.get(`${api}/userRoute/allUserRoute`);
    return result.data;
  } catch (error) {
    return [];
  }
};

export const singleUserRoutes = async (values) => {
  try {
    const result = await axios.post(`${api}/userRoute/singleRoleRoute`, {
      values,
    });
    return result.data;
  } catch (error) {
    return [];
  }
};

export const updateUserRoleRoutes = async (values) => {
  try {
    const result = await axios.post(`${api}/userRoute/updateRoleRoute`, {
      values,
    });

    return result.data;
  } catch (error) {
    return [];
  }
};

export const newUserRoleRoutes = async (values) => {
  try {
    const result = await axios.get(`${api}/userRoute/newUserRole`);
    return result.data;
  } catch (error) {
    return [];
  }
};
