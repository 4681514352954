import React, { useEffect } from "react";
import {
  Drawer,
  Grid,
  Stack,
  IconButton,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { api } from "../../../api/api_route";
import { useDispatch, useSelector } from "react-redux";
import { caseDetailsHistoryAction } from "../../../redux/actions/Case/caseAction";
import { LiaUserEditSolid } from "react-icons/lia";
import { IoDocumentText } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 320,
    padding: theme.spacing(2),
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  closeButton: {
    marginLeft: "auto",
  },
  formControl: {
    minWidth: "100%",
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

const steps = [];

const EditDocumentTracker = ({ open, setOpen, checklistDocuments, id }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (e) => {
    setActiveStep(e);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    dispatch(caseDetailsHistoryAction(id));
  }, []);

  const caseHistory = useSelector((state) => state.case.history);

  const dateFormate = (date) => {
    const createdAtDate = new Date(date);

    const day = createdAtDate.getDate().toString().padStart(2, "0");

    const month = createdAtDate.toLocaleString("en-GB", { month: "short" });

    const year = createdAtDate.getFullYear();

    let hours = createdAtDate.getHours();
    const minutes = createdAtDate.getMinutes().toString().padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedDate = `${day}/${month}/${year}  - ${hours
      ?.toString()
      ?.padStart(2, "0")}:${minutes} ${ampm}`;

    return formattedDate;
  };

  function renderList(obj, checklist, parentKey = "") {
    return Object.keys(obj).map((key) => {
      const value = obj?.[key];
      const fullKey = parentKey ? `${parentKey}.${key}` : key;

      if (value && typeof value === "object" && !Array.isArray(value)) {
        if (value !== undefined) {
          let result =
            fullKey === "document" ? (
              <>
                <span className="text-secondary p-0 m-0">Checklist :</span>{" "}
                <p className="p-0 m-0">{checklist.checklistName}</p>
              </>
            ) : (
              `Header : ${fullKey}`
            );
          if (checklist) {
          }
          return (
            <ListItem
              className="pl-0 ml-0 mt-0 p-0"
              alignItems="flex-start"
              key={fullKey}
            >
              <ListItemAvatar className="pl-0 ml-0 p-0">
                <Avatar
                  className={
                    fullKey !== "document" ? "bg-secondary " : "bg-primary "
                  }
                  alt={fullKey}
                  src=""
                >
                  {fullKey !== "document" ? (
                    <LiaUserEditSolid className="text-white" />
                  ) : (
                    <IoDocumentText className="text-white" />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                className="mt-0 ml-0 pl-0"
                primary={result}
                secondary={
                  <React.Fragment>
                    <Typography
                      className="mt-0 p-0"
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {value !== null && value !== undefined ? (
                        <>
                          {value?.new && ` New Value : ${String(value?.new)}`}
                          <br />
                          {value?.old && `Old Value : ${String(value?.old)}`}
                          {value?.old && <br />}
                          {value?.group && (
                            <ListItemText
                              className="p-0 m-0"
                              primary={<u>Group</u>}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    <>
                                      New : {value?.group?.new}
                                      <br />
                                      Old : {value?.group?.old}
                                    </>
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          )}
                          {value?.reportsAndTemplates && (
                            <ListItemText
                              primary={<u>Report & Templates</u>}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    <>
                                      New :{" "}
                                      {value?.reportsAndTemplates?.new
                                        ? "True"
                                        : "False"}
                                      <br />
                                      Old :{" "}
                                      {value?.reportsAndTemplates?.old
                                        ? "True"
                                        : "False"}
                                    </>
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          )}
                          {value?.fieldNotNeeded && (
                            <ListItemText
                              primary={<u>Field Not Needed</u>}
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    New :{" "}
                                    {value?.fieldNotNeeded?.new
                                      ? "True"
                                      : "False"}
                                    <br />
                                    Old :{" "}
                                    {value?.fieldNotNeeded?.old
                                      ? "True"
                                      : "False"}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          )}
                          {checklist && (
                            <ListItemText
                              className="p-0 m-0"
                              secondary={
                                <React.Fragment>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    <span>
                                      {" "}
                                      <b> Document:</b>{" "}
                                      <a
                                        style={{
                                          color: "blue",
                                          textDecoration: "none",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          handleDownload(value);
                                        }}
                                      >
                                        {value?.actualDocName}
                                      </a>
                                    </span>
                                    <p className="p-0 m-0">
                                      {" "}
                                      <b> Notes:</b> {value?.comments}
                                    </p>
                                    <p>
                                      {" "}
                                      <b> Action:</b>{" "}
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          fontSize: 12,
                                          fontWeight: 800,
                                        }}
                                        className={
                                          value?.action == "INSERT"
                                            ? "text-success"
                                            : value?.action == "UPDATE"
                                              ? "text-primary"
                                              : "text-danger"
                                        }
                                      >
                                        {value?.action}
                                      </a>
                                    </p>
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          )}
                        </>
                      ) : (
                        "N/A"
                      )}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          );
        }
      } else {
        return (
          <ListItem alignItems="flex-start" key={fullKey}>
            <ListItemAvatar>
              <Avatar alt="Value" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary={fullKey}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {value !== null && value !== undefined
                      ? String(value)
                      : "N/A"}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        );
      }
    });
  }

  const handleDownload = (item) => {
    const downloadUrl = `${api}/caseChecklistDocuments/${item.uploadDocName}`;
    const fileName = item.uploadDocName;

    fetch(downloadUrl)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      classes={{ paper: classes.drawerPaper }}
    >
      <div>
        <div className="MuiStack-root css-jjtu05" sx={{ zIndex: 0 }}>
          {/* Header */}
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h4">Case Details History</Typography>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                className={classes.closeButton}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          {/* Divider */}
          <hr className="MuiDivider-root MuiDivider-fullWidth css-d7wf4l" />

          {/* Form */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <form>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <Box sx={{ maxWidth: 400 }}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                      {caseHistory?.update?.map((step, index) => {
                        if (step.history !== null) {
                          const history = step?.history;
                          const checklist = step?.checklistName;
                          const stepNumber = caseHistory.update?.length;

                          return (
                            <Step key={index}>
                              <StepLabel
                                onClick={() => {
                                  handleBack(index);
                                }}
                                color="primary"
                              >
                                <span className="text-secondary">
                                  Modify On:
                                </span>{" "}
                                {dateFormate(step?.updateDate)} <br />{" "}
                                <span className="text-secondary">
                                  Modify By -
                                </span>
                                {step?.Editor?.name}
                              </StepLabel>
                              <StepContent>
                                <Typography>{step.description}</Typography>
                                <Box className="m-0 p-0" sx={{ mb: 2 }}>
                                  <List
                                    className="m-0 p-0"
                                    sx={{
                                      width: "100%",

                                      bgcolor: "background.paper",
                                    }}
                                  >
                                    {step.history &&
                                      renderList(history, checklist)}
                                    <Divider variant="inset" component="li" />
                                  </List>
                                </Box>
                              </StepContent>
                            </Step>
                          );
                        }
                      })}

                      <Step key={1}>
                        <StepLabel
                          onClick={() => {
                            handleBack(0);
                          }}
                          color="primary"
                        >
                          Create Case Details <br />{" "}
                          <p>
                            Created On -
                            <span className="text-secondary">
                              {dateFormate(
                                caseHistory?.create?.[0]?.creator?.createdAt
                              )}
                            </span>
                            <br />
                            Created By -
                            <span className="text-secondary">
                              {caseHistory?.create?.[0]?.creator?.name}
                            </span>
                          </p>
                        </StepLabel>
                      </Step>
                    </Stepper>

                    {/* {activeStep === steps.length && (
                      <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>
                          All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                          Reset
                        </Button>
                      </Paper>
                    )} */}
                  </Box>
                </List>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
    </Drawer>
  );
};

export default EditDocumentTracker;
