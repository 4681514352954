import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  Stack,
  styled,
  TextareaAutosize,
  Typography,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DatePicker from "react-datepicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  preTrailCaseDetailsDocumentsAction,
  singleCaseDetailsAction,
  respondentsListOfEvidenceDeleteAction,
  claimantSideWitnessCreateAction,
  claimantSideWitnessUpdateAction,
  claimantSideWitnessDeleteAction,
} from "../../../../redux/actions/Case/caseAction";
import React, { useEffect, useState } from "react";
import { animateCss } from "../../../TosterAlert";
import { MdDelete } from "react-icons/md";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { IoMdAttach } from "react-icons/io";
import { closeSnackbar, enqueueSnackbar } from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StyledButton = styled(Button)(({ theme }) => ({
  color: "white",
  marginLeft: theme.spacing(1),
}));

const ClaimantSideWitness = ({
  expanded,
  openModel,
  setOpenModel,
  setViewDocument,
  setEditIndex,
  editIndex,
  handleAccordionChange,
  formValue,
  setFormValue,
}) => {
  const [editForm, setEditForm] = useState(false);
  const dispatch = useDispatch();

  const { id } = useParams();
  const params = { caseID: id };
  const rowData = useSelector((state) => state?.case?.editCaseDetails);
  const settings = useSelector((state) => state?.ChecklistDocument?.settings);

  const data = rowData.ClaimantWitness
    ? (JSON.parse(JSON.parse(rowData.ClaimantWitness)) ??
      JSON.parse(rowData.ClaimantWitness))
    : [];

  useEffect(() => {
    setTimeout(() => {
      dispatch(singleCaseDetailsAction(params));
    }, 3000);
  }, [openModel]);

  return (
    <>
      <div
        className="d-flex row p-0 m-0"
        spacing={2}
        style={{ backgroundColor: "#fbfbfc" }}
      >
        {editForm && expanded === 3 && editForm ? (
          <div className="d-flex row col-12 mt-2  ">
            <form
              className="pe-0"
              // onSubmit={handleSubmit}
              style={{ boxShadow: "none" }}
              xs={12}
              sm={12}
              md={12}
            >
              <Accordion expanded={expanded === 3}>
                <AccordionSummary
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box
                    className="p-0 m-0"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "end",
                      width: "100%",
                      backgroundColor: "white",
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      className="m-0"
                      sx={{
                        "&:hover": {
                          color: "primary.main",
                        },
                      }}
                      onClick={() => handleAccordionChange(3)}
                    >
                      Claimant Side Witness
                    </Typography>
                    <div>
                      <Button
                        className="mx-2"
                        type="button"
                        variant="contained"
                        color="secondary"
                        onClick={(event) => {
                          event.stopPropagation();
                          setEditForm(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "end",
                          backgroundColor: "white",
                          flexWrap: "wrap",
                          alignItems: "stretch",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "end",
                            px: 2,
                            gridTemplateColumns: {
                              xs: "1fr",
                              sm: "1fr",
                              md: "1fr",
                            },
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "100%",
                            },
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setEditIndex(null);
                              setOpenModel(true);
                            }}
                            disabled={data?.length == 10}
                            className="justify-content-center"
                          >
                            Claimant Side Witness Submission Form
                          </Button>
                        </Box>
                        <ModelForm
                          values={data}
                          settings={settings}
                          formValue={formValue}
                          setFormValue={setFormValue}
                          setOpenModel={setOpenModel}
                          openModel={openModel}
                          editIndex={editIndex}
                          setEditIndex={setEditIndex}
                        />

                        <Box
                          sx={{
                            display: "grid",
                            // px: 2,
                            gridTemplateColumns: {
                              xs: "1fr",
                              sm: "1fr",
                              md: "1fr",
                            },
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "100%",
                            },
                          }}
                          xs={12}
                          sm={12}
                          md={12}
                        >
                          {data?.length > 0 && (
                            <Box
                              className="d-flex col-12"
                              // style={{
                              //   overflowX: "auto",
                              //   width: "100%",
                              // }}
                            >
                              <table
                                striped
                                bordered
                                hover
                                className="my-2 table"
                              >
                                <thead
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "lighter",
                                  }}
                                >
                                  <tr>
                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Witness Number
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Witness Name
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Status
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Attachment
                                    </th>

                                    <th
                                      scope="col"
                                      style={{
                                        fontWeight: "lighter",
                                      }}
                                      className="border"
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {data?.map((order, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <Typography
                                            gutterBottom
                                            variant="p"
                                            component="div"
                                            className="m-0"
                                            sx={{
                                              "&:hover": {
                                                color: "primary.main",
                                                cursor: "pointer",
                                              },
                                            }}
                                            onClick={() => {
                                              if (window.edit) {
                                                setOpenModel(true);
                                                setEditIndex(index);
                                              } else {
                                                animateCss(
                                                  "info",
                                                  "You are not authorized to update"
                                                );
                                              }

                                              // const uniqueDocumentId = [
                                              //   ...(order?.statementDeclarationProvided ||
                                              //     []),
                                              //   ...(order?.supportingEvidenceSubmitted ||
                                              //     []),
                                              //   ...(order?.videoAudioRecordingSubmitted ||
                                              //     []),
                                              //   ...(order?.witnessIDProof ||
                                              //     []),
                                              // ];

                                              // const document = {
                                              //   uniqueDocumentId,
                                              // };
                                              // dispatch(
                                              //   preTrailCaseDetailsDocumentsAction(
                                              //     document
                                              //   )
                                              // );
                                            }}
                                          >
                                            Witness {index + 1}
                                          </Typography>{" "}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "12px",
                                          }}
                                        >
                                          {order?.witnessName}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "12px",
                                          }}
                                        >
                                          {order?.finalWitnessStatus}
                                        </td>
                                        <td
                                          style={{
                                            fontSize: "12px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {[
                                            ...(order?.statementDeclarationProvided ||
                                              []),
                                            ...(order?.supportingEvidenceSubmitted ||
                                              []),
                                            ...(order?.videoAudioRecordingSubmitted ||
                                              []),
                                            ...(order?.witnessIDProof || []),
                                          ]?.length > 0 && (
                                            <IconButton aria-label="attach">
                                              <IoMdAttach className="fs-3 text-primary" />
                                            </IconButton>
                                          )}
                                        </td>
                                        <td
                                          className={
                                            data?.length - 1 == index &&
                                            "d-flex"
                                          }
                                        >
                                          {data?.length - 1 == index && (
                                            <IconButton
                                              aria-label="delete"
                                              color="error"
                                              style={{
                                                marginTop: "4%",
                                              }}
                                              onClick={() => {
                                                if (window.delete) {
                                                  enqueueSnackbar(
                                                    <>
                                                      <div>
                                                        Are you sure you want to
                                                        delete the record and
                                                        its associated document
                                                        for Claimant Side
                                                        Witness {index + 1} ?
                                                      </div>
                                                    </>,
                                                    {
                                                      variant: "warning",
                                                      onClose: () => {},
                                                      anchorOrigin: {
                                                        vertical: "top",
                                                        horizontal: "right",
                                                      },
                                                      action: (key) => (
                                                        <>
                                                          <StyledButton
                                                            onClick={async () => {
                                                              const output =
                                                                formValue.filter(
                                                                  (_, i) =>
                                                                    i !== index
                                                                );

                                                              setFormValue(
                                                                output
                                                              );
                                                              const value = {
                                                                caseID: params,
                                                                index,
                                                              };
                                                              dispatch(
                                                                claimantSideWitnessDeleteAction(
                                                                  value
                                                                )
                                                              );

                                                              closeSnackbar(
                                                                key
                                                              );

                                                              setTimeout(() => {
                                                                dispatch(
                                                                  singleCaseDetailsAction(
                                                                    params
                                                                  )
                                                                );
                                                              }, 1000);
                                                            }}
                                                          >
                                                            Confirm
                                                          </StyledButton>
                                                          <StyledButton
                                                            onClick={() => {
                                                              closeSnackbar(
                                                                key
                                                              );
                                                            }}
                                                          >
                                                            Cancel
                                                          </StyledButton>
                                                        </>
                                                      ),
                                                      autoHideDuration: null,
                                                      style: {
                                                        backgroundColor:
                                                          "#f44336",
                                                        width: "600px",
                                                        color: "#fff",
                                                      },
                                                    }
                                                  );
                                                } else {
                                                  animateCss(
                                                    "info",
                                                    "You are not authorized to delete"
                                                  );
                                                }
                                              }}
                                            >
                                              <MdDelete
                                                style={{
                                                  fontSize: "24px",
                                                }}
                                              />
                                            </IconButton>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </Box>
                          )}
                        </Box>
                      </Box>

                      {/* Dynamice Data */}
                    </LocalizationProvider>
                  </>
                </AccordionDetails>
              </Accordion>
            </form>
          </div>
        ) : (
          <>
            <Accordion className="mt-2 " expanded={expanded === 3}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="m-0"
                    sx={{
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                    onClick={() => handleAccordionChange(3)}
                  >
                    Claimant Side Witness
                  </Typography>

                  {window?.edit && (
                    <div>
                      <Button
                        type="button"
                        color="primary"
                        onClick={(event) => {
                          event.stopPropagation();

                          if (window.edit) {
                            event.stopPropagation();
                            // setPreTrialApplications(true);
                            setEditForm(true);
                          } else {
                            animateCss(
                              "info",
                              "You are not authorized to update"
                            );
                          }
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={0}>
                  <Box
                    sx={{
                      display: "grid",
                      // px: 2,
                      gridTemplateColumns: {
                        xs: "1fr",
                        sm: "1fr",
                        md: "1fr",
                      },
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                      },
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <Box
                      className="d-flex col-12"
                      style={{
                        overflowX: "auto",
                        width: "100%",
                      }}
                    >
                      <table striped bordered hover className="table my-2">
                        <thead
                          style={{
                            fontSize: "12px",
                            fontWeight: "lighter",
                          }}
                        >
                          <tr>
                            <th
                              style={{
                                fontWeight: "lighter",
                              }}
                              className="border"
                            >
                              Witness Number
                            </th>

                            <th
                              style={{
                                fontWeight: "lighter",
                              }}
                              className="border"
                            >
                              Witness Name
                            </th>

                            <th
                              style={{
                                fontWeight: "lighter",
                              }}
                              className="border"
                            >
                              Status
                            </th>

                            <th
                              style={{
                                fontWeight: "lighter",
                              }}
                              className="border"
                            >
                              Attachment
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {data?.map((order, index) => {
                            return (
                              <tr key={index}>
                                <td>Witness {index + 1}</td>
                                <td
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {order?.witnessName}
                                </td>
                                <td
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {order?.finalWitnessStatus}
                                </td>
                                <td
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {[
                                    ...(order?.statementDeclarationProvided ||
                                      []),
                                    ...(order?.supportingEvidenceSubmitted ||
                                      []),
                                    ...(order?.videoAudioRecordingSubmitted ||
                                      []),
                                    ...(order?.witnessIDProof || []),
                                  ]?.length > 0 && (
                                    <IconButton
                                      onClick={() => {
                                        setViewDocument(true);
                                        const uniqueDocumentId = [
                                          ...(order?.statementDeclarationProvided ||
                                            []),
                                          ...(order?.supportingEvidenceSubmitted ||
                                            []),
                                          ...(order?.videoAudioRecordingSubmitted ||
                                            []),
                                          ...(order?.witnessIDProof || []),
                                        ];

                                        const document = { uniqueDocumentId };
                                        dispatch(
                                          preTrailCaseDetailsDocumentsAction(
                                            document
                                          )
                                        );
                                      }}
                                      aria-label="attach"
                                    >
                                      <IoMdAttach className="fs-3 text-primary" />
                                    </IconButton>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Box>
                  </Box>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </div>
    </>
  );
};

export default ClaimantSideWitness;

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  marginTop: "1%",
  fontSize: "1rem",
  borderRadius: "4px",
  border: "1px solid #ccc",
  resize: "vertical",
  minHeight: "90px",
  transition: "border-color 0.3s ease",
  overflow: "auto",
  "&:focus": {
    borderColor: theme.palette.primary.main,
    outline: "none",
  },
}));

const ModelForm = ({
  setPreTrialApplications,
  values,
  formValue,
  setFormValue,
  settings,
  setOpenModel,
  openModel,
  editIndex,
  setEditIndex,
}) => {
  const [formData, setFormData] = useState(null);
  const data = values;

  const claimantPreTrailApplicationAttachmentsOld = [
    ...(data[editIndex]?.statementDeclarationProvided || []),
    ...(data[editIndex]?.supportingEvidenceSubmitted || []),
    ...(data[editIndex]?.videoAudioRecordingSubmitted || []),
    ...(data[editIndex]?.witnessIDProof || []),
  ];

  const uniqueevidenceAttachment = Array.from(
    new Set(claimantPreTrailApplicationAttachmentsOld?.map(Number))
  );

  const uniqueDocumentId = [...(uniqueevidenceAttachment || [])];

  const dispatch = useDispatch();

  const { id } = useParams();

  const params = { caseID: id };

  const document = {
    uniqueDocumentId,
    id,
  };

  // Updating formData based on editIndex
  useEffect(() => {
    dispatch(preTrailCaseDetailsDocumentsAction(document));
    if (editIndex >= 0) {
      setFormData(data?.[editIndex]);
    } else {
      setFormData(null);
    }

    if (claimantPreTrailApplicationAttachmentsOld?.length >= 0) {
      const documentId = [...(uniqueDocumentId || [])];
      // Ensure all IDs are unique
      const uniqueDocumentsId = [...new Set(documentId)];

      const document = {
        uniqueDocumentsId,
        id,
      };
    }
  }, [editIndex, openModel]);

  const reduxAttachments = useSelector(
    (state) => state.case?.preTrailCaseDetailsDocuments
  );

  const evidenceAttachmentDoc =
    useSelector((state) => state.case?.preTrailCaseDetailsDocuments)?.filter(
      (item) =>
        data?.[editIndex]?.statementDeclarationProvided?.includes(
          item.checkListDocID
        )
    ) || [];

  let initialValues = {
    witnessName: data?.[editIndex]?.witnessName ?? "",
    witnessAddress: data?.[editIndex]?.witnessAddress ?? "",
    phoneNumber: data?.[editIndex]?.phoneNumber ?? "",
    emailAddress: data?.[editIndex]?.emailAddress ?? "",
    witnessDesignationProfession:
      data?.[editIndex]?.witnessDesignationProfession ?? "",
    relationshipToTheCase: data?.[editIndex]?.relationshipToTheCase ?? "",
    typeOfWitness: data?.[editIndex]?.typeOfWitness ?? "",
    statementDeclarationProvided:
      useSelector((state) => state.case?.preTrailCaseDetailsDocuments)?.filter(
        (item) =>
          data?.[editIndex]?.statementDeclarationProvided?.includes(
            item.checkListDocID
          )
      ) || [],
    dateOfStatementSubmission:
      data?.[editIndex]?.dateOfStatementSubmission ?? "",
    examinationConductedOn: data?.[editIndex]?.examinationConductedOn ?? "",
    crossExaminationConductedOn:
      data?.[editIndex]?.crossExaminationConductedOn ?? "",
    supportingEvidenceSubmitted:
      useSelector((state) => state.case?.preTrailCaseDetailsDocuments)?.filter(
        (item) =>
          data?.[editIndex]?.supportingEvidenceSubmitted?.includes(
            item.checkListDocID
          )
      ) || [],
    witnessNotesObservations: data?.[editIndex]?.witnessNotesObservations ?? "",
    finalWitnessStatus: data?.[editIndex]?.finalWitnessStatus ?? "",
    videoAudioRecordingSubmitted:
      useSelector((state) => state.case?.preTrailCaseDetailsDocuments)?.filter(
        (item) =>
          data?.[editIndex]?.videoAudioRecordingSubmitted?.includes(
            item.checkListDocID
          )
      ) || [],
    witnessIDProof:
      useSelector((state) => state.case?.preTrailCaseDetailsDocuments)?.filter(
        (item) =>
          data?.[editIndex]?.witnessIDProof?.includes(item.checkListDocID)
      ) || [],
    specialRemarksOrComments: data?.[editIndex]?.specialRemarksOrComments ?? "",
    editIndex,
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    Formik.setFieldValue("claimantsPreTrailApplicationAttachments", files);
  };

  return (
    <Dialog
      open={openModel}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          width: "50%",
          maxWidth: "100%",
          padding: 16,
        },
      }}
    >
      <Box sx={{ display: "grid", p: 2, width: "100%", height: "100%" }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            try {
              const form = new FormData();
              form.append("witnessName", values.witnessName);
              form.append("witnessAddress", values.witnessAddress);
              form.append("phoneNumber", values.phoneNumber);
              form.append("emailAddress", values.emailAddress);
              form.append("caseID", id);
              form.append(
                "witnessDesignationProfession",
                values.witnessDesignationProfession
              );
              form.append(
                "relationshipToTheCase",
                values.relationshipToTheCase
              );
              form.append("typeOfWitness", values.typeOfWitness);

              if (values.statementDeclarationProvided) {
                values.statementDeclarationProvided.forEach((file) => {
                  if (file?.caseID) {
                    form.append(
                      `statementDeclarationProvidedOld`,
                      file?.checkListDocID
                    );
                  } else {
                    form.append(`Statement/Declaration Provided`, file?.file);
                  }
                });
              }

              form.append(
                "dateOfStatementSubmission",
                values.dateOfStatementSubmission
              );
              form.append(
                "examinationConductedOn",
                values.examinationConductedOn
              );
              form.append(
                "crossExaminationConductedOn",
                values.crossExaminationConductedOn
              );

              if (values.supportingEvidenceSubmitted) {
                values.supportingEvidenceSubmitted.forEach((file) => {
                  if (file?.caseID) {
                    form.append(
                      `supportingEvidenceSubmittedOld`,
                      file?.checkListDocID
                    );
                  } else {
                    form.append(`Supporting Evidence Submitted`, file?.file);
                  }
                });
              }

              form.append(
                "witnessNotesObservations",
                values.witnessNotesObservations
              );

              form.append("finalWitnessStatus", values.finalWitnessStatus);

              if (values.videoAudioRecordingSubmitted) {
                values.videoAudioRecordingSubmitted.forEach((file) => {
                  if (file?.caseID) {
                    form.append(
                      `videoAudioRecordingSubmittedOld`,
                      file?.checkListDocID
                    );
                  } else {
                    form.append(`Video/Audio Recording Submitted`, file?.file);
                  }
                });
              }

              if (values.witnessIDProof) {
                values.witnessIDProof.forEach((file) => {
                  if (file?.caseID) {
                    form.append(`witnessIDProofOld`, file?.checkListDocID);
                  } else {
                    form.append(`Witness ID Proof`, file?.file);
                  }
                });
              }
              form.append(
                "specialRemarksOrComments",
                values.specialRemarksOrComments
              );

              if (editIndex == null) {
                dispatch(claimantSideWitnessCreateAction(form))
                  .unwrap()
                  .then((result) => {
                    setOpenModel(false);
                    if (result.documents) {
                      setTimeout(() => {
                        dispatch(singleCaseDetailsAction(params));
                        setOpenModel(false);
                      }, 2000);
                    }
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              } else if (editIndex >= 0) {
                const updatedData = [...formValue];
                updatedData[editIndex] = values;
                setFormValue(updatedData);
                form.append("editIndex", editIndex);
                dispatch(claimantSideWitnessUpdateAction(form))
                  .unwrap()
                  .then((result) => {
                    setOpenModel(false);
                    if (result.documents) {
                      setTimeout(() => {
                        dispatch(singleCaseDetailsAction(params));
                      }, 3000);
                    }
                  })
                  .catch((error) => {
                    console.error("Action rejected with error:", error);
                  });
              }

              setOpenModel(false);
              // dispatch(
              //   allAgreementDocumentListAction({
              //     value: "Arbitration Agreement",
              //     caseID: id,
              //   })
              // );

              actions.resetForm();
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="witnessName">Witness Name</InputLabel>
                <OutlinedInput
                  id="witnessName"
                  name="witnessName"
                  value={values?.witnessName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.witnessName && errors.witnessName && (
                  <Typography color="error">{errors.witnessName}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="witnessAddress">
                  Witness Address
                </InputLabel>

                <StyledTextarea
                  rowsMin={5}
                  id="witnessAddress"
                  name="witnessAddress"
                  aria-label="maximum height"
                  value={values?.witnessAddress}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                {touched.witnessAddress && errors.witnessAddress && (
                  <Typography color="error">{errors.witnessAddress}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel
                  className="fs-6 fw-bold"
                  htmlFor="contactInformation"
                >
                  Contact Information
                </InputLabel>
                <Box sx={{ display: "grid", mb: 2 }}>
                  <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
                  <OutlinedInput
                    id="phoneNumber"
                    name="phoneNumber"
                    value={values?.phoneNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.phoneNumber && errors.phoneNumber && (
                    <Typography color="error">{errors.phoneNumber}</Typography>
                  )}
                </Box>
                <Box sx={{ display: "grid", mb: 2 }}>
                  <InputLabel htmlFor="emailAddress">Email Address</InputLabel>
                  <OutlinedInput
                    id="emailAddress"
                    name="emailAddress"
                    value={values?.emailAddress}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.emailAddress && errors.emailAddress && (
                    <Typography color="error">{errors.emailAddress}</Typography>
                  )}
                </Box>
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="witnessDesignationProfession">
                  Witness Designation/Profession
                </InputLabel>
                <OutlinedInput
                  id="witnessDesignationProfession"
                  name="witnessDesignationProfession"
                  value={values?.witnessDesignationProfession}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.witnessDesignationProfession &&
                  errors.witnessDesignationProfession && (
                    <Typography color="error">
                      {errors.witnessDesignationProfession}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="relationshipToTheCase">
                  Relationship to the Case
                </InputLabel>
                <StyledTextarea
                  // rowsMin={5}
                  // name="caseGeneralNotes"
                  aria-label="maximum height"
                  // value={values?.caseGeneralNotes}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.relationshipToTheCase &&
                  errors.relationshipToTheCase && (
                    <Typography color="error">
                      {errors.relationshipToTheCase}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="typeOfWitness">Type of Witness</InputLabel>
                <Select
                  labelId="typeOfWitness"
                  id="typeOfWitness"
                  name="typeOfWitness"
                  value={values?.typeOfWitness}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find((item) => item?.categoryName === "Type of Witness")
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.categoryName}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.typeOfWitness && errors.typeOfWitness && (
                  <Typography color="error">{errors.typeOfWitness}</Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Statement/Declaration Provided
                </Typography>
                <Button
                  className="col-12 col-sm-12 col-md-6 col-xl-3"
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload files
                  <VisuallyHiddenInput
                    type="file"
                    multiple
                    // accept="application/pdf"
                    onChange={(event) => {
                      const existingFiles =
                        values?.statementDeclarationProvided || [];
                      const newFiles = Array.from(event.target.files);

                      const updatedFiles = [
                        ...new Map(
                          [...existingFiles, ...newFiles].map((file) => [
                            file.name || file?.actualDocName,
                            {
                              file: file?.file ?? file,
                              checkListDocID: file?.checkListDocID || null,
                              caseRowID: file?.caseRowID || null,
                              caseID: file?.caseID || null,
                              checklistID: file?.checklistID || null,
                              genSetID: file?.genSetID || null,
                              checklistDate: null,
                              actualDocName: file.name || file?.actualDocName,
                              uploadDocName: file.name || file?.uploadDocName,
                              documentType: file.type || file.documentType,
                              documentSize:
                                file.documentSize || file.size?.toString(),
                              documentClassification: null,
                              documentService: null,
                              serviceCount: null,
                              comments: null,
                              createdBy: file?.createdBy || 1,
                              updatedBy: file?.updatedBy || 1,
                              isActive: 1,
                              createdAt:
                                file?.createdAt || new Date()?.toISOString(),
                              updatedAt:
                                file?.updatedAt || new Date()?.toISOString(),
                            },
                          ])
                        ).values(),
                      ];

                      setFieldValue(
                        "statementDeclarationProvided",
                        updatedFiles
                      );
                    }}
                  />
                </Button>
                {touched.statementDeclarationProvided &&
                  errors.statementDeclarationProvided && (
                    <Typography color="error">
                      {errors.statementDeclarationProvided}
                    </Typography>
                  )}
              </Box>

              {values.statementDeclarationProvided.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  {values.statementDeclarationProvided?.map((file, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={file?.actualDocName || file.name}
                          onDelete={() => {
                            const updatedFiles =
                              values.statementDeclarationProvided.filter(
                                (_, fileIndex) => fileIndex !== index
                              );
                            setFieldValue(
                              "statementDeclarationProvided",
                              updatedFiles
                            );
                          }}
                        />
                      </Stack>
                    </Box>
                  ))}
                </Box>
              )}

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Date of Statement Submission
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="dateOfStatementSubmission"
                  selected={values?.dateOfStatementSubmission}
                  onChange={(date) =>
                    setFieldValue("dateOfStatementSubmission", date)
                  }
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.dateOfStatementSubmission &&
                  errors.dateOfStatementSubmission && (
                    <Typography color="error">
                      {errors.dateOfStatementSubmission}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Examination Conducted On
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="examinationConductedOn"
                  selected={values?.examinationConductedOn}
                  onChange={(date) =>
                    setFieldValue("examinationConductedOn", date)
                  }
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.examinationConductedOn &&
                  errors.examinationConductedOn && (
                    <Typography color="error">
                      {errors.examinationConductedOn}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Cross-Examination Conducted On
                </Typography>
                <DatePicker
                  style={{ width: "100%" }}
                  id="crossExaminationConductedOn"
                  selected={values?.crossExaminationConductedOn}
                  onChange={(date) =>
                    setFieldValue("crossExaminationConductedOn", date)
                  }
                  // minDate={minDate}
                  onBlur={handleBlur}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD-MM-YYYY"
                  className="form-control w-100"
                />
                {touched.crossExaminationConductedOn &&
                  errors.crossExaminationConductedOn && (
                    <Typography color="error">
                      {errors.crossExaminationConductedOn}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Supporting Evidence Submitted
                </Typography>
                <Button
                  className="col-12 col-sm-12 col-md-6 col-xl-3"
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload files
                  <VisuallyHiddenInput
                    type="file"
                    multiple
                    // accept="application/pdf"
                    onChange={(event) => {
                      const existingFiles =
                        values?.supportingEvidenceSubmitted || [];
                      const newFiles = Array.from(event.target.files);

                      const updatedFiles = [
                        ...new Map(
                          [...existingFiles, ...newFiles].map((file) => [
                            file.name || file?.actualDocName,
                            {
                              file: file?.file ?? file,
                              checkListDocID: file?.checkListDocID || null,
                              caseRowID: file?.caseRowID || null,
                              caseID: file?.caseID || null,
                              checklistID: file?.checklistID || null,
                              genSetID: file?.genSetID || null,
                              checklistDate: null,
                              actualDocName: file.name || file?.actualDocName,
                              uploadDocName: file.name || file?.uploadDocName,
                              documentType: file.type || file.documentType,
                              documentSize:
                                file.documentSize || file.size?.toString(),
                              documentClassification: null,
                              documentService: null,
                              serviceCount: null,
                              comments: null,
                              createdBy: file?.createdBy || 1,
                              updatedBy: file?.updatedBy || 1,
                              isActive: 1,
                              createdAt:
                                file?.createdAt || new Date()?.toISOString(),
                              updatedAt:
                                file?.updatedAt || new Date()?.toISOString(),
                            },
                          ])
                        ).values(),
                      ];

                      setFieldValue(
                        "supportingEvidenceSubmitted",
                        updatedFiles
                      );
                    }}
                  />
                </Button>
                {touched.supportingEvidenceSubmitted &&
                  errors.supportingEvidenceSubmitted && (
                    <Typography color="error">
                      {errors.supportingEvidenceSubmitted}
                    </Typography>
                  )}
              </Box>

              {values.supportingEvidenceSubmitted.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  {values.supportingEvidenceSubmitted?.map((file, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={file?.actualDocName || file.name}
                          onDelete={() => {
                            const updatedFiles =
                              values.supportingEvidenceSubmitted.filter(
                                (_, fileIndex) => fileIndex !== index
                              );
                            setFieldValue(
                              "supportingEvidenceSubmitted",
                              updatedFiles
                            );
                          }}
                        />
                      </Stack>
                    </Box>
                  ))}
                </Box>
              )}

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="witnessNotesObservations">
                  Witness Notes/Observations
                </InputLabel>
                <OutlinedInput
                  id="witnessNotesObservations"
                  name="witnessNotesObservations"
                  value={values?.witnessNotesObservations}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.witnessNotesObservations &&
                  errors.witnessNotesObservations && (
                    <Typography color="error">
                      {errors.witnessNotesObservations}
                    </Typography>
                  )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="finalWitnessStatus">
                  Final Witness Status
                </InputLabel>
                <Select
                  labelId="finalWitnessStatus"
                  id="finalWitnessStatus"
                  name="finalWitnessStatus"
                  value={values?.finalWitnessStatus}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  fullWidth
                >
                  {settings
                    ?.find(
                      (item) => item?.categoryName === "Final Witness Status"
                    )
                    ?.Children?.map((data, index) => (
                      <MenuItem key={index} value={data?.categoryName}>
                        {data?.categoryName}
                      </MenuItem>
                    ))}
                </Select>
                {touched.finalWitnessStatus && errors.finalWitnessStatus && (
                  <Typography color="error">
                    {errors.finalWitnessStatus}
                  </Typography>
                )}
              </Box>

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Video/Audio Recording Submitted
                </Typography>
                <Button
                  className="col-12 col-sm-12 col-md-6 col-xl-3"
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload files
                  <VisuallyHiddenInput
                    type="file"
                    multiple
                    // accept="application/pdf"
                    onChange={(event) => {
                      const existingFiles =
                        values?.videoAudioRecordingSubmitted || [];
                      const newFiles = Array.from(event.target.files);

                      const updatedFiles = [
                        ...new Map(
                          [...existingFiles, ...newFiles].map((file) => [
                            file.name || file?.actualDocName,
                            {
                              file: file?.file ?? file,
                              checkListDocID: file?.checkListDocID || null,
                              caseRowID: file?.caseRowID || null,
                              caseID: file?.caseID || null,
                              checklistID: file?.checklistID || null,
                              genSetID: file?.genSetID || null,
                              checklistDate: null,
                              actualDocName: file.name || file?.actualDocName,
                              uploadDocName: file.name || file?.uploadDocName,
                              documentType: file.type || file.documentType,
                              documentSize:
                                file.documentSize || file.size?.toString(),
                              documentClassification: null,
                              documentService: null,
                              serviceCount: null,
                              comments: null,
                              createdBy: file?.createdBy || 1,
                              updatedBy: file?.updatedBy || 1,
                              isActive: 1,
                              createdAt:
                                file?.createdAt || new Date()?.toISOString(),
                              updatedAt:
                                file?.updatedAt || new Date()?.toISOString(),
                            },
                          ])
                        ).values(),
                      ];

                      setFieldValue(
                        "videoAudioRecordingSubmitted",
                        updatedFiles
                      );
                    }}
                  />
                </Button>
                {touched.videoAudioRecordingSubmitted &&
                  errors.videoAudioRecordingSubmitted && (
                    <Typography color="error">
                      {errors.videoAudioRecordingSubmitted}
                    </Typography>
                  )}
              </Box>

              {values.videoAudioRecordingSubmitted.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  {values.videoAudioRecordingSubmitted?.map((file, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={file?.actualDocName || file.name}
                          onDelete={() => {
                            const updatedFiles =
                              values.videoAudioRecordingSubmitted.filter(
                                (_, fileIndex) => fileIndex !== index
                              );
                            setFieldValue(
                              "videoAudioRecordingSubmitted",
                              updatedFiles
                            );
                          }}
                        />
                      </Stack>
                    </Box>
                  ))}
                </Box>
              )}

              <Box sx={{ display: "grid", mb: 2 }}>
                <Typography variant="body1" component="label">
                  Witness ID Proof
                </Typography>
                <Button
                  className="col-12 col-sm-12 col-md-6 col-xl-3"
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload files
                  <VisuallyHiddenInput
                    type="file"
                    multiple
                    // accept="application/pdf"
                    onChange={(event) => {
                      const existingFiles = values?.witnessIDProof || [];
                      const newFiles = Array.from(event.target.files);

                      const updatedFiles = [
                        ...new Map(
                          [...existingFiles, ...newFiles].map((file) => [
                            file.name || file?.actualDocName,
                            {
                              file: file?.file ?? file,
                              checkListDocID: file?.checkListDocID || null,
                              caseRowID: file?.caseRowID || null,
                              caseID: file?.caseID || null,
                              checklistID: file?.checklistID || null,
                              genSetID: file?.genSetID || null,
                              checklistDate: null,
                              actualDocName: file.name || file?.actualDocName,
                              uploadDocName: file.name || file?.uploadDocName,
                              documentType: file.type || file.documentType,
                              documentSize:
                                file.documentSize || file.size?.toString(),
                              documentClassification: null,
                              documentService: null,
                              serviceCount: null,
                              comments: null,
                              createdBy: file?.createdBy || 1,
                              updatedBy: file?.updatedBy || 1,
                              isActive: 1,
                              createdAt:
                                file?.createdAt || new Date()?.toISOString(),
                              updatedAt:
                                file?.updatedAt || new Date()?.toISOString(),
                            },
                          ])
                        ).values(),
                      ];

                      setFieldValue("witnessIDProof", updatedFiles);
                    }}
                  />
                </Button>
                {touched.witnessIDProof && errors.witnessIDProof && (
                  <Typography color="error">{errors.witnessIDProof}</Typography>
                )}
              </Box>

              {values.witnessIDProof.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  {values.witnessIDProof?.map((file, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      sx={{ mb: 1 }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={file?.actualDocName || file.name}
                          onDelete={() => {
                            const updatedFiles = values.witnessIDProof.filter(
                              (_, fileIndex) => fileIndex !== index
                            );
                            setFieldValue("witnessIDProof", updatedFiles);
                          }}
                        />
                      </Stack>
                    </Box>
                  ))}
                </Box>
              )}

              <Box sx={{ display: "grid", mb: 2 }}>
                <InputLabel htmlFor="evidenceName">
                  Special Remarks or Comments
                </InputLabel>
                <OutlinedInput
                  id="evidenceName"
                  name="evidenceName"
                  value={values?.evidenceName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.evidenceName && errors.evidenceName && (
                  <Typography color="error">{errors.evidenceName}</Typography>
                )}
              </Box>

              <Box sx={{ textAlign: "right" }}>
                <Button
                  onClick={() => {
                    setOpenModel(false);
                    setEditIndex(null);
                  }}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button disabled={isSubmitting} color="primary" type="submit">
                  {editIndex !== null ? "Update" : "Submit"}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};
