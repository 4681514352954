import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  exportCSVDocument,
  exportExcel,
  exportPDFDocument,
  exportWord,
} from "../../../api/exportDocument_api";

export const exportPDFDocumentAction = createAsyncThunk(
  "export/exportPDFDocumentAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await exportPDFDocument(values);
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const exportCSVDocumentAction = createAsyncThunk(
  "export/exportCSVDocumentAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await exportCSVDocument(values);
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const exportExcelAction = createAsyncThunk(
  "export/exportExcelAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await exportExcel(values);
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);

export const exportWordAction = createAsyncThunk(
  "export/exportWordAction",
  async (values, { rejectWithValue, getState }) => {
    try {
      const result = await exportWord(values);
      return result;
    } catch (error) {
      return rejectWithValue(error.message || "something went wrong");
    }
  }
);
